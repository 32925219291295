import React, { useState } from "react";
import styled from "styled-components";

const InputDiv = styled.div`
  --text: black;
  --text-placeholder: #d9d9db;
  --icon: black;
  --icon-focus: black;
  --icon-invalid: #f04949;
  --icon-valid: #16bf78;
  --background: #fff;
  --border: black;
  --border-hover: #a6accd;
  --border-focus: #d4af37;
  --shadow-focus: rgba(39, 94, 254, 0.32);
  position: relative;
  width: 375px;
  margin: 10px;

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }

  input {
    width: 300px;
    -webkit-appearance: none;
    outline: none;
    display: block;
    font-size: 16px;
    font-family: inherit;
    margin: 0;
    padding: 8px 16px 8px 41px;
    line-height: 27px;
    border-radius: 6px;
    color: var(--text);
    border: 1px solid var(--bc, var(--border));
    background: var(--background);
    transition: border-color 0.3s, box-shadow 0.3s;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
      rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
    &::placeholder {
      color: var(--text-placeholder);
    }
  }
  svg {
    width: 16px;
    height: 16px;
    top: 14px;
    left: 20px;
    display: block;
    position: absolute;
    fill: none;
    stroke: var(--i, var(--icon));
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.6;
    transition: stroke 0.3s;
    path {
      stroke-dasharray: 80;
      stroke-dashoffset: var(--path, 170);
      transition: stroke-dashoffset 0.5s ease var(--path-delay, 0.3s);
    }
    polyline {
      stroke-dasharray: 12;
      stroke-dashoffset: var(--tick, 12);
      transition: stroke-dashoffset 0.45s ease var(--tick-delay, 0s);
    }
  }
  &:hover {
    --bc: var(--border-hover);
  }
  &:focus-within {
    --bc: var(--border-focus);
    --i: var(--icon-focus);
    input {
      box-shadow: 0 1px 6px -1px var(--shadow-focus);
    }
  }
  &:not(.valid) {
    input {
      &:not(:placeholder-shown) {
        &:not(:focus) {
          & + svg {
            --i: var(--icon-invalid);
          }
        }
      }
    }
  }
  &.valid {
    --i: var(--icon-valid);
    --path: 132;
    --path-delay: 0s;
    --tick: 0;
    --tick-delay: 0.3s;
  }
`;

const EmailInput = ({ value, onChange, isValid }) => {
  return (
    <InputDiv className={`password-input ${isValid ? "valid" : "invalid"}`}>
      <input
        type="email"
        placeholder="Email"
        value={value}
        onChange={onChange}
        autoComplete="email"
      />
      <svg viewBox="0 0 18 18">
        <path d="M11.5,10.5 C6.4987941,17.5909626 1,3.73719105 11.5,6 C10.4594155,14.5485365 17,13.418278 17,9 C17,4.581722 13.418278,1 9,1 C4.581722,1 1,4.581722 1,9 C1,13.418278 4.581722,17 9,17 C13.418278,17 17,13.42 17,9"></path>
        <polyline points="5 9.25 8 12 13 6"></polyline>
      </svg>
    </InputDiv>
  );
};

export default EmailInput;
