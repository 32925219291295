import ReactGA from "react-ga4";

const measurementId = "G-Z9C14ZQPJY";
// ReactGA.initialize(measurementId);

export const initializeGA = () => {
  if (process.env.REACT_APP_ENV !== "development") {
    ReactGA.initialize(measurementId);
  }
};

export const pageview = (page) => {
  if (process.env.REACT_APP_ENV !== "development") {
    ReactGA.send({ hitType: "pageview", page });
  }
};

export const trackEvent = (category, action, label = "") => {
  if (process.env.REACT_APP_ENV !== "development") {
    ReactGA.event({
      category: category,
      action: action,
      label: label,
    });
  }
};
