import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { BounceEffect } from "../custom/animations";

const DropdownContainer = styled.div`
  text-align: left;
  align-items: center;
  font-family: San Francisco Regular;
  border: 1px solid #ccc;
  margin: 5px 0px 0px 0px;
  width: 45%;
  background-color: transparent;
  position: relative;
  font-size: 0.9rem;
  border-radius: 10px;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;

  @media (max-width: 760px) {
    min-width: 45%;
  }
`;
const DropdownInput = styled.div`
  padding: 8px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  user-select: none;
  cursor: pointer;
  color: #575756;

  &.invalid {
    border: red;
    background: #ffd7d7;
  }

  @media (max-width: 760px) {
    //padding: 5px;
  }
`;
const DropdownMenu = styled.div`
  position: absolute;
  transform: translateY(5px);
  width: 100%;
  border: 1px solid #ccc;
  overflow: auto;
  max-height: 150px;
  background-color: #fff;
  color: #575756;
  z-index: 99;
  border-radius: 10px;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  animation: ${BounceEffect} 0.25s ease 1;

  ::-webkit-scrollbar {
    width: 12px;
    height: 90%;
  }

  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #efefef;
  }
`;
const DropdownItem = styled.div`
  padding: 8px;
  cursor: pointer;

  &:hover {
    background-color: #efefef;
  }
`;

const SVGDiv = styled.div`
  z-index: -1;
  padding: 2px;
`;
const Dropdown = ({ className, placeholder, options, value, selected }) => {
  const [showMenu, setShowMenu] = useState(false);
  const inputRef = useRef();

  const DropdownIcon = () => {
    return (
      <SVGDiv>
        <svg height="20" width="20" viewBox="0 0 20 20">
          <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
        </svg>
      </SVGDiv>
    );
  };
  useEffect(() => {
    const handler = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    };

    window.addEventListener("click", handler);
    return () => {
      window.removeEventListener("click", handler);
    };
  });

  const handleInputClick = (event) => {
    setShowMenu(!showMenu);
  };

  const getDisplay = () => {
    if (value) {
      return value;
    }
    return placeholder;
  };

  const onItemClick = (option) => {
    selected(option.label);
  };

  return (
    <DropdownContainer>
      <DropdownInput
        ref={inputRef}
        onClick={handleInputClick}
        className={className}
      >
        <div className="dropdown-selected-value">{getDisplay()}</div>
        <DropdownIcon />
      </DropdownInput>
      {showMenu && (
        <DropdownMenu>
          {options.map((options) => (
            <DropdownItem
              onClick={() => onItemClick(options)}
              key={options.label}
            >
              {options.label}
            </DropdownItem>
          ))}
        </DropdownMenu>
      )}
    </DropdownContainer>
  );
};

export default Dropdown;
