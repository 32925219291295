import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useLocation, useSearchParams } from "react-router-dom";
import { BounceEffect, LiveIndicator } from "../custom/animations";
import BrandListCompact from "../components/BrandListCompact";
import ReactPaginate from "react-paginate";
import { trackEvent } from "../custom/analytics";
import { ChartSVG, IngredientEyeSVG } from "../custom/svg";
import { Regions, electrolyteIngredients } from "../custom/functions";
import PopUp from "../components/PopUp";

// CSS FOLD
const NewPodiumDiv = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
  position: static;
  padding: 0px 0px 30px 0px;
  width: 1500px;
  animation: ${(props) => props.filtered && BounceEffect} 0.25s ease 1;

  @media (max-width: 760px) {
    display: none;
  }
`;
const PodiumDivMobile = styled.div`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: static;
  width: 70%;
  max-width: 700px;
  gap: 10px;
  display: flex;
  animation: ${(props) => props.filtered && BounceEffect} 0.25s ease 1;

  padding-top: 20px;
  padding-bottom: 20px;

  @media (max-width: 760px) {
    display: flex;
    width: 95%;
  }
`;
const SvgWrapper = styled.span`
  margin-right: 5px;
  margin-bottom: 3px;
  display: inline-flex;
  vertical-align: middle;

  @media (max-width: 760px) {
     margin-bottom: 2px;
  }
`;
const FilterSubtitle = styled.p`
  color: gray;
  font-size: 1rem;
  font-family: San Francisco Regular;
  padding: 0px 20px;
  max-width: 60%;

  @media (max-width: 760px) {
    font-size: 0.9rem;
    max-width: 80%;
  }

  @media (min-width: 760px) {
    &:hover {
      text-decoration: underline;
    }
  }

  &:active {
    text-decoration: underline;
  }
`;
const Title = styled.p`
  font-family: San Francisco Bold;
  font-size: 1.4rem;
  margin: 20px 0px 0px 0px;
  width: 90%;

  @media (max-width: 900px) {
    font-size: 1.3rem;
  }
`;
const FilterDiv = styled.div`
  width: 50rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  position: static;
  padding: 0px 0px 20px 0px;

  @media (max-width: 900px) {
    width: 90%;
    flex-wrap: wrap;
  }
`;
const FilterButtonDiv = styled.div`
  width: 40%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  position: static;
  padding: 0px 0px 20px 0px;

  @media (max-width: 900px) {
    width: 90%;
  }
`;
const FilterButtons = styled.button`
  all: unset;
  display: block;
  text-decoration: none;
  font-family: San Francisco Bold;
  width 120px;
  height: 40px;
  background-color: #efefef;
  border-radius: 10px;
  position: static;
  cursor: pointer;
  transition-duration: 0.25s;
  margin: 0px 0px 20px 0px;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;

  @media (min-width: 760px) {
    &:hover {
      box-shadow: 0px 0px 5px black;
    }
  }
`;
const ListDiv = styled.div`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: static;
  width: 70%;
  max-width: 700px;
  gap: 10px;
  display: flex;
  padding: 0px 0px 20px 0px;

  @media (max-width: 760px) {
    display: flex;
    width: 95%;
  }
`;
const ListShopText = styled.p`
  font-size: 1rem;
  color: black;
  margin: auto;
  line-height: 40px;
  position: relative;
`;
const StyledReactPaginate = styled(ReactPaginate).attrs({
  //activeClassName: 'active'
})`
  display: flex;
  flex-wrap: nowrap;
  cursor: pointer;
  justify-content: center;
  list-style: none;
  padding: 0px;

  li {
    padding-left: 10px;
    padding-right: 10px;
  }

  li.selected {
    text-decoration: underline;
  }
`;

const SuggestionDiv = styled.div`
  display: flex;
  flex-direction: row;
  // justify-content: center;
  gap: 10px;
  // margin-bottom: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  // margin-bottom: 15px;
  cursor: grab;
  user-select: none; // Prevent text selection during drag

  &:active {
    cursor: grabbing;
  }

  overflow-x: auto; // Changed from 'scroll' to 'auto'

  @media (min-width: 760px) {
    width: 70%;
    max-width: 700px;
  }

  @media (min-width: 760px) {
    justify-content: center;
  }

  // Hiding the scrollbar for WebKit browsers
  &::-webkit-scrollbar {
    display: none;
  }

  // For Firefox 64+
  scrollbar-width: none; // Hides the scrollbar in Firefox

  // For Internet Explorer and Edge
  -ms-overflow-style: none; // Hides the scrollbar in IE and Edge
`;
const Suggestion = styled.button`
  all: unset;
  display: block;
  background: #efefef;
  border-radius: 7px;
  position: static;
  cursor: pointer;
  transition-duration: 0.25s;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;

  &:active {
    cursor: grabbing;
  }

  @media (min-width: 760px) {
    &:hover {
      box-shadow: 0px 0px 5px black;
    }
  }
`;

const SuggestionText = styled.div`
  color: black;
  font-family: San Francisco Bold;
  font-size: 1rem;
  position: static;
  text-align: center;
  margin: 5px;
  white-space: nowrap;

  @media (max-width: 760px) {
    font-size: 0.9rem;
  }
`;
const BackDiv = styled.div`
  display: flex;
  width: 95%;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  line-height: 14px;
  font-family: San Francisco Regular;
  padding-bottom: 10px;
  // background: gray;

  @media (max-width: 760px) {
    width: 90%;
    height: 50px;
    line-height: 12px;
    padding-bottom: 0px;
  }
`;
const BackButton = styled.button`
  all: unset;
  display: flex;
  align-items: center;
  gap: 5px;

  &:active {
    text-decoration: underline;
  }

  &:hover {
    cursor: pointer;
  }
`;
const BackText = styled.p`
  font-size: 14px;
  color: black;
  // margin: 0px;

  @media (max-width: 760px) {
    font-size: 12px;
  }
`;
const RankInfo = styled.div`
  display: flex;
  align-items: left;
  width: 95%;
  padding-left: 10px;

  @media (max-width: 760px) {
    width: 90%;
  }
`;
const RankText = styled.p`
  font-size: 14px;
  color: gray;
  margin-top: 0px;
  text-align: left;

  @media (max-width: 760px) {
    font-size: 12px;
  }
`;

const Type = [
  {
    value: "powder",
    label: "Powder",
  },
  {
    value: "capsule",
    label: "Capsules",
  },
  {
    value: "packet",
    label: "Packets",
  },
  {
    value: "tablet",
    label: "Tablets",
  },
];

const Ingredients = [
  { value: "calcium", label: "Calcium" },
  { value: "chloride", label: "Chloride" },
  { value: "magnesium", label: "Magnesium" },
  { value: "potassium", label: "Potassium" },
  { value: "sodium", label: "Sodium" },
];

const SelectCategories = [
  { value: "rank", label: "Rank Ingredients" },
  { value: "type", label: "Type" },
];

function responseMaker(type) {
  if (type === "powder") {
    return "Electrolyte Powder";
  }
  if (type === "capsule") {
    return "Electrolyte Capsules";
  }
  if (type === "tablet") {
    return "Electrolyte Tablets";
  }
  if (type === "packet") {
    return "Electrolyte Packets";
  }

  return "All Electrolytes";
}

function subMaker([...ingredients]) {
  const findLabel = (value) => {
    const ingredient = Ingredients.find((ing) => ing.value === value);
    return ingredient ? ingredient.label : value;
  };

  const validIngredients = ingredients.filter(
    (ingredient) =>
      typeof ingredient === "string" &&
      ingredient !== "undefined" &&
      ingredient !== "null" &&
      ingredient.trim() !== ""
  );

  const revisedIngredients = validIngredients.map((ingredient) =>
    findLabel(ingredient)
  );

  if (revisedIngredients.length === 0) {
    return "No valid ingredients provided.";
  } else if (revisedIngredients.length === 1) {
    return "Ranked by " + revisedIngredients[0] + " value.";
  } else {
    return (
      "Ranked by " +
      revisedIngredients.slice(0, -1).join(", ") +
      " and " +
      revisedIngredients[revisedIngredients.length - 1] +
      " value."
    );
  }
}

function newapiHandler(selectedRank, filter, userCountry) {
  let link = "/api/electrolyte?";
  const params = new URLSearchParams();

  selectedRank.forEach((ingredient, index) => {
    const value = Ingredients.find((i) => i.value === ingredient)?.value;
    if (value) {
      params.append(`ingredient${index + 1}`, value);
    }
  });

  if (filter) {
    params.append("filter", filter);
  }

  params.append("region", userCountry);

  link += params.toString();
  // console.log(link);
  return link;
}

const ElectrolyteFilter = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [advancedMode, setAdvancedMode] = useState(false);
  const [infoActive, setInfoActive] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [regionSelected, setRegion] = useState(null);
  const [isPopupOpen, setPopupOpen] = useState(false);

  const openPopup = () => setPopupOpen(true);
  const closePopup = () => setPopupOpen(false);

  const getParamArray = (paramPrefix) => {
    const paramsArray = [];
    for (let i = 1; i <= 5; i++) {
      const param = searchParams.get(`${paramPrefix}${i}`);
      if (param) {
        paramsArray.push(param);
      }
    }
    return paramsArray;
  };

  const setParamArray = (params, paramPrefix, valuesArray) => {
    // Remove old parameters with the same prefix
    for (let i = 1; i <= 5; i++) {
      params.delete(`${paramPrefix}${i}`);
    }

    // Add new parameters
    valuesArray.forEach((value, index) => {
      if (value) {
        params.set(`${paramPrefix}${index + 1}`, value);
      }
    });
  };

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [finalItems, setFinalItems] = useState([]);
  const [emptySearch, setEmptySearch] = useState(false);

  const ref = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const excludeRef = useRef(null);
  const [excludeIsDragging, setExcludeIsDragging] = useState(false);
  const [excludeStartX, setExcludeStartX] = useState(0);
  const [excludeScrollLeft, setExcludeScrollLeft] = useState(0);

  const [selectedRankIngredients, setSelectedRankIngredients] = useState(() => {
    const rankIngredients = getParamArray("ingredient");
    return rankIngredients.length > 0
      ? rankIngredients
      : ["potassium", "sodium"];
  });
  const [selectedFilter, setSelectedFilter] = useState(
    searchParams.get("filter") || null
  );
  const [ingredientList, setIngredientList] = useState([...Ingredients]);
  const [typeList, setTypeList] = useState([...Type]);

  const startDragging = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - ref.current.offsetLeft);
    setScrollLeft(ref.current.scrollLeft);
  };

  const stopDragging = (event) => {
    setIsDragging(false);
  };

  const whileDragging = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - ref.current.offsetLeft;
    const walk = (x - startX) * 3; // the *3 is the scroll speed factor
    ref.current.scrollLeft = scrollLeft - walk;
  };

  const excludeStartDragging = (e) => {
    setExcludeIsDragging(true);
    setExcludeStartX(e.pageX - excludeRef.current.offsetLeft);
    setExcludeScrollLeft(excludeRef.current.scrollLeft);
  };

  const excludeStopDragging = (event) => {
    setExcludeIsDragging(false);
  };

  const excludeWhileDragging = (e) => {
    if (!excludeIsDragging) return;
    e.preventDefault();
    const x = e.pageX - excludeRef.current.offsetLeft;
    const walk = (x - excludeStartX) * 3; // the *3 is the scroll speed factor
    excludeRef.current.scrollLeft = excludeScrollLeft - walk;
  };

  const reorderList = (activeItem, list) => {
    const filteredList = list.filter((item) => item.value !== activeItem.value);

    return [activeItem, ...filteredList];
  };

  const removeActive = (activeItem, list, selectedLength) => {
    const filteredList = list.filter((item) => item.value !== activeItem.value);
    filteredList.push(activeItem);

    const firstHalf = filteredList.slice(0, selectedLength - 1);
    const secondHalf = filteredList.slice(selectedLength - 1).sort((a, b) => {
      if (a.value < b.value) return -1;
      if (a.value > b.value) return 1;
      return 0;
    });

    return [...firstHalf, ...secondHalf];
  };

  const handleCategoryClick = (item) => {
    setSelectedCategory((prevSelected) => {
      if (item.value === prevSelected) {
        return null;
      } else {
        return item.value;
      }
    });
  };

  const handleRegionClick = (item) => {
    // props.setUserCountry(item.value);
    setRegion((prevSelected) => {
      if (prevSelected === item.value) {
        // setTypeList(
        //   removeActive(item, typeList, 1)
        // );
        return prevSelected;
      } else if (prevSelected !== item.value) {
        // setTypeList(reorderList(item, typeList));
        return item.value;
      } else {
        return prevSelected;
      }
    });
  };

  const handleRankClick = (item) => {
    setSelectedRankIngredients((prevSelected) => {
      if (prevSelected.includes(item.value)) {
        // Prevent removal if it would result in zero selections
        if (prevSelected.length === 1) {
          return prevSelected;
        }
        setIngredientList(
          removeActive(item, ingredientList, selectedRankIngredients.length)
        );
        return prevSelected.filter((ingredient) => ingredient !== item.value);
      } else if (prevSelected.length < 5) {
        setIngredientList(reorderList(item, ingredientList));
        return [...prevSelected, item.value];
      } else {
        return prevSelected;
      }
    });
  };

  const handleExcludeClick = (item) => {
    setSelectedFilter((prevSelected) => {
      if (prevSelected === item.value) {
        // setTypeList(
        //   removeActive(item, typeList, 1)
        // );
        return null;
      } else if (prevSelected !== item.value) {
        // setTypeList(reorderList(item, typeList));
        return item.value;
      } else {
        return prevSelected;
      }
    });
  };

  const reorderSelectedItems = (selectedItems, fullList) => {
    // Filter the list to get the selected items first
    const selectedItemsList = fullList.filter((item) =>
      selectedItems.includes(item.value)
    );
    // Filter the list to get the non-selected items
    const nonSelectedItemsList = fullList.filter(
      (item) => !selectedItems.includes(item.value)
    );
    // Concatenate the selected items at the front and non-selected items at the end
    return [...selectedItemsList, ...nonSelectedItemsList];
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        // apiHandler(ingredientOne, ingredientTwo, excludeOne, excludeTwo)
        newapiHandler(selectedRankIngredients, selectedFilter, regionSelected)
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const result = await response.json();
      trackEvent("Filter", "Filtered: Electrolytes", "");
      if (result.length > 0) {
        setEmptySearch(false);
        // setMobilePodium([...result.slice(0, 3)]);
        // setList([...result.slice(3, result.length)]);

        const params = new URLSearchParams();
        setParamArray(params, "ingredient", selectedRankIngredients);
        if (selectedFilter) params.append("filter", selectedFilter);
        if (regionSelected) params.append("region", regionSelected);

        setSearchParams(params, { replace: true });

        props.setElectrolyteFilter([...result]);
        props.setElectrolyteFilterLink("/electrolyte?" + params.toString());

        setIngredientList(
          reorderSelectedItems(selectedRankIngredients, ingredientList)
        );

        let previousValueRank = null;
        let currentRank = 1;
        let previousOldValueRank = null;
        let oldCurrentRank = 1;

        // First, calculate the current ranks while handling ties
        const rankedItems = result.map((item, index) => {
          if (
            previousValueRank !== null &&
            item.value_rank === previousValueRank
          ) {
            currentRank--;
          }
          const rankToShow = currentRank;
          previousValueRank = item.value_rank;
          currentRank++;
          return { ...item, currentRank: rankToShow };
        });

        // Sort items by old_value_rank
        const sortedByOldValueRank = [...rankedItems].sort(
          (a, b) => a.old_value_rank - b.old_value_rank
        );

        // Calculate old ranks while handling ties
        const rankedOldItems = sortedByOldValueRank.map((item, index) => {
          if (
            previousOldValueRank !== null &&
            item.old_value_rank === previousOldValueRank
          ) {
            oldCurrentRank--;
          }
          const oldRankToShow = oldCurrentRank;
          previousOldValueRank = item.old_value_rank;
          oldCurrentRank++;
          return { ...item, oldRank: oldRankToShow };
        });

        // Merge old ranks back into the original items based on value_rank
        const mergedItems = rankedItems.map((item) => {
          const rankedOldItem = rankedOldItems.find(
            (i) => i.brand === item.brand && i.type === item.type
          );
          return {
            ...item,
            oldRank: rankedOldItem ? rankedOldItem.oldRank : null,
          };
        });

        // console.log(mergedItems);
        setFinalItems(mergedItems);
        setLoading(false);
      } else {
        setEmptySearch(true);
      }
    } catch (error) {
      setError(error); // Update the state with the error
    }
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, [props.userCountry]);

  useEffect(() => {
    if (regionSelected === null && props.userCountry) {
      setRegion(searchParams.get("region") || props.userCountry);
    }
  }, [props.userCountry, searchParams]);

  useEffect(() => {
    if (!regionSelected) return;
    // window.scroll(0, 0);
    fetchData();
  }, [selectedRankIngredients, selectedFilter, regionSelected]);

  const getStyleList = (item, list) => {
    return list.includes(item.value)
      ? { background: "#2e2e2e", color: "white" } // Adding color: "white" for better visibility
      : { color: "#efefef" };
  };

  const getTextStyleList = (item, list) => {
    return list.includes(item.value)
      ? { color: "white" } // Adding color: "white" for better visibility
      : { color: "black" };
  };

  const getCategoryStyle = (item, selected) => {
    return item.value === selected
      ? { background: "#2e2e2e", color: "white" } // Adding color: "white" for better visibility
      : { color: "#efefef" };
  };

  const getCategoryTextStyle = (item, selected) => {
    return item.value === selected
      ? { color: "white" } // Adding color: "white" for better visibility
      : { color: "black" };
  };

  const getStyle = (item, list) => {
    return item.value === list
      ? { background: "#2e2e2e", color: "white" } // Adding color: "white" for better visibility
      : { color: "#efefef" };
  };

  const getTextStyle = (item, list) => {
    return item.value === list
      ? { color: "white" } // Adding color: "white" for better visibility
      : { color: "black" };
  };

  const infoToggle = () => {
    setInfoActive(!infoActive);
  };

  return (
    <center style={{ overflowX: "hidden" }}>
      <BackDiv>
        {/* <StyledLink to={props.preFilterLink} method="get"> */}
        <BackButton onClick={infoToggle}>
          <ChartSVG />
          <BackText>Ranking Info {!infoActive ? "+" : "-"}</BackText>
        </BackButton>
        <LiveIndicator />
        {/* </StyledLink>
          <BackText style={{color:"gray"}}>{rank} Place</BackText> */}
      </BackDiv>
      {infoActive && (
        <RankInfo>
          <RankText>
            Products are ranked by Total Selected Ingredient Value. <br />
            Formula: (Total Servings * Ingredient Serving) / Price
          </RankText>
        </RankInfo>
      )}
      <PodiumDivMobile style={{ paddingTop: "0px" }}>
        {!loading ? (
          finalItems.slice(0, 3).map((item, index) => {
            if (!item) return null;
            return (
              <BrandListCompact
                item={item}
                filterRank={item.currentRank}
                oldRank={item.oldRank}
                userCountry={regionSelected}
                ticker={true}
                ai={true}
                key={index}
                loading={false}
              />
            );
          })
        ) : (
          <>
            <BrandListCompact filterRank={1} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={2} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={3} loading={true}></BrandListCompact>
          </>
        )}
      </PodiumDivMobile>
      <Title>
        {!emptySearch ? responseMaker(selectedFilter) : "No Search Results"}
      </Title>
      <FilterSubtitle onClick={!emptySearch ? openPopup : null}>
        {!emptySearch && (
          <SvgWrapper>
            <IngredientEyeSVG />
          </SvgWrapper>
        )}
        {!emptySearch
          ? subMaker(selectedRankIngredients)
          : "Oops! Your search didn't return products. Please try again."}
      </FilterSubtitle>
      <SuggestionDiv
        style={{ justifyContent: "center", paddingBottom: "10px" }}
      >
        {/* <SuggestionText>Type:</SuggestionText> */}
        {Regions.map(
          (item) =>
            item && (
              <Suggestion
                key={item.value}
                onClick={() => handleRegionClick(item)}
                style={getStyle(item, regionSelected || "")}
              >
                <SuggestionText
                  style={getTextStyle(item, regionSelected || "")}
                >
                  {item.label}
                </SuggestionText>
              </Suggestion>
            )
        )}
      </SuggestionDiv>
      <SuggestionDiv
        style={
          selectedCategory === null
            ? {
                paddingTop: "5px",
                marginBottom: "30px",
                justifyContent: "center",
              }
            : {
                paddingTop: "5px",
                justifyContent: "center",
              }
        }
      >
        {SelectCategories.map(
          (item) =>
            item &&
            !item.hide && (
              <Suggestion
                key={item.value}
                onClick={() => handleCategoryClick(item)}
                style={getCategoryStyle(item, selectedCategory)}
              >
                <SuggestionText
                  style={getCategoryTextStyle(item, selectedCategory)}
                >
                  {item.label}
                </SuggestionText>
              </Suggestion>
            )
        )}
      </SuggestionDiv>
      {selectedCategory === "rank" && (
        <SuggestionDiv
          ref={ref}
          onMouseDown={startDragging}
          onMouseLeave={stopDragging}
          onMouseUp={stopDragging}
          onMouseMove={whileDragging}
          style={{ paddingTop: "5px", marginBottom: "30px" }}
        >
          {ingredientList.map(
            (item) =>
              item && (
                <Suggestion
                  key={item.value}
                  onClick={() => handleRankClick(item)}
                  style={getStyleList(item, selectedRankIngredients)}
                >
                  <SuggestionText
                    style={getTextStyleList(item, selectedRankIngredients)}
                  >
                    {item.label}
                  </SuggestionText>
                </Suggestion>
              )
          )}
        </SuggestionDiv>
      )}
      {selectedCategory === "type" && (
        <SuggestionDiv
          ref={excludeRef}
          onMouseDown={excludeStartDragging}
          onMouseLeave={excludeStopDragging}
          onMouseUp={excludeStopDragging}
          onMouseMove={excludeWhileDragging}
          style={{
            paddingTop: "5px",
            marginBottom: "30px",
            justifyContent: "center",
          }}
        >
          {typeList.map(
            (item) =>
              item && (
                <Suggestion
                  key={item.value}
                  onClick={() => handleExcludeClick(item)}
                  style={getStyle(item, selectedFilter)}
                >
                  <SuggestionText style={getTextStyle(item, selectedFilter)}>
                    {item.label}
                  </SuggestionText>
                </Suggestion>
              )
          )}
        </SuggestionDiv>
      )}
      {/* <FilterButtonDiv>
          <FilterButtons
            onClick={() => {
              modeToggle();
            }}
          >
            <ListShopText>{!advancedMode ? "Advanced" : "Normal"}</ListShopText>
          </FilterButtons>
          <FilterButtons
            onClick={() => {
              setSelectedRankIngredients(["calcium", "chloride", "magnesium", "potassium", "sodium"]);
              setSelectedFilter([]);
              setIngredientList([...Ingredients]);
              setTypeList([...Type]);
            }}
          >
            <ListShopText>Reset</ListShopText>
          </FilterButtons>
        </FilterButtonDiv> */}
      <ListDiv>
        {!loading ? (
          finalItems.slice(3).map((item, index) => {
            if (!item) return null;
            return (
              <BrandListCompact
                item={item}
                filterRank={item.currentRank}
                oldRank={item.oldRank}
                userCountry={regionSelected}
                ticker={true}
                ai={true}
                key={index}
                loading={false}
              />
            );
          })
        ) : (
          <>
            <BrandListCompact filterRank={4} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={5} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={6} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={7} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={8} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={9} loading={true}></BrandListCompact>
          </>
        )}
      </ListDiv>
      <PopUp
        isOpen={isPopupOpen}
        onClose={closePopup}
        ingredients={selectedRankIngredients}
        ingredientList={electrolyteIngredients}
      />
      {/* <StyledReactPaginate
          pageCount={totalPages}
          onPageChange={handlePageChange}
          previousLabel={"Prev"}
        /> */}
    </center>
  );
};

export default ElectrolyteFilter;
