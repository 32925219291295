import React from "react";
import logo from "../images/supphead.png";
import styled from "styled-components";
import { InstaSVG, TikTokSVG } from "../custom/svg";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";

const FooterContainer = styled.div`
  display: flex;
  height: 100px;
  flex-direction: row;
  justify-content: space-evenly;
  border-top: solid #efefef 1px;
  margin-top: ${(props) => props.pathname !== "/" && "20px"};
  position: absolute;
  bottom: 0;
  width: 100%;
  font-family: San Francisco Regular;
`;
const SectionDiv = styled.div`
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  height: 100%;

  @media (max-width: 760px) {
    width: 30%;
    margin-left: ${(props) => props.left && "10px"};
    margin-right: ${(props) => props.right && "10px"};
  }
`;
const Logo = styled.img`
  display: block;
  width: 60%;
  max-width: 200px;
  margin: 1em 0px; // <p> margins

  @media (max-width: 760px) {
    width: 90%;
  }
`;
const Text = styled.p`
  font-size: 12px;
`;
const SocialDiv = styled.div`
  margin: auto;
  align-items: left;
`;
const ItemDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
const FooterLinks = styled.a`
  text-decoration: none;
  color: black;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;
const FooterReactLink = styled(Link)`
  text-decoration: none;
  color: black;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export default function Footer() {
  const { pathname } = useLocation();

  return (
    <center>
      <FooterContainer pathname={pathname}>
        <SectionDiv left={true}>
          <Logo src={logo} />
          <Text>© SUPPHEAD LLC</Text>
        </SectionDiv>
        <SectionDiv>
          <SocialDiv>
            <ItemDiv>
              <InstaSVG />
              <FooterLinks
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/supphead"
              >
                <Text>Instagram</Text>
              </FooterLinks>
            </ItemDiv>
            <ItemDiv>
              <TikTokSVG />
              <FooterLinks
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.tiktok.com/@supphead"
              >
                <Text>TikTok</Text>
              </FooterLinks>
            </ItemDiv>
          </SocialDiv>
        </SectionDiv>
        <SectionDiv right={true}>
          <FooterLinks
            target="_blank"
            rel="noopener noreferrer"
            href="mailto:contact@supphead.com?subject=Contact"
          >
            <Text>Contact Us</Text>
          </FooterLinks>
          <FooterReactLink to={"/about"}>
            <Text>About</Text>
          </FooterReactLink>
        </SectionDiv>
      </FooterContainer>
    </center>
  );
}
