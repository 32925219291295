import React, { useState, useEffect } from "react";
import styled from "styled-components";

const ErrorText = styled.p`
  font-family: San Francisco Bold;
  font-size: 1.5rem;
  transition-duration: 0.25s;

  @media (max-width: 760px) {
    font-size: 1.5rem;
  }
`;
const ItemBar = styled.div`
  height: 3px;
  width: 30%;
  background-color: #efefef;
  border-radius: 50px;
  position: static;

  @media (max-width: 760px) {
    width: 80%;
  }
`;
const Header = styled.p`
  font-family: San Francisco Bold;
  font-size: 2.25rem;
  transition-duration: 0.25s;
  padding: 10px;

  @media (max-width: 760px) {
    font-size: 2rem;
  }
`;
const Fact = styled.p`
  font-family: San Francisco Regular;
  font-size: 1rem;
  transition-duration: 0.25s;
  max-width: 50%;
  padding-bottom: 25%;

  @media (max-width: 760px) {
    max-width: 80%;
    padding-bottom: 70%;
  }
`;

const Facts = [
  {
    fact: "Ronnie Coleman and Lee Haney are tied for most Mr. Olympia wins (8).",
  },
  {
    fact: "Despite being some of the most recognizable names in bodybuilding, Tom Platz, Kevin Levrone, and Flex Wheeler never won the Mr. Olympia.",
  },
  {
    fact: "When Arnold Schwarzenegger won the 1980 Mr. Olympia, the crowd booed. Many felt his sheer influence had won him the contest and had cheated Mike Mentzer out of a victory.",
  },
  {
    fact: "Tom Platz once squatted 525lbs for 23 reps.",
  },
  {
    fact: "Arnold Schwarzenegger is a 7-time Mr. Olympia winner.",
  },
  {
    fact: "There have only been 17 bodybuilders that have won the Mr. Olympia overall.",
  },
];

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

const Error = () => {
  const [fact, setFact] = useState("");

  useEffect(() => {
    const factNum = getRandomInt(Facts.length);
    setFact(Facts[factNum].fact);
  }, []);

  return (
    <center>
      <ErrorText>404: Page doesn't exist</ErrorText>
      <ItemBar />
      <Header>Did you know?</Header>
      <Fact>{fact}</Fact>
    </center>
  );
};

export default Error;
