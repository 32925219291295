import React, { useEffect } from "react";
import styled from "styled-components";
import { CloseSVG } from "../custom/svg";

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  touch-action: none; /* Prevents touch scrolling on touch devices */
  z-index: 99; // prevents items showing in front
`;

const PopupContainer = styled.div`
  background-color: #fff;
  padding: 20px;
  // margin-top: 10%;
  border-radius: 15px;
  max-width: 500px;
  width: 50%;
  text-align: center;
  position: relative;
  // overflow-y: auto;
  // overflow: hidden;

  @media (max-width: 760px) {
    width: 85%;
  }
`;

const CloseButton = styled.button`
  all: unset;
  position: absolute;
  top: 20px;
  right: 20px;
  background: white;
  border-radius: 5px;
  border: none;
  padding-top: 3px;

  &:hover {
    cursor: pointer;
  }

  &:active {
    cursor: pointer;
  }
`;
const PopUpIngredientDiv = styled.div`
  display: flex;
  // padding-left: 15px;
  padding: 10px;
  padding-top: 25px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
`;
const PopUpTitle = styled.p`
  display: block;
  height: auto;
  font-family: San Francisco Bold;
  font-size: 1.2rem;
  position: static;
  text-align: left;
  margin: 0px;

  @media (max-width: 760px) {
    font-size: 1.3rem;
  }
`;
const PopUpDefinition = styled.p`
  font-size: 14px;
  font-family: San Francisco Regular;
  color: gray;
  text-align: left;
  padding-bottom: 10px;
  margin: 0px;
`;
const ScrollableContent = styled.div`
  max-height: 60vh; /* Restrict the height of the scrollable content */
  overflow-y: auto; /* Makes the content inside this div scrollable */

  /* Hide scrollbar for Chrome, Safari, and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge, and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

const PopUp = ({ isOpen, onClose, ingredients, ingredientList }) => {
  useEffect(() => {
    if (isOpen) {
      // Prevent background scrolling
      document.body.style.overflow = "hidden";
    } else {
      // Re-enable background scrolling
      document.body.style.overflow = "";
    }

    // Cleanup function to reset the style when the component is unmounted or closed
    return () => {
      document.body.style.overflow = "";
    };
  }, [isOpen]);

  if (!isOpen) return null;

  const handleOverlayClick = (event) => {
    onClose(); // Close the popup if clicked outside
  };

  const handlePopupClick = (event) => {
    event.stopPropagation(); // Prevent the click from reaching the overlay
  };

  return (
    <Overlay onClick={handleOverlayClick}>
      <PopupContainer onClick={handlePopupClick}>
        <CloseButton onClick={onClose}>
          <CloseSVG color={"black"} />
        </CloseButton>
        <ScrollableContent>
          {
            <PopUpIngredientDiv>
              {ingredients.map((ingredient, index) => {
                if (!ingredient || ingredient === "undefined") {
                  return null;
                }

                const tableIngredient = ingredientList.find(
                  (item) => item.value === ingredient
                );
                return (
                  <React.Fragment key={ingredient}>
                    <PopUpTitle>{tableIngredient.label}</PopUpTitle>
                    <PopUpDefinition
                      style={
                        index + 1 === ingredientList.length
                          ? { paddingBottom: "0px" }
                          : {}
                      }
                    >
                      {tableIngredient.definition}
                    </PopUpDefinition>
                  </React.Fragment>
                );
              })}
            </PopUpIngredientDiv>
          }
        </ScrollableContent>
      </PopupContainer>
    </Overlay>
  );
};

export default PopUp;
