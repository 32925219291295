export function array_move(arr, old_index, new_index) {
  if (new_index >= arr.length) {
    var k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr; // for testing
}

export const creatineIngredients = [
  {
    value: "hcl",
    label: "Creatine HCl",
    definition:
      "Creatine HCl (Creatine Hydrochloride) is a form of creatine where a hydrochloride group is attached to the creatine molecule, enhancing its water solubility and absorption in the body. This increased solubility means that Creatine HCl is more easily dissolved in liquids, potentially leading to better absorption and reduced gastrointestinal discomfort compared to other forms, such as creatine monohydrate. As a result, lower doses of Creatine HCl are often required to achieve similar effects, such as increased muscle strength, endurance, and recovery, making it a popular choice for athletes and bodybuilders. Creatine HCl is valued for its effectiveness in promoting muscle growth, improving performance in high-intensity workouts, and reducing the likelihood of bloating or water retention, which can sometimes be associated with other forms of creatine supplementation.",
  },
  {
    value: "micronized",
    label: "Micronized Creatine Monohydrate",
    definition:
      "Micronized Creatine Monohydrate is a form of creatine monohydrate that has been processed into smaller particles, or micronized, to enhance its solubility in water and absorption in the body. Creatine is a naturally occurring compound found in small amounts in certain foods and synthesized in the body, primarily stored in muscles, where it helps regenerate adenosine triphosphate (ATP), the primary energy carrier for short bursts of high-intensity activities. Micronized creatine monohydrate is popular among athletes, bodybuilders, and fitness enthusiasts for its potential to increase muscle strength, power, and endurance, as well as support faster recovery after intense workouts. The micronization process makes this form of creatine easier to mix, more stable, and potentially more effective in delivering creatine to muscle cells, enhancing overall performance and muscle growth.",
  },
  {
    value: "monohydrate",
    label: "Creatine Monohydrate",
    definition:
      "Creatine Monohydrate is the most common and well-researched form of creatine, widely used to increase muscle strength, enhance endurance, and support muscle growth. It works by replenishing ATP, the primary energy source for muscle contractions, allowing for improved performance during high-intensity exercise. Creatine Monohydrate is known for its effectiveness in building lean muscle mass and is a staple ingredient in many sports and bodybuilding supplements.",
  },
];

export const proteinIngredients = [
  {
    value: "blend",
    label: "Whey Blend",
    hide: true,
    definition:
      "Whey Blend is a type of protein supplement that combines different forms of whey protein, typically whey concentrate, whey isolate, and sometimes whey hydrolysate. This combination provides a balance of protein absorption rates and nutritional benefits, offering a versatile option for various dietary and fitness needs. Whey concentrate is rich in protein but also contains some fats and carbohydrates, providing a slightly slower release of amino acids compared to whey isolate, which is more refined with a higher protein content and lower levels of fat and lactose. The inclusion of whey hydrolysate, which is pre-digested and rapidly absorbed, can further enhance the blend's effectiveness for muscle recovery and growth. A whey blend is popular among athletes and fitness enthusiasts as it provides a well-rounded protein source that supports muscle repair, growth, and recovery over an extended period, catering to both immediate and sustained protein needs.",
  },
  {
    value: "concentrate",
    label: "Whey Concentrate",
    hide: true,
    definition:
      "Whey Concentrate is a form of whey protein that is produced by extracting protein from whey, the liquid byproduct of cheese production. It typically contains about 70-80% protein by weight, with the remaining percentage composed of fats, carbohydrates, and lactose. Whey concentrate is valued for its high-quality protein content, which includes all essential amino acids and is particularly rich in branched-chain amino acids (BCAAs) that support muscle protein synthesis and recovery. Because it retains more of the natural nutrients found in whey, including some fats and carbohydrates, whey concentrate is often favored by those looking for a more balanced protein source. It is widely used by athletes, bodybuilders, and fitness enthusiasts to promote muscle growth, improve strength, and aid recovery after exercise. Due to its slightly lower protein concentration compared to whey isolate, it is generally more affordable and provides a creamier texture, making it a popular choice for protein shakes and smoothies.",
  },
  {
    value: "clear",
    label: "Clear Whey Isolate",
    hide: true,
    definition:
      "Clear Whey Isolate is a high-quality protein supplement derived from whey protein isolate, but with a unique formulation that makes it light, clear, and more refreshing than traditional creamy protein shakes. It undergoes additional processing to remove fats, lactose, and other non-protein components, resulting in a low-carb, low-fat, and rapidly absorbed protein source. Clear whey isolate is known for its smooth texture, fruit-flavored varieties, and high protein content per serving, making it an ideal post-workout option for those looking to support muscle recovery and growth without the heaviness of regular shakes.",
  },
  {
    value: "isolate",
    label: "Whey Isolate",
    hide: true,
    definition:
      "Whey Isolate is a highly purified form of whey protein derived from milk, known for its high protein content and low levels of fat, carbohydrates, and lactose. Through a process of filtration and refinement, whey isolate is separated from most of the non-protein components, resulting in a protein powder that typically contains 90% or more protein by weight. This makes it an excellent choice for individuals seeking to increase their protein intake while minimizing the intake of fats and sugars. Whey isolate is quickly absorbed by the body, making it ideal for post-workout recovery to support muscle repair and growth. It is also popular among those with lactose intolerance or those on calorie-restricted diets due to its reduced lactose content and lower caloric profile. Additionally, whey isolate is rich in essential amino acids, particularly branched-chain amino acids (BCAAs), which are crucial for muscle protein synthesis and overall muscle health.",
  },
  {
    value: "vegan",
    label: "Vegan Protein",
    hide: true,
    definition:
      "Vegan Protein is a plant-based protein supplement derived from various sources such as peas, rice, hemp, soy, chia seeds, and quinoa. It is designed to provide a high-quality protein alternative to animal-based proteins like whey or casein, making it suitable for vegetarians, vegans, and those with dairy allergies or intolerances. Vegan protein powders are rich in essential amino acids necessary for muscle growth, repair, and overall health. While some single plant proteins may lack one or more essential amino acids, many vegan protein products combine multiple sources (e.g., pea and rice protein) to create a complete amino acid profile similar to that of animal proteins. Vegan protein is also typically high in fiber and low in saturated fat, supporting digestive health and overall wellness. It is commonly used by athletes, bodybuilders, and fitness enthusiasts looking to build muscle, support recovery, or maintain a balanced diet free from animal products.",
  },
  {
    value: "calories",
    label: "Calories",
    unit: "",
    definition:
      "A unit of energy measurement used to quantify the amount of energy provided by food and beverages. Calories are essential for the body's basic functions and physical activities, supplying the energy needed to sustain life. In nutrition, calories refer to kilocalories (kcal), which represent the energy required to raise the temperature of one kilogram of water by one degree Celsius. Consuming calories from food allows the body to perform vital functions, such as breathing, circulation, and movement. The balance between caloric intake and expenditure plays a critical role in managing body weight and overall health.",
  },
  {
    value: "fat",
    label: "Fat",
    unit: "g",
    definition:
      "An essential macronutrient, fat provides a concentrated source of energy for the body, delivering more than twice the calories per gram compared to carbohydrates or protein. Fats are crucial for absorbing fat-soluble vitamins (A, D, E, and K), protecting vital organs, insulating the body, and supporting cell membrane structure. They can be categorized into different types, including saturated fats, unsaturated fats (monounsaturated and polyunsaturated), and trans fats, each with varying effects on health. Consuming healthy fats, such as those from avocados, nuts, seeds, and oily fish, is important for maintaining heart health, brain function, and overall well-being.",
  },
  {
    value: "saturatedfat",
    label: "Saturated Fat",
    unit: "g",
    definition:
      "A type of dietary fat, saturated fat is typically solid at room temperature and is found primarily in animal products like meat, butter, cheese, and dairy, as well as some plant-based sources such as coconut oil and palm oil. Saturated fats have a chemical structure fully 'saturated' with hydrogen atoms, meaning they contain no double bonds between carbon molecules. While the body requires some saturated fat for energy and other functions, excessive intake is associated with an increased risk of heart disease due to its potential to raise LDL (bad) cholesterol levels. Balancing saturated fat intake with healthier fats, like monounsaturated and polyunsaturated fats, is recommended for overall cardiovascular health.",
  },
  {
    value: "carbs",
    label: "Carbohydrates",
    unit: "g",
    definition:
      "A primary source of energy for the body, carbohydrates (carbs) are macronutrients found in foods such as grains, fruits, vegetables, and legumes. They are broken down into glucose during digestion, which is then used by cells for fuel. Carbs are essential for proper brain function, physical activity, and overall metabolic processes. They can be categorized into simple carbohydrates (sugars) and complex carbohydrates (starches and fibers), each affecting the body differently in terms of energy release and digestion. Managing carbohydrate intake is important for maintaining stable blood sugar levels and supporting overall health.",
  },
  {
    value: "dietaryfiber",
    label: "Dietary Fiber",
    unit: "g",
    definition:
      "A type of carbohydrate that the body cannot digest, fiber is found in plant-based foods such as fruits, vegetables, whole grains, legumes, nuts, and seeds. Unlike other carbohydrates, fiber passes through the digestive system relatively intact, aiding in digestive health by adding bulk to stools and promoting regular bowel movements. Fiber is classified into two types: soluble, which dissolves in water and can help lower blood cholesterol and glucose levels, and insoluble, which does not dissolve in water and helps move food through the digestive tract. Adequate fiber intake supports digestive health, helps control blood sugar levels, aids in weight management, and reduces the risk of certain diseases, such as heart disease and type 2 diabetes.",
  },
  {
    value: "protein",
    label: "Protein",
    unit: "g",
    definition:
      "A crucial macronutrient for the body, protein is essential for building and repairing tissues, including muscles, skin, and organs. It is composed of amino acids, which are the building blocks required for various bodily functions, such as enzyme production, hormone regulation, and immune response. Protein can be obtained from both animal sources, like meat, fish, eggs, and dairy, and plant sources, such as beans, lentils, nuts, and seeds. Adequate protein intake is vital for muscle growth, recovery, and overall health, particularly in active individuals or those seeking to build muscle mass.",
  },
  {
    value: "sugar",
    label: "Sugar",
    unit: "g",
    definition:
      "A type of simple carbohydrate, sugar is a quick source of energy that is easily absorbed by the body. Sugars can be found naturally in foods such as fruits, vegetables, and dairy (in the form of fructose, glucose, and lactose) or added to foods and beverages during processing or preparation (such as table sugar or high-fructose corn syrup). While natural sugars provide essential nutrients along with energy, added sugars contribute extra calories without additional nutrients, which can lead to weight gain and increased risk of health issues like type 2 diabetes, heart disease, and tooth decay. Managing sugar intake, especially from added sources, is important for maintaining overall health and well-being.",
  },
  {
    value: "addedsugars",
    label: "Added Sugar",
    unit: "g",
    definition:
      "Added sugar refers to any sugars or sweeteners that are added to foods or beverages during processing or preparation, rather than those naturally occurring in fruits, vegetables, or dairy products. Common forms of added sugar include table sugar (sucrose), high-fructose corn syrup, honey, and syrups. These sugars are often used to enhance flavor, preserve food, or improve texture but provide no essential nutrients and are considered 'empty calories.' High intake of added sugars is associated with various health risks, including weight gain, obesity, type 2 diabetes, heart disease, and dental cavities. Managing and reducing the consumption of added sugars is crucial for maintaining overall health, and it is recommended to limit added sugar intake to less than 10% of total daily calories for most individuals.",
  },
  {
    value: "creatine",
    label: "Creatine Monohydrate",
    unit: "mg",
    definition:
      "Creatine Monohydrate is the most common and well-researched form of creatine, widely used to increase muscle strength, enhance endurance, and support muscle growth. It works by replenishing ATP, the primary energy source for muscle contractions, allowing for improved performance during high-intensity exercise. Creatine Monohydrate is known for its effectiveness in building lean muscle mass and is a staple ingredient in many sports and bodybuilding supplements.",
  },
];

export const massIngredients = [
  {
    value: "calories",
    label: "Calories",
    unit: "",
    definition:
      "A unit of energy measurement used to quantify the amount of energy provided by food and beverages. Calories are essential for the body's basic functions and physical activities, supplying the energy needed to sustain life. In nutrition, calories refer to kilocalories (kcal), which represent the energy required to raise the temperature of one kilogram of water by one degree Celsius. Consuming calories from food allows the body to perform vital functions, such as breathing, circulation, and movement. The balance between caloric intake and expenditure plays a critical role in managing body weight and overall health.",
  },
  {
    value: "fat",
    label: "Fat",
    unit: "g",
    definition:
      "An essential macronutrient, fat provides a concentrated source of energy for the body, delivering more than twice the calories per gram compared to carbohydrates or protein. Fats are crucial for absorbing fat-soluble vitamins (A, D, E, and K), protecting vital organs, insulating the body, and supporting cell membrane structure. They can be categorized into different types, including saturated fats, unsaturated fats (monounsaturated and polyunsaturated), and trans fats, each with varying effects on health. Consuming healthy fats, such as those from avocados, nuts, seeds, and oily fish, is important for maintaining heart health, brain function, and overall well-being.",
  },
  {
    value: "saturatedfat",
    label: "Saturated Fat",
    unit: "g",
    definition:
      "A type of dietary fat, saturated fat is typically solid at room temperature and is found primarily in animal products like meat, butter, cheese, and dairy, as well as some plant-based sources such as coconut oil and palm oil. Saturated fats have a chemical structure fully 'saturated' with hydrogen atoms, meaning they contain no double bonds between carbon molecules. While the body requires some saturated fat for energy and other functions, excessive intake is associated with an increased risk of heart disease due to its potential to raise LDL (bad) cholesterol levels. Balancing saturated fat intake with healthier fats, like monounsaturated and polyunsaturated fats, is recommended for overall cardiovascular health.",
  },
  {
    value: "carbs",
    label: "Carbohydrates",
    unit: "g",
    definition:
      "A primary source of energy for the body, carbohydrates (carbs) are macronutrients found in foods such as grains, fruits, vegetables, and legumes. They are broken down into glucose during digestion, which is then used by cells for fuel. Carbs are essential for proper brain function, physical activity, and overall metabolic processes. They can be categorized into simple carbohydrates (sugars) and complex carbohydrates (starches and fibers), each affecting the body differently in terms of energy release and digestion. Managing carbohydrate intake is important for maintaining stable blood sugar levels and supporting overall health.",
  },
  {
    value: "dietaryfiber",
    label: "Dietary Fiber",
    unit: "g",
    definition:
      "A type of carbohydrate that the body cannot digest, fiber is found in plant-based foods such as fruits, vegetables, whole grains, legumes, nuts, and seeds. Unlike other carbohydrates, fiber passes through the digestive system relatively intact, aiding in digestive health by adding bulk to stools and promoting regular bowel movements. Fiber is classified into two types: soluble, which dissolves in water and can help lower blood cholesterol and glucose levels, and insoluble, which does not dissolve in water and helps move food through the digestive tract. Adequate fiber intake supports digestive health, helps control blood sugar levels, aids in weight management, and reduces the risk of certain diseases, such as heart disease and type 2 diabetes.",
  },
  {
    value: "protein",
    label: "Protein",
    unit: "g",
    definition:
      "A crucial macronutrient for the body, protein is essential for building and repairing tissues, including muscles, skin, and organs. It is composed of amino acids, which are the building blocks required for various bodily functions, such as enzyme production, hormone regulation, and immune response. Protein can be obtained from both animal sources, like meat, fish, eggs, and dairy, and plant sources, such as beans, lentils, nuts, and seeds. Adequate protein intake is vital for muscle growth, recovery, and overall health, particularly in active individuals or those seeking to build muscle mass.",
  },
  {
    value: "cholesterol",
    label: "Cholesterol",
    unit: "mg",
    definition:
      "Cholesterol is a waxy, fat-like substance found in all cells of the body, essential for producing hormones, vitamin D, and substances that help digest foods. It is carried through the bloodstream by lipoproteins, which are categorized into low-density lipoprotein (LDL) and high-density lipoprotein (HDL). LDL, often referred to as 'bad' cholesterol, can build up on the walls of arteries and form plaques, leading to atherosclerosis, which increases the risk of heart disease and stroke. HDL, or 'good' cholesterol, helps remove LDL cholesterol from the arteries and transport it back to the liver for elimination. While cholesterol is necessary for healthy body function, maintaining a balance is important; high levels of LDL cholesterol or low levels of HDL cholesterol can lead to cardiovascular issues. Cholesterol levels are influenced by factors such as diet, exercise, genetics, and overall health, and managing these levels is crucial for heart health.",
  },
  {
    value: "sodium",
    label: "Sodium",
    unit: "mg",
    definition:
      "Sodium is an essential electrolyte that plays a vital role in maintaining fluid balance, nerve function, and muscle contractions in the body. It helps regulate blood pressure and is crucial for proper hydration, as it helps the body retain water and maintain the balance of fluids inside and outside of cells. Sodium is commonly found in table salt (sodium chloride) and is naturally present in many foods. While it is necessary for health, too much sodium can lead to high blood pressure and increase the risk of heart disease. It is important to balance sodium intake with other electrolytes like potassium to support overall cardiovascular and muscular health.",
  },
  {
    value: "sugar",
    label: "Sugar",
    unit: "g",
    definition:
      "A type of simple carbohydrate, sugar is a quick source of energy that is easily absorbed by the body. Sugars can be found naturally in foods such as fruits, vegetables, and dairy (in the form of fructose, glucose, and lactose) or added to foods and beverages during processing or preparation (such as table sugar or high-fructose corn syrup). While natural sugars provide essential nutrients along with energy, added sugars contribute extra calories without additional nutrients, which can lead to weight gain and increased risk of health issues like type 2 diabetes, heart disease, and tooth decay. Managing sugar intake, especially from added sources, is important for maintaining overall health and well-being.",
  },
  {
    value: "addedsugars",
    label: "Added Sugar",
    unit: "g",
    definition:
      "Added sugar refers to any sugars or sweeteners that are added to foods or beverages during processing or preparation, rather than those naturally occurring in fruits, vegetables, or dairy products. Common forms of added sugar include table sugar (sucrose), high-fructose corn syrup, honey, and syrups. These sugars are often used to enhance flavor, preserve food, or improve texture but provide no essential nutrients and are considered 'empty calories.' High intake of added sugars is associated with various health risks, including weight gain, obesity, type 2 diabetes, heart disease, and dental cavities. Managing and reducing the consumption of added sugars is crucial for maintaining overall health, and it is recommended to limit added sugar intake to less than 10% of total daily calories for most individuals.",
  },
  {
    value: "arginine",
    label: "Arginine",
    unit: "mg",
    definition:
      "Arginine, also known as L-Arginine, is an amino acid that plays a crucial role in the body's production of nitric oxide, a compound that helps relax blood vessels and improve blood flow. This makes Arginine popular in pre-workout supplements for enhancing muscle pumps, endurance, and overall exercise performance. It also supports protein synthesis, which is important for muscle growth and recovery. Beyond its athletic benefits, Arginine is used to support heart health, immune function, and wound healing. It's naturally found in foods like meat, dairy, and nuts, and is often supplemented to boost physical performance and cardiovascular health.",
  },
  {
    value: "betaalanine",
    label: "Beta Alanine",
    unit: "mg",
    definition:
      "Beta-Alanine is an amino acid that enhances athletic performance by increasing muscle carnosine levels. This helps buffer acid buildup during intense exercise, delaying muscle fatigue and improving endurance. Commonly found in pre-workout supplements, Beta-Alanine is known for its tingling sensation, a harmless side effect called paresthesia, which some users experience.",
  },
  {
    value: "bioperine",
    label: "BioPerine®",
    unit: "mg",
    definition:
      "BioPerine® is a patented extract derived from black pepper (Piper nigrum) that is standardized to contain 95% piperine, the active compound responsible for its health benefits. BioPerine® is widely recognized for its ability to enhance the bioavailability and absorption of various nutrients and supplements, making them more effective. By inhibiting certain enzymes and transporters in the digestive tract, BioPerine® allows for better absorption of vitamins, minerals, and other active compounds, such as curcumin, CoQ10, and resveratrol. It is commonly included in a wide range of dietary supplements to improve their efficacy and ensure that the body can fully utilize the ingredients.",
  },
  {
    value: "choline",
    label: "Choline",
    unit: "mg",
    definition:
      "Choline is an essential nutrient that supports brain health, liver function, and metabolism. It plays a key role in the production of acetylcholine, a neurotransmitter important for memory, mood, and muscle control. Choline is commonly found in eggs, meat, and certain vegetables, and is often included in supplements to enhance cognitive function and support overall health.",
  },
  {
    value: "creatine",
    label: "Creatine Monohydrate",
    unit: "mg",
    definition:
      "Creatine Monohydrate is the most common and well-researched form of creatine, widely used to increase muscle strength, enhance endurance, and support muscle growth. It works by replenishing ATP, the primary energy source for muscle contractions, allowing for improved performance during high-intensity exercise. Creatine Monohydrate is known for its effectiveness in building lean muscle mass and is a staple ingredient in many sports and bodybuilding supplements.",
  },
  {
    value: "creapure",
    label: "Creatine (Creapure®)",
    unit: "mg",
    definition:
      "Creapure® is a high-quality, branded form of creatine monohydrate, known for its purity, effectiveness, and safety. Manufactured in Germany, Creapure® is produced using a patented process to ensure that it is free from impurities, such as creatinine, dicyandiamide, and dihydrotriazine, which can sometimes be found in lower-quality creatine products. Creatine is a naturally occurring substance in the body, primarily stored in muscles, where it helps regenerate adenosine triphosphate (ATP), the primary energy source for short bursts of high-intensity activities. Supplementing with Creapure® can enhance muscle strength, power, and endurance, making it popular among athletes, bodybuilders, and fitness enthusiasts. Its high level of purity and safety has made Creapure® a preferred choice for those looking to improve performance and recovery while minimizing potential side effects associated with lower-quality creatine supplements.",
  },
  {
    value: "digezyme",
    label: "DigeZyme®",
    unit: "mg",
    definition:
      "DigeZyme® is a multi-enzyme complex that combines several digestive enzymes, including amylase, protease, lipase, cellulase, and lactase, to support the breakdown and digestion of carbohydrates, proteins, fats, cellulose, and lactose, respectively. This proprietary blend is designed to enhance the digestive process, improve nutrient absorption, and reduce gastrointestinal discomfort, such as bloating and gas. DigeZyme® is often used in dietary supplements aimed at promoting digestive health and overall well-being, particularly for individuals with digestive sensitivities or those who consume high-protein or high-fat diets. By aiding in the efficient digestion of various nutrients, DigeZyme® helps ensure that the body receives the full benefits of the food consumed, making it a popular ingredient in digestive health supplements and sports nutrition products.",
  },
  {
    value: "hmb",
    label: "HMB",
    unit: "mg",
    definition:
      "HMB (Beta-Hydroxy Beta-Methylbutyrate) is a metabolite of the essential amino acid leucine, known for its role in muscle health and recovery. HMB helps to reduce muscle protein breakdown and supports muscle preservation, making it particularly beneficial for individuals engaged in intense physical training, those recovering from injury, or older adults at risk of muscle loss. It has been shown to improve muscle strength, enhance endurance, and aid in faster recovery by minimizing muscle damage and soreness following exercise. HMB is commonly used in sports nutrition supplements and is available in various forms, such as capsules, powders, and tablets. Regular supplementation with HMB can help maintain muscle mass, boost performance, and promote overall muscular health, especially in combination with resistance training or high-intensity workouts.",
  },
  {
    value: "glutamine",
    label: "Glutamine",
    unit: "mg",
    definition:
      "Glutamine is a conditionally essential amino acid that plays a crucial role in muscle recovery, immune function, and gut health. During intense exercise, glutamine levels can be depleted, leading to muscle breakdown and a weakened immune system. Supplementing with glutamine helps replenish these levels, promoting muscle repair, reducing recovery time, and supporting the body's immune response. It is commonly used in sports supplements to aid in recovery, enhance endurance, and support overall health, particularly for athletes and those engaged in intense physical activity.",
  },
  {
    value: "lactospore",
    label: "LactoSpore®",
    unit: "spores",
    definition:
      "LactoSpore® is a probiotic supplement containing Bacillus coagulans, a spore-forming bacterium known for its ability to survive harsh conditions in the digestive tract, such as stomach acid and bile. This probiotic strain supports digestive health by promoting a balanced gut microbiota, enhancing the breakdown of food, and aiding nutrient absorption. LactoSpore® is also recognized for its potential to improve gastrointestinal function, alleviate symptoms of digestive discomfort, and support immune health. Unlike many other probiotics, LactoSpore® is stable at room temperature and has a long shelf life, making it convenient for inclusion in various supplements and functional foods. Its resilience and effectiveness make LactoSpore® a popular choice for individuals seeking to enhance their digestive wellness and overall gut health.",
  },
  {
    value: "leucine",
    label: "Leucine",
    unit: "mg",
    definition:
      "Leucine is one of the three branched-chain amino acids (BCAAs), alongside isoleucine and valine, and plays a critical role in muscle protein synthesis. It is the most potent of the BCAAs in stimulating muscle growth and repair, making it essential for athletes and individuals engaged in intense physical activity. Leucine helps to promote muscle recovery, reduce muscle breakdown, and enhance overall muscle mass. It is commonly included in BCAA supplements, protein powders, and muscle-building formulas to support muscle growth, improve workout performance, and aid in recovery after exercise.",
  },
  {
    value: "inositol",
    label: "Inositol",
    unit: "mg",
    definition:
      "Inositol is a naturally occurring carbohydrate that plays a crucial role in cell signaling and the regulation of various hormones, including insulin. It is often used in supplements to support mental health, particularly in reducing anxiety and improving mood. Inositol is also known for its benefits in promoting healthy ovarian function and supporting fertility, especially in women with polycystic ovary syndrome (PCOS). Additionally, it is used to enhance insulin sensitivity and support metabolic health. Inositol is commonly included in supplements aimed at improving mental well-being, hormonal balance, and metabolic function.",
  },
  {
    value: "isoleucine",
    label: "Isoleucine",
    unit: "mg",
    definition:
      "Isoleucine is one of the three branched-chain amino acids (BCAAs), alongside leucine and valine, that play a crucial role in muscle metabolism. It supports muscle repair, growth, and recovery after exercise. Isoleucine also helps regulate blood sugar levels by assisting in glucose uptake into cells and promotes energy production during physical activity. It is commonly included in BCAA supplements and protein powders to enhance athletic performance, reduce muscle soreness, and support muscle preservation during intense training or dieting.",
  },
  {
    value: "paba",
    label: "PABA",
    unit: "mg",
    definition:
      "PABA (Para-Aminobenzoic Acid) is an organic compound that is part of the B-vitamin complex, often associated with folic acid. PABA is known for its role in supporting skin health and is commonly used in sunscreens and skin care products for its ability to absorb ultraviolet (UV) light, offering protection against UV radiation. Additionally, PABA is believed to have antioxidant properties that help protect cells from oxidative stress. It is sometimes used in supplements to promote hair and skin health, as it is thought to aid in the pigmentation process and prevent premature graying of hair. While the body can synthesize PABA from gut bacteria, it is also found in small amounts in foods like whole grains, liver, eggs, and yeast. PABA supplements should be used cautiously, as high doses can cause side effects such as skin rashes or gastrointestinal discomfort in some individuals.",
  },
  {
    value: "taurine",
    label: "Taurine",
    unit: "mg",
    definition:
      "Taurine is a naturally occurring amino acid that plays a crucial role in various bodily functions, including supporting cardiovascular health, maintaining proper hydration and electrolyte balance, and promoting the development and function of the central nervous system. It also has antioxidant properties, helping to protect cells from oxidative stress. Taurine is commonly found in energy drinks, pre-workout supplements, and products aimed at improving athletic performance, as it can enhance endurance, reduce muscle fatigue, and support recovery. Additionally, taurine supports heart health by improving blood flow and reducing the risk of cardiovascular diseases.",
  },
  {
    value: "tyrosine",
    label: "Tyrosine",
    unit: "mg",
    definition:
      "Tyrosine is an amino acid that supports the production of neurotransmitters, such as dopamine and norepinephrine. It is commonly used in supplements to enhance cognitive function, improve mood, and manage stress. Tyrosine helps with mental alertness and focus, particularly during periods of fatigue or stressful situations.",
  },
  {
    value: "valine",
    label: "Valine",
    unit: "mg",
    definition:
      "Valine is an essential amino acid that supports muscle growth, repair, and energy production. It helps maintain nitrogen balance and is crucial for muscle metabolism. Valine is often included in supplements to enhance exercise performance and recovery.",
  },
  {
    value: "velositol",
    label: "Velositol®",
    unit: "mg",
    definition:
      "Velositol® is a patented complex of amylopectin (a form of carbohydrate) and chromium designed to enhance the effects of protein supplementation. It is known for its ability to amplify muscle protein synthesis (MPS) when combined with protein, leading to greater muscle growth, strength, and recovery after exercise. Velositol® works by increasing the body's insulin response, which helps drive amino acids into muscle cells more effectively, thereby boosting the anabolic effects of protein. This makes it a popular ingredient in sports nutrition products aimed at athletes, bodybuilders, and fitness enthusiasts looking to maximize the benefits of their protein intake. Studies suggest that Velositol®, when taken with protein, can enhance the body's ability to build muscle and recover faster, making it an effective supplement for those engaged in resistance training or other forms of intense physical activity.",
  },
];

export const electrolyteIngredients = [
  {
    value: "calories",
    label: "Calories",
    unit: "",
    hide: true,
    definition:
      "A unit of energy measurement used to quantify the amount of energy provided by food and beverages. Calories are essential for the body's basic functions and physical activities, supplying the energy needed to sustain life. In nutrition, calories refer to kilocalories (kcal), which represent the energy required to raise the temperature of one kilogram of water by one degree Celsius. Consuming calories from food allows the body to perform vital functions, such as breathing, circulation, and movement. The balance between caloric intake and expenditure plays a critical role in managing body weight and overall health.",
  },
  {
    value: "carbs",
    label: "Carbohydrates",
    unit: "g",
    hide: true,
    definition:
      "A primary source of energy for the body, carbohydrates (carbs) are macronutrients found in foods such as grains, fruits, vegetables, and legumes. They are broken down into glucose during digestion, which is then used by cells for fuel. Carbs are essential for proper brain function, physical activity, and overall metabolic processes. They can be categorized into simple carbohydrates (sugars) and complex carbohydrates (starches and fibers), each affecting the body differently in terms of energy release and digestion. Managing carbohydrate intake is important for maintaining stable blood sugar levels and supporting overall health.",
  },
  {
    value: "fiber",
    label: "Dietary Fiber",
    unit: "g",
    definition:
      "A type of carbohydrate that the body cannot digest, fiber is found in plant-based foods such as fruits, vegetables, whole grains, legumes, nuts, and seeds. Unlike other carbohydrates, fiber passes through the digestive system relatively intact, aiding in digestive health by adding bulk to stools and promoting regular bowel movements. Fiber is classified into two types: soluble, which dissolves in water and can help lower blood cholesterol and glucose levels, and insoluble, which does not dissolve in water and helps move food through the digestive tract. Adequate fiber intake supports digestive health, helps control blood sugar levels, aids in weight management, and reduces the risk of certain diseases, such as heart disease and type 2 diabetes.",
  },
  {
    value: "sugar",
    label: "Sugar",
    unit: "g",
    definition:
      "A type of simple carbohydrate, sugar is a quick source of energy that is easily absorbed by the body. Sugars can be found naturally in foods such as fruits, vegetables, and dairy (in the form of fructose, glucose, and lactose) or added to foods and beverages during processing or preparation (such as table sugar or high-fructose corn syrup). While natural sugars provide essential nutrients along with energy, added sugars contribute extra calories without additional nutrients, which can lead to weight gain and increased risk of health issues like type 2 diabetes, heart disease, and tooth decay. Managing sugar intake, especially from added sources, is important for maintaining overall health and well-being.",
  },
  {
    value: "addedsugar",
    label: "Added Sugar",
    unit: "g",
    definition:
      "Added sugar refers to any sugars or sweeteners that are added to foods or beverages during processing or preparation, rather than those naturally occurring in fruits, vegetables, or dairy products. Common forms of added sugar include table sugar (sucrose), high-fructose corn syrup, honey, and syrups. These sugars are often used to enhance flavor, preserve food, or improve texture but provide no essential nutrients and are considered 'empty calories.' High intake of added sugars is associated with various health risks, including weight gain, obesity, type 2 diabetes, heart disease, and dental cavities. Managing and reducing the consumption of added sugars is crucial for maintaining overall health, and it is recommended to limit added sugar intake to less than 10% of total daily calories for most individuals.",
  },
  {
    value: "alfalfa",
    label: "Alfalfa",
    unit: "mg",
    definition:
      "A nutrient-rich plant, alfalfa is commonly used as a forage crop for livestock and as a dietary supplement for humans due to its high content of vitamins, minerals, and antioxidants. Alfalfa is known for its potential health benefits, including supporting digestion, reducing cholesterol levels, and providing a natural source of vitamins A, C, E, and K, as well as minerals like calcium, potassium, and iron. Often consumed in the form of sprouts, tea, or supplements, alfalfa is valued for its anti-inflammatory and diuretic properties, which may help promote overall health and wellness. However, it should be consumed in moderation, especially by individuals with certain health conditions, due to its potential effects on hormone levels and immune function.",
  },
  {
    value: "atlantickelp",
    label: "Atlantic Kelp",
    unit: "mg",
    definition:
      "A type of brown seaweed, Atlantic kelp is rich in essential nutrients, including iodine, potassium, calcium, magnesium, and a range of vitamins such as A, B, C, D, and E. It is commonly found in cold ocean waters and is known for its potential health benefits, particularly for supporting thyroid function due to its high iodine content, which is crucial for hormone production and metabolism regulation. Atlantic kelp also contains antioxidants, such as carotenoids and flavonoids, that help protect cells from oxidative damage. It is often used in supplements and dietary products aimed at boosting energy levels, supporting immune health, and promoting overall well-being. However, excessive intake of kelp can lead to iodine overload, so it should be consumed in moderation.",
  },
  {
    value: "bioperine",
    label: "BioPerine®",
    unit: "mg",
    definition:
      "BioPerine® is a patented extract derived from black pepper (Piper nigrum) that is standardized to contain 95% piperine, the active compound responsible for its health benefits. BioPerine® is widely recognized for its ability to enhance the bioavailability and absorption of various nutrients and supplements, making them more effective. By inhibiting certain enzymes and transporters in the digestive tract, BioPerine® allows for better absorption of vitamins, minerals, and other active compounds, such as curcumin, CoQ10, and resveratrol. It is commonly included in a wide range of dietary supplements to improve their efficacy and ensure that the body can fully utilize the ingredients.",
  },
  {
    value: "boron",
    label: "Boron",
    unit: "mcg",
    definition:
      "Boron is a trace mineral that plays a crucial role in supporting bone health, hormone regulation, and cognitive function. It helps the body efficiently use calcium, magnesium, and vitamin D, which are vital for maintaining strong bones and preventing osteoporosis. Boron is also known to influence the metabolism of key hormones such as estrogen and testosterone, potentially enhancing muscle growth, recovery, and overall hormonal balance. Additionally, boron may support brain function, improving memory and cognitive performance. It is often included in dietary supplements aimed at promoting bone health, hormonal balance, and mental clarity.",
  },
  {
    value: "biotin",
    label: "Biotin",
    unit: "mcg",
    definition:
      "A water-soluble B-vitamin, biotin (also known as vitamin B7 or vitamin H) plays a key role in converting food into energy by aiding in the metabolism of carbohydrates, fats, and proteins. It is essential for maintaining healthy skin, hair, and nails, and is often included in beauty supplements for these benefits. Biotin also supports the function of the nervous system and helps maintain proper liver function. While biotin is naturally found in foods such as eggs, nuts, seeds, salmon, and leafy green vegetables, deficiencies are rare. However, supplementation may be recommended for individuals with certain health conditions or for those seeking to support hair and nail growth. Excessive intake of biotin supplements can interfere with certain lab tests, so it should be used as directed.",
  },
  {
    value: "calcium",
    label: "Calcium",
    unit: "mg",
    definition:
      "Calcium is an essential mineral that plays a vital role in maintaining strong bones and teeth, as well as supporting overall health. It is the most abundant mineral in the human body, with the majority stored in bones and teeth, providing them with structure and strength. Calcium is also crucial for muscle function, nerve transmission, blood clotting, and the regulation of heart rhythms. Adequate calcium intake is important throughout life, particularly during childhood, adolescence, and older age to prevent bone-related conditions such as osteoporosis. It is commonly found in dairy products, leafy greens, and fortified foods, and is often included in supplements to ensure sufficient intake for bone health and overall wellness.",
  },
  {
    value: "chloride",
    label: "Chloride",
    unit: "mg",
    definition:
      "Chloride is an essential electrolyte that helps maintain fluid balance, supports proper nerve function, and aids in digestion by forming hydrochloric acid in the stomach. It is commonly found in salt (sodium chloride) and is crucial for maintaining overall hydration and pH balance in the body. Chloride is often included in electrolyte supplements to support hydration and overall electrolyte balance.",
  },
  {
    value: "chromium",
    label: "Chromium",
    unit: "mcg",
    definition:
      "Chromium is a trace mineral that plays a crucial role in regulating blood sugar levels by enhancing the action of insulin. It supports metabolism and may help with weight management by reducing cravings and improving glucose utilization. Chromium is commonly found in supplements aimed at supporting healthy blood sugar levels and overall metabolic health.",
  },
  {
    value: "citricacid",
    label: "Citric Acid",
    unit: "mg",
    definition:
      "Citric Acid is a natural compound found in citrus fruits, commonly used as a preservative, flavor enhancer, and pH adjuster in food and supplements. It plays a role in the Krebs cycle, a key metabolic pathway that generates energy in cells. In supplements, Citric Acid is often included to enhance flavor, improve absorption of minerals, and support energy production.",
  },
  {
    value: "cocomineral",
    label: "CocoMineral®",
    unit: "mg",
    definition:
      "A branded ingredient derived from coconut water, CocoMineral® is a natural source of essential electrolytes, particularly potassium and sodium, which are vital for maintaining proper hydration, muscle function, and electrolyte balance in the body. It is commonly used in sports nutrition supplements, energy drinks, and rehydration formulas to help replenish electrolytes lost during intense physical activity or sweating. CocoMineral® is valued for its ability to support hydration and endurance, making it a popular choice for athletes and active individuals. Additionally, it offers the benefits of coconut water without the sugars and calories typically found in fresh coconut water, providing a convenient and low-calorie option for maintaining electrolyte balance and overall hydration.",
  },
  {
    value: "coconutfruitpowder",
    label: "Coconut Fruit Water Powder",
    unit: "mg",
    definition:
      "Coconut fruit powder is included in pre-workout supplements primarily for its natural electrolyte content, which helps maintain proper hydration and muscle function during intense exercise. Rich in potassium, magnesium, and sodium, coconut fruit powder helps replenish the electrolytes lost through sweat, reducing the risk of dehydration and muscle cramps. Its natural source of these essential minerals supports optimal fluid balance in the body, aiding in sustained energy levels and enhanced athletic performance. Additionally, the inclusion of coconut fruit powder provides a more natural alternative to synthetic electrolytes, making it a popular choice in pre-workout formulations aimed at holistic and balanced nutrition.",
  },
  {
    value: "coconutwaterpowder",
    label: "Coconut Water Powder",
    unit: "mg",
    definition:
      "A dehydrated form of coconut water, coconut water powder is a convenient and concentrated source of the natural electrolytes found in fresh coconut water, such as potassium, sodium, magnesium, and calcium. It is commonly used in sports drinks, hydration supplements, and smoothie mixes to help support hydration, replenish electrolytes, and promote overall fluid balance, especially during or after physical activity. Coconut water powder retains the nutrients and natural flavors of coconut water while being low in calories and sugars, making it a popular choice for those looking to stay hydrated without added sugars or artificial ingredients. It can be easily reconstituted by mixing with water and provides a portable, shelf-stable alternative to fresh coconut water.",
  },
  {
    value: "coenzyme",
    label: "Coenzyme",
    unit: "mg",
    definition:
      "A coenzyme is a non-protein compound that binds to an enzyme to help it perform its catalytic activity. Coenzymes are essential for the function of many enzymes in biochemical reactions within the body, particularly those involved in metabolism, energy production, and the synthesis of biomolecules. They often act as carriers, transporting chemical groups between different molecules to facilitate various metabolic processes. Common examples of coenzymes include NAD+ (nicotinamide adenine dinucleotide), FAD (flavin adenine dinucleotide), and CoA (coenzyme A), all of which play critical roles in cellular respiration and energy production. Coenzymes are often derived from vitamins, such as B vitamins, making adequate vitamin intake crucial for maintaining proper enzyme function and overall metabolic health.",
  },
  {
    value: "concentrateac",
    label: "ConcenTrace® AC",
    unit: "mg",
    definition:
      "ConcenTrace® AC is a mineral supplement derived from concentrated seawater, specifically designed to provide a wide spectrum of trace minerals and electrolytes. It is known for its high mineral content, including magnesium, chloride, sodium, potassium, and numerous other trace elements essential for maintaining proper bodily function. ConcenTrace® AC is often used to support hydration, energy production, and overall mineral balance, making it popular among individuals looking to replenish minerals lost through sweat or dietary deficiencies. The minerals in ConcenTrace® AC are in ionic form, which is believed to enhance absorption and bioavailability in the body. This supplement is commonly added to water, smoothies, or food to boost daily mineral intake and promote overall health and wellness.",
  },
  {
    value: "copper",
    label: "Copper",
    unit: "mg",
    definition:
      "Copper is an essential trace mineral that plays a vital role in numerous physiological processes, including the formation of red blood cells, maintenance of healthy bones, and support of the immune system. It is also crucial for the proper functioning of enzymes involved in energy production and antioxidant defense. Copper helps in the absorption and utilization of iron, supports cardiovascular health, and contributes to the formation of collagen, which is important for skin, bones, and connective tissues. It is commonly found in foods like shellfish, nuts, seeds, and whole grains, and is often included in multivitamins and mineral supplements to ensure adequate intake.",
  },
  {
    value: "cranberryextract",
    label: "Cranberry Extract",
    unit: "mg",
    definition:
      "Cranberry extract is a concentrated form of cranberries, known for its high content of antioxidants, particularly proanthocyanidins (PACs), which are compounds believed to help prevent bacteria from adhering to the walls of the urinary tract. This makes cranberry extract a popular natural remedy for supporting urinary tract health and reducing the risk of urinary tract infections (UTIs). In addition to its urinary benefits, cranberry extract also contains vitamins C, E, and K, as well as other phytonutrients that support immune function, cardiovascular health, and overall well-being. It is available in various forms, including capsules, tablets, powders, and juices, and is often used in supplements aimed at promoting urinary and general health. While generally safe, cranberry extract should be consumed in moderation, especially by individuals taking certain medications or those with specific health conditions, as it may interact with blood thinners and affect kidney stone formation in susceptible individuals.",
  },
  { value: "de", label: "DE", unit: "mg" }, // weird product id 134
  {
    value: "enovita",
    label: "Enovita®",
    unit: "mg",
    definition:
      "A standardized extract from grape seeds, Enovita® is rich in polyphenols, particularly oligomeric proanthocyanidins (OPCs), which are potent antioxidants known for their ability to support cardiovascular health and protect against oxidative stress. Enovita® is commonly used in supplements to promote healthy blood pressure, enhance circulation, and improve vascular function. Its powerful antioxidant properties help neutralize free radicals, reducing cellular damage and potentially lowering the risk of chronic diseases. Enovita® is also valued for its anti-inflammatory effects, making it a popular choice in products aimed at supporting overall wellness and protecting the body from the effects of aging.",
  },
  {
    value: "folicacid",
    label: "Folic Acid",
    unit: "mcg",
    definition:
      "Folic Acid is the synthetic form of Folate (Vitamin B9), commonly used in supplements and fortified foods. It plays a vital role in DNA synthesis, cell division, and the production of red blood cells. Folic Acid is especially important during pregnancy, as it helps prevent neural tube defects in the developing fetus. It also supports heart health by helping to regulate homocysteine levels in the blood. Folic Acid is widely used in multivitamins, prenatal supplements, and fortified foods to ensure sufficient intake of this essential nutrient.",
  },
  {
    value: "gingerroot",
    label: "Ginger Root",
    unit: "mg",
    definition:
      "A popular herbal remedy, ginger root is renowned for its medicinal properties and culinary uses, derived from the rhizome of the plant *Zingiber officinale*. Ginger is rich in bioactive compounds, such as gingerols and shogaols, which are known for their anti-inflammatory and antioxidant effects. It is commonly used to alleviate digestive issues, reduce nausea (including morning sickness and motion sickness), and soothe gastrointestinal discomfort. Additionally, ginger has been shown to help reduce muscle pain and soreness, support immune health, and may even have potential benefits for cardiovascular health by improving blood circulation. Available in various forms—fresh, dried, powdered, or as an extract—ginger is a versatile ingredient widely used in supplements, teas, and culinary dishes to enhance flavor and promote health.",
  },
  {
    value: "iron",
    label: "Iron",
    unit: "mg",
    definition:
      "Iron is an essential mineral that plays a key role in the production of hemoglobin, a protein in red blood cells responsible for transporting oxygen throughout the body. It is crucial for maintaining energy levels, supporting immune function, and promoting healthy cognitive development. Iron is commonly found in foods like red meat, beans, and fortified cereals, and is often included in supplements to prevent or treat iron deficiency anemia. Adequate iron intake is particularly important for women of childbearing age, pregnant women, and individuals with increased iron needs or limited dietary intake.",
  },
  {
    value: "kelp",
    label: "Kelp",
    unit: "mg",
    definition:
      "Kelp is a type of large, brown seaweed that is rich in essential nutrients, particularly iodine, which is crucial for maintaining healthy thyroid function. In addition to iodine, kelp contains vitamins, minerals, and antioxidants that support overall health. Kelp is commonly used in supplements to promote thyroid health, support metabolism, and provide a natural source of trace minerals. Its high iodine content makes it particularly beneficial for individuals with low iodine levels or those looking to support healthy thyroid hormone production. Kelp is also valued for its potential detoxifying properties and its ability to support healthy skin and hair.",
  },
  {
    value: "magnesium",
    label: "Magnesium",
    unit: "mg",
    definition:
      "Magnesium is an essential mineral that plays a vital role in numerous bodily functions, including muscle and nerve function, energy production, and bone health. It is involved in over 300 enzymatic reactions, helping to regulate blood pressure, support immune function, and maintain normal muscle contractions, including the heart. Magnesium is also important for protein synthesis and glucose metabolism. It is commonly found in foods like leafy greens, nuts, seeds, and whole grains, and is often included in supplements to support muscle relaxation, reduce cramps, improve sleep, and promote overall cardiovascular health.",
  },
  {
    value: "magnesiumcitrate",
    label: "Magnesium Citrate",
    unit: "mg",
    definition:
      "Magnesium Citrate is a highly absorbable form of magnesium, commonly used to support digestive health and relieve constipation due to its mild laxative effect. It is also used to support muscle and nerve function, energy production, and overall cardiovascular health. Magnesium Citrate is favored for its high bioavailability, meaning it is easily absorbed by the body. It is often included in supplements to help maintain normal muscle contractions, support bone health, and promote relaxation, making it a popular choice for individuals looking to increase their magnesium intake for both digestive and general health benefits.",
  },
  {
    value: "manganese",
    label: "Manganese",
    unit: "mg",
    definition:
      "An essential trace mineral, manganese plays a crucial role in various bodily functions, including metabolism, bone development, and the antioxidant defense system. It is a key component of several enzymes involved in the breakdown of carbohydrates, proteins, and fats, aiding in energy production and nutrient utilization. Manganese also supports the formation of connective tissue, bone health, and the proper functioning of the nervous system. Additionally, it contributes to the body’s natural antioxidant defenses by activating superoxide dismutase (SOD), an enzyme that helps protect cells from oxidative stress. Found in foods like whole grains, nuts, seeds, leafy vegetables, and tea, manganese is required in small amounts, but adequate intake is essential for maintaining overall health and metabolic balance.",
  },
  {
    value: "mchaintrigyl",
    label: "MCT (Medium Chain Triglycerides)",
    unit: "mg",
    definition:
      "Medium Chain Triglycerides (MCTs) are a type of fat composed of medium-length fatty acid chains that are more rapidly absorbed and metabolized by the body compared to long-chain fatty acids. MCTs are primarily derived from coconut oil and palm kernel oil and are popular in health supplements and sports nutrition due to their potential to provide a quick source of energy. Unlike other fats, MCTs are transported directly to the liver, where they are quickly converted into ketones or used for immediate energy, making them beneficial for those following ketogenic or low-carb diets. MCTs are also associated with various health benefits, including supporting weight management, enhancing cognitive function, and promoting gut health. They are available in oil, powder, and supplement forms and are commonly used in smoothies, coffee, and other beverages to boost energy and satiety.",
  },
  {
    value: "molybdenum",
    label: "Molybdenum",
    unit: "mcg",
    definition:
      "Molybdenum is an essential trace mineral that plays a vital role in various enzymatic reactions within the body. It acts as a cofactor for several important enzymes, including those involved in the metabolism of sulfur-containing amino acids, the breakdown of certain toxins, and the conversion of purines into uric acid. Molybdenum is necessary for the proper functioning of the enzyme sulfite oxidase, which helps detoxify sulfites, compounds that can be found in some foods and beverages. This mineral is found in small amounts in foods such as legumes, grains, leafy vegetables, and nuts. While the body requires only a small amount of molybdenum, it is crucial for maintaining metabolic balance and supporting the overall detoxification processes. Deficiency in molybdenum is rare, but adequate intake is important for enzyme function and overall health.",
  },
  {
    value: "niacin",
    label: "Niacin",
    unit: "mg",
    definition:
      "Niacin, also known as Vitamin B3, is an essential nutrient that plays a key role in energy production, DNA repair, and the maintenance of healthy skin, nerves, and digestive systems. It helps convert food into energy by assisting enzymes involved in metabolism. Niacin is also known for its ability to improve cholesterol levels by raising HDL (good) cholesterol and lowering LDL (bad) cholesterol and triglycerides. In supplements, niacin is often used to support cardiovascular health, boost energy levels, and promote overall wellness. It can cause a temporary flushing effect, which is a warm, tingling sensation on the skin, a common side effect known as the 'niacin flush'.",
  },
  {
    value: "pantothenic",
    label: "Pantothenic Acid",
    unit: "mg",
    definition:
      "Pantothenic Acid, also known as Vitamin B5, is an essential water-soluble vitamin that plays a key role in energy metabolism by helping to convert carbohydrates, fats, and proteins into usable energy. It is also crucial for the synthesis of coenzyme A, which is involved in the production of hormones, neurotransmitters, and red blood cells. Pantothenic Acid supports adrenal function, helps the body respond to stress, and is important for maintaining healthy skin, hair, and eyes. It is commonly found in foods like meat, eggs, legumes, and whole grains, and is often included in multivitamins and B-complex supplements to support energy levels, stress management, and overall health.",
  },
  {
    value: "phosphorus",
    label: "Phosphorus",
    unit: "mg",
    definition:
      "Phosphorus is an essential mineral that plays a critical role in many bodily functions, including the formation of bones and teeth, energy production, and the regulation of acid-base balance. It is a major component of ATP (adenosine triphosphate), the molecule that stores and transfers energy within cells, making it vital for energy metabolism. Phosphorus is also involved in the synthesis of DNA and RNA, and it helps maintain the structure and function of cell membranes. It is commonly found in foods like dairy products, meat, fish, and whole grains, and is often included in supplements to support bone health, energy production, and overall metabolic function. Phosphorus works closely with calcium to maintain healthy bones and teeth, and an appropriate balance of these two minerals is essential for optimal health.",
  },
  {
    value: "potassium",
    label: "Potassium",
    unit: "mg",
    definition:
      "Potassium is an essential mineral and electrolyte that plays a crucial role in maintaining proper cell function, particularly in muscle and nerve cells. It helps regulate fluid balance, muscle contractions, and nerve signals. Potassium is vital for maintaining normal blood pressure levels, reducing the risk of stroke, and supporting cardiovascular health. It also aids in the proper functioning of the kidneys and helps prevent the formation of kidney stones. Potassium is commonly found in foods like bananas, oranges, potatoes, and leafy greens, and is often included in supplements to support heart health, muscle function, and overall electrolyte balance, especially for individuals who may be at risk of deficiency due to diet or medical conditions.",
  },
  {
    value: "rednite",
    label: "RedNite®",
    unit: "mg",
    definition:
      "RedNite® is a branded, concentrated beetroot powder known for its high nitrate content, which supports improved blood flow, endurance, and exercise performance. It is commonly used in pre-workout supplements to enhance muscle pumps, increase stamina, and boost overall cardiovascular health. RedNite® offers a natural and effective way to support athletic performance, making it popular among athletes and fitness enthusiasts.",
  },
  {
    value: "riboflavin",
    label: "Riboflavin",
    unit: "mg",
    definition:
      "Riboflavin, also known as Vitamin B2, is an essential nutrient that plays a key role in energy production, cellular function, and metabolism. It helps convert carbohydrates, fats, and proteins into energy and is crucial for maintaining healthy skin, eyes, and nervous system function. Riboflavin also acts as an antioxidant, protecting cells from oxidative stress. It is commonly found in foods like eggs, milk, and green vegetables, and is often included in multivitamins and B-complex supplements to support overall health and energy levels.",
  },
  {
    value: "ribose",
    label: "D-Ribose",
    unit: "g",
    definition:
      "D-Ribose is a naturally occurring sugar that plays a crucial role in energy production within the body's cells. It is a fundamental component of adenosine triphosphate (ATP), the primary energy carrier in all living organisms. By supporting the synthesis of ATP, D-ribose helps improve cellular energy levels, particularly in the heart and muscles, making it popular in supplements aimed at enhancing exercise performance, reducing fatigue, and aiding in recovery after intense physical activity. D-Ribose is also valued for its potential benefits in supporting cardiovascular health, particularly for individuals with heart conditions or chronic fatigue. It can be found in small amounts in foods like meats and dairy products but is often taken as a supplement in powder or capsule form for its energy-boosting properties.",
  },
  {
    value: "selenium",
    label: "Selenium",
    unit: "mcg",
    definition:
      "Selenium is an essential trace mineral that plays a critical role in various bodily functions, particularly as a component of antioxidant enzymes like glutathione peroxidase, which help protect cells from oxidative damage. Selenium is also important for thyroid health, as it supports the production of thyroid hormones, and it contributes to immune system function by helping to fight off infections. Adequate selenium intake is linked to a reduced risk of certain chronic diseases, including heart disease and some types of cancer. Selenium is commonly found in foods like Brazil nuts, seafood, and whole grains, and is often included in multivitamins and antioxidant supplements to support overall health, immune function, and thyroid regulation.",
  },
  {
    value: "senactiv",
    label: "SenActiv®",
    unit: "mg",
    definition:
      "SenActiv® is a patented blend of natural ingredients, including Panax notoginseng and Rosa roxburghii, designed to enhance athletic performance and promote muscle recovery. It works by increasing the production of energy at the cellular level, improving muscle endurance, and reducing inflammation. SenActiv® also supports the removal of old, damaged muscle cells, helping to promote the regeneration of healthy muscle tissue. This makes it a popular ingredient in pre-workout and recovery supplements, aimed at improving workout performance, accelerating recovery, and reducing muscle fatigue.",
  },
  {
    value: "sodium",
    label: "Sodium",
    unit: "mg",
    definition:
      "Sodium is an essential electrolyte that plays a vital role in maintaining fluid balance, nerve function, and muscle contractions in the body. It helps regulate blood pressure and is crucial for proper hydration, as it helps the body retain water and maintain the balance of fluids inside and outside of cells. Sodium is commonly found in table salt (sodium chloride) and is naturally present in many foods. While it is necessary for health, too much sodium can lead to high blood pressure and increase the risk of heart disease. It is important to balance sodium intake with other electrolytes like potassium to support overall cardiovascular and muscular health.",
  },
  {
    value: "spectra",
    label: "Spectra™ Total ORAC Blend",
    unit: "mg",
    definition:
      "Spectra™ Total ORAC Blend is a proprietary blend of natural plant-based ingredients, including fruits, vegetables, and herbs, designed to provide a high antioxidant capacity in dietary supplements, particularly pre-workout formulas. It aims to enhance the body's antioxidant defenses by neutralizing free radicals, which are produced during intense physical activity and can cause oxidative stress and muscle damage. The blend includes a wide range of ingredients such as green tea, broccoli, turmeric, elderberry, and various berries, all selected for their potent antioxidant properties. By supporting cellular health, reducing oxidative stress, and potentially improving blood flow, Spectra™ Total ORAC Blend contributes to enhanced workout performance, faster recovery, and overall wellness.",
  },
  {
    value: "steviareba",
    label: "Stevia Reb A",
    unit: "mg",
    definition:
      "Stevia Reb A, short for Rebaudioside A, is a high-purity extract derived from the leaves of the Stevia rebaudiana plant. Known for its intense sweetness—about 200 to 400 times sweeter than table sugar—Reb A is a popular natural sweetener used in a variety of foods and beverages as a sugar substitute. It is calorie-free, making it a preferred choice for individuals looking to reduce their sugar intake or manage their weight. In addition to its sweetness, Reb A does not raise blood sugar levels, making it suitable for people with diabetes or those on low-carb diets. Stevia Reb A is often found in products like soft drinks, desserts, and snacks, and it is valued for its ability to provide a sweet taste without the calories or glycemic impact of sugar. While generally recognized as safe, some people may find that stevia extracts have a distinct aftertaste.",
  },
  {
    value: "sulfate",
    label: "Sulfate",
    unit: "mg",
    definition:
      "Sulfate is a salt or ester of sulfuric acid, containing the sulfate ion (SO₄²⁻). It is a common chemical compound found naturally in various minerals, soil, water, and even in the human body. Sulfates are often used in a wide range of products, including detergents, shampoos, cosmetics, and cleaning agents, due to their effectiveness as surfactants that help remove dirt and oil. In the body, sulfates play a role in various metabolic processes, including detoxification and hormone synthesis. While sulfates are generally considered safe for most people, some individuals with sensitive skin may experience irritation when exposed to high concentrations in personal care products. In water, excessive levels of sulfates can lead to a laxative effect or an unpleasant taste, so they are typically monitored in drinking water supplies to ensure safety and quality.",
  },
  {
    value: "taurine",
    label: "Taurine",
    unit: "mg",
    definition:
      "Taurine is a naturally occurring amino acid that plays a crucial role in various bodily functions, including supporting cardiovascular health, maintaining proper hydration and electrolyte balance, and promoting the development and function of the central nervous system. It also has antioxidant properties, helping to protect cells from oxidative stress. Taurine is commonly found in energy drinks, pre-workout supplements, and products aimed at improving athletic performance, as it can enhance endurance, reduce muscle fatigue, and support recovery. Additionally, taurine supports heart health by improving blood flow and reducing the risk of cardiovascular diseases.",
  },
  {
    value: "thiamin",
    label: "Thiamin",
    unit: "mg",
    definition:
      "Thiamin, also known as Vitamin B1, is an essential water-soluble vitamin that plays a crucial role in energy metabolism. It helps convert carbohydrates into energy and is vital for the proper function of the nervous system, muscles, and heart. Thiamin is also important for the synthesis of certain neurotransmitters and the conduction of nerve signals. It is commonly found in foods such as whole grains, legumes, nuts, and meat, and is often included in multivitamins and B-complex supplements to support overall energy production, nerve function, and cardiovascular health. Thiamin deficiency can lead to conditions such as beriberi and Wernicke-Korsakoff syndrome, particularly in individuals with high alcohol consumption or poor diets.",
  },
  {
    value: "theanine",
    label: "Theanine",
    unit: "mg",
    definition:
      "Theanine, specifically L-theanine, is an amino acid primarily found in tea leaves, particularly green tea. It is known for its calming effects and its ability to promote relaxation without causing drowsiness. Theanine works by increasing the production of neurotransmitters like serotonin, dopamine, and GABA, which help regulate mood, reduce stress, and improve focus. It is often used in combination with caffeine to enhance cognitive performance while mitigating the jittery effects of caffeine. Theanine is commonly included in supplements aimed at reducing anxiety, improving sleep quality, and promoting mental clarity and focus.",
  },
  {
    value: "tyrosine",
    label: "Tyrosine",
    unit: "mg",
    definition:
      "Tyrosine is an amino acid that supports the production of neurotransmitters, such as dopamine and norepinephrine. It is commonly used in supplements to enhance cognitive function, improve mood, and manage stress. Tyrosine helps with mental alertness and focus, particularly during periods of fatigue or stressful situations.",
  },
  {
    value: "vitaminb1",
    label: "Vitamin B1",
    unit: "mg",
    definition:
      "Vitamin B1, also known as thiamin, is a water-soluble vitamin essential for converting carbohydrates into energy, supporting the proper functioning of the nervous system, heart, and muscles. It is a crucial cofactor for enzymes involved in glucose metabolism, which helps the body produce energy from the food we eat. Thiamin also plays a role in nerve transmission and muscle contraction, making it vital for neurological health. It is naturally found in foods like whole grains, meat (especially pork), fish, legumes, seeds, and nuts. Because the body does not store thiamin in large amounts, it must be consumed regularly through diet to prevent deficiency. A lack of vitamin B1 can lead to conditions such as beriberi, which affects the cardiovascular and nervous systems, or Wernicke-Korsakoff syndrome, a serious neurological disorder. Maintaining adequate levels of vitamin B1 is important for energy production and overall well-being.",
  },
  {
    value: "vitaminb2",
    label: "Vitamin B2",
    unit: "mg",
    definition:
      "Vitamin B2, also known as riboflavin, is a water-soluble vitamin that plays a key role in energy production and the metabolism of fats, carbohydrates, and proteins. It is essential for maintaining healthy skin, eyes, and nerve function, as well as supporting the conversion of other B vitamins, such as B6 and niacin, into their active forms. Riboflavin acts as a coenzyme in several biochemical reactions and helps protect cells from oxidative damage by contributing to the body's antioxidant defenses. It is naturally found in foods such as dairy products, eggs, lean meats, green leafy vegetables, nuts, and enriched grains. Because it is water-soluble, the body does not store riboflavin in large amounts, so regular dietary intake is necessary to prevent deficiency. Symptoms of riboflavin deficiency can include sore throat, redness and swelling of the lining of the mouth and throat, cracks or sores on the outsides of the lips (cheilosis) and at the corners of the mouth (angular stomatitis), and a swollen, magenta-colored tongue (magenta tongue). Maintaining adequate levels of vitamin B2 is important for energy metabolism and overall health.",
  },
  {
    value: "vitaminb3",
    label: "Vitamin B3",
    unit: "mg",
    definition:
      "Vitamin B3, also known as niacin, is a water-soluble vitamin that supports various bodily functions. It plays a crucial role in energy production by aiding the metabolism of carbohydrates, fats, and proteins. Vitamin B3 also helps maintain healthy skin, supports digestive health, and contributes to cardiovascular health by improving cholesterol levels. Additionally, it supports the nervous system and can help reduce inflammation. Niacin is commonly found in foods like meat, fish, and whole grains and is often included in supplements to support overall health.",
  },
  {
    value: "vitaminb5",
    label: "Vitamin B5",
    unit: "mg",
    definition:
      "Vitamin B5, also known as pantothenic acid, is a water-soluble vitamin essential for the synthesis and metabolism of carbohydrates, fats, and proteins. It plays a critical role in the formation of coenzyme A (CoA), which is vital for numerous biochemical reactions in the body, including the production of energy and the synthesis of fatty acids, cholesterol, and steroid hormones. Pantothenic acid also contributes to the maintenance of healthy skin and hair, supports the function of the nervous system, and is involved in the production of red blood cells.",
  },
  {
    value: "vitaminb6",
    label: "Vitamin B6",
    unit: "mg",
    definition:
      "Vitamin B6, also known as pyridoxine, is a water-soluble vitamin essential for numerous bodily functions. It plays a key role in amino acid metabolism, neurotransmitter synthesis, and the production of hemoglobin. Vitamin B6 supports brain health, helps regulate mood, and aids in the functioning of the immune system. It also contributes to the metabolism of proteins, fats, and carbohydrates. Common sources of vitamin B6 include poultry, fish, potatoes, and bananas. It is often included in supplements to support overall health and well-being.",
  },
  {
    value: "vitaminb7",
    label: "Vitamin B7",
    unit: "mcg",
    definition:
      "Vitamin B7, also known as biotin, is a water-soluble B-vitamin that plays an essential role in the metabolism of carbohydrates, fats, and proteins, helping the body convert food into usable energy. It is vital for maintaining healthy hair, skin, and nails, which is why it is often included in beauty and hair growth supplements. Biotin also supports nervous system function and is necessary for the proper functioning of certain enzymes involved in glucose production and fatty acid synthesis. Naturally found in foods like eggs, nuts, seeds, salmon, and leafy green vegetables, biotin deficiencies are rare but can lead to symptoms such as hair thinning, skin rashes, and brittle nails. Adequate intake of Vitamin B7 is important for metabolic health, energy production, and the maintenance of healthy hair, skin, and nails.",
  },
  {
    value: "vitaminb9",
    label: "Vitamin B9",
    unit: "mcg",
    definition:
      "Vitamin B9, commonly known as folate or folic acid, is a water-soluble vitamin vital for DNA synthesis and repair. It plays a crucial role in cell division and growth, making it especially important during periods of rapid development, such as pregnancy and infancy. Folate supports red blood cell formation and helps prevent neural tube defects in developing embryos. It also aids in mental health by supporting cognitive function and mood regulation. Sources of vitamin B9 include leafy green vegetables, legumes, and fortified cereals. It is commonly included in supplements to support overall health and prenatal care.",
  },
  {
    value: "vitaminb12",
    label: "Vitamin B12",
    unit: "mcg",
    definition:
      "Vitamin B12, also known as cobalamin, is a water-soluble vitamin essential for red blood cell formation, neurological function, and DNA synthesis. It helps maintain the health of nerve cells and supports brain function. Vitamin B12 is primarily found in animal products like meat, dairy, and eggs. It is crucial for energy metabolism and can help prevent anemia and neurological disorders. Supplementation is often necessary for those with dietary restrictions or absorption issues to maintain optimal health.",
  },
  {
    value: "vitaminc",
    label: "Vitamin C",
    unit: "mg",
    definition:
      "Vitamin C, or ascorbic acid, is a water-soluble vitamin vital for the growth and repair of tissues in the body. It acts as a powerful antioxidant, protecting cells from damage by free radicals. Vitamin C is essential for the production of collagen, which supports skin, blood vessels, bones, and cartilage. It also enhances the absorption of iron from plant-based foods and boosts the immune system. Common sources include citrus fruits, strawberries, and bell peppers. Deficiency can lead to scurvy, characterized by gum disease, fatigue, and skin issues.",
  },
  {
    value: "vitamind",
    label: "Vitamin D",
    unit: "mcg",
    definition:
      "Vitamin D is a fat-soluble vitamin crucial for maintaining healthy bones and teeth. It helps the body absorb calcium and phosphorus from the diet, which supports bone density and strength. Vitamin D also plays a role in immune function and has been linked to improved mood and overall health. It can be obtained through sunlight exposure, fatty fish, fortified dairy products, and supplements. Deficiency can lead to bone disorders such as rickets in children and osteomalacia in adults.",
  },
  {
    value: "wildfirebee",
    label: "Wildfire Bee",
    unit: "mg",
    definition:
      "Wildfire Bee is a unique form of propolis, a resinous substance that bees collect from tree buds, sap flows, or other botanical sources. Known for its potent antimicrobial, antiviral, and anti-inflammatory properties, Wildfire Bee propolis is often used in natural remedies and supplements to support immune health, soothe sore throats, and promote overall well-being. It contains a rich blend of bioactive compounds, including flavonoids, phenolic acids, and terpenes, which contribute to its ability to protect the body from infections and reduce inflammation. Wildfire Bee propolis is prized for its high potency and purity, often sourced from pristine environments where bees have access to diverse flora. It is commonly available in various forms, such as tinctures, sprays, capsules, and ointments, providing a natural option for boosting immune function and addressing various health concerns.",
  },
  {
    value: "zinc",
    label: "Zinc",
    unit: "mg",
    definition:
      "Zinc is a mineral essential for numerous physiological functions, including immune system support, DNA synthesis, and cell division. It plays a key role in protein synthesis, wound healing, and maintaining healthy skin, eyes, and taste and smell senses. Zinc also supports proper growth and development during pregnancy, childhood, and adolescence. It is often included in dietary supplements to address deficiencies and boost overall health.",
  },
];

export const preIngredients = [
  {
    value: "calories",
    label: "Calories",
    unit: "",
    hide: true,
    definition:
      "A unit of energy measurement used to quantify the amount of energy provided by food and beverages. Calories are essential for the body's basic functions and physical activities, supplying the energy needed to sustain life. In nutrition, calories refer to kilocalories (kcal), which represent the energy required to raise the temperature of one kilogram of water by one degree Celsius. Consuming calories from food allows the body to perform vital functions, such as breathing, circulation, and movement. The balance between caloric intake and expenditure plays a critical role in managing body weight and overall health.",
  },
  {
    value: "carbs",
    label: "Carbohydrates",
    unit: "g",
    hide: true,
    definition:
      "A primary source of energy for the body, carbohydrates (carbs) are macronutrients found in foods such as grains, fruits, vegetables, and legumes. They are broken down into glucose during digestion, which is then used by cells for fuel. Carbs are essential for proper brain function, physical activity, and overall metabolic processes. They can be categorized into simple carbohydrates (sugars) and complex carbohydrates (starches and fibers), each affecting the body differently in terms of energy release and digestion. Managing carbohydrate intake is important for maintaining stable blood sugar levels and supporting overall health.",
  },
  {
    value: "protein",
    label: "Protein",
    hide: true,
    unit: "g",
    definition:
      "A crucial macronutrient for the body, protein is essential for building and repairing tissues, including muscles, skin, and organs. It is composed of amino acids, which are the building blocks required for various bodily functions, such as enzyme production, hormone regulation, and immune response. Protein can be obtained from both animal sources, like meat, fish, eggs, and dairy, and plant sources, such as beans, lentils, nuts, and seeds. Adequate protein intake is vital for muscle growth, recovery, and overall health, particularly in active individuals or those seeking to build muscle mass.",
  },
  {
    value: "absorbic",
    label: "Absorbic Acid",
    unit: "mg",
    definition:
      "Ascorbic Acid, commonly known as Vitamin C, is a powerful antioxidant that supports the immune system, promotes skin health, and aids in the absorption of iron from plant-based foods. It plays a crucial role in collagen production, which is essential for healthy skin, bones, and connective tissue. Ascorbic Acid is found naturally in many fruits and vegetables and is often included in supplements to boost overall health and prevent deficiencies.",
  },
  {
    value: "acetylcarnitine",
    label: "Acetyl-L-Carnitine",
    unit: "mg",
    definition:
      "Acetyl-L-Carnitine (ALCAR) is a form of the amino acid L-carnitine that helps the body produce energy by transporting fatty acids into the mitochondria, where they are burned for fuel. It is known for its ability to enhance cognitive function, support memory, and improve mental clarity. ALCAR is also popular in supplements aimed at boosting physical performance and fat metabolism. Additionally, it has antioxidant properties that help protect cells from damage and support overall brain health.",
  },
  {
    value: "acetylcarnitinehcl",
    label: "Acetyl-L-Carnitine HCl",
    unit: "mg",
    definition:
      "Acetyl-L-Carnitine HCl (ALCAR HCl) is a highly bioavailable form of L-carnitine, combined with hydrochloride to enhance absorption. It plays a key role in energy production by transporting fatty acids into the mitochondria, aiding in fat metabolism and physical performance. ALCAR HCl is also valued for its cognitive benefits, including improved memory, focus, and mental clarity. Its antioxidant properties help protect brain cells from damage, making it a popular ingredient in supplements for both physical and cognitive health.",
  },
  {
    value: "acetylcysteine",
    label: "Acetyl Cysteine",
    unit: "mg",
    definition:
      "Acetyl Cysteine, also known as N-Acetyl Cysteine (NAC), is a powerful antioxidant and precursor to glutathione, one of the body's most important detoxifying agents. NAC supports liver health, helps protect against oxidative stress, and is often used to boost the immune system. It also has benefits for respiratory health, as it can help thin mucus, making it easier to clear the airways. NAC is commonly included in supplements aimed at supporting overall detoxification, respiratory health, and immune function.",
  },
  {
    value: "acetyltyrosine",
    label: "Acetyl Tyrosine",
    unit: "mg",
    definition:
      "Acetyl-L-Tyrosine is a modified form of the amino acid L-tyrosine, designed for better absorption and bioavailability. It supports mental performance by boosting the production of neurotransmitters like dopamine, norepinephrine, and adrenaline, which are crucial for focus, mood, and stress response. Acetyl-L-Tyrosine is often used in cognitive enhancement supplements to improve alertness, reduce stress, and enhance mental clarity, especially during demanding physical or mental activities.",
  },
  {
    value: "actigin",
    label: "ActiGin®",
    unit: "mg",
    definition:
      "ActiGin® is a patented blend of natural ingredients, primarily derived from Panax notoginseng and Rosa roxburghii, designed to enhance athletic performance and recovery. It works by increasing the body's ability to produce energy and reducing inflammation caused by exercise, which helps improve endurance and accelerate recovery time. ActiGin® also supports muscle health by promoting the regeneration of muscle cells and reducing oxidative stress. It's commonly included in pre-workout and recovery supplements aimed at athletes and fitness enthusiasts looking to maximize their performance and recovery.",
  },
  {
    value: "advantraz",
    label: "Advantra Z®",
    unit: "mg",
    definition:
      "Advantra Z® is a patented extract derived from bitter orange (Citrus aurantium), known for its natural thermogenic properties. It contains synephrine, a compound that boosts metabolism, increases energy levels, and supports fat loss by promoting lipolysis (the breakdown of fat). Advantra Z® is often used in weight loss and energy supplements as a safer alternative to ephedra, offering similar benefits without the harsh side effects. It's popular among those looking to enhance their workout performance and achieve better weight management results.",
  },
  {
    value: "agmatinesulfate",
    label: "Agmatine Sulfate",
    unit: "mg",
    definition:
      "Agmatine Sulfate is a derivative of the amino acid L-arginine, known for its diverse benefits in both cognitive and physical performance. It works by modulating nitric oxide production, which can enhance blood flow, leading to improved muscle pumps and nutrient delivery during exercise. Agmatine Sulfate is also recognized for its potential to support mood, reduce stress, and alleviate pain by influencing neurotransmitter activity. It's commonly found in pre-workout and cognitive enhancement supplements, valued for its ability to enhance workout performance and overall well-being.",
  },
  {
    value: "africanmango",
    label: "African Mango Extract",
    unit: "mg",
    definition:
      "African Mango Extract, derived from the seeds of the Irvingia gabonensis tree, is popular in weight management supplements. It's known for its ability to support fat loss by improving metabolism, reducing appetite, and regulating blood sugar levels. The extract is also believed to help lower cholesterol and promote overall heart health. African Mango Extract is often included in supplements aimed at those looking to lose weight and improve metabolic health naturally.",
  },
  {
    value: "alfabin",
    label: "AlfaBin®",
    unit: "mg",
    definition:
      "AlfaBin® is a proprietary blend designed to support healthy blood sugar levels and carbohydrate metabolism. It works by helping to slow down the absorption of carbohydrates, which can lead to more stable blood sugar levels and improved insulin sensitivity. AlfaBin® is often included in supplements aimed at supporting metabolic health, weight management, and overall glucose control, making it a popular choice for those looking to manage their carbohydrate intake more effectively.",
  },
  {
    value: "alphagpc",
    label: "Alpha GPC",
    unit: "mg",
    min: 300,
    max: 600,
    definition:
      "Alpha GPC (Alpha-Glyceryl Phosphoryl Choline) is a natural choline compound that plays a key role in cognitive function and brain health. It enhances the production of acetylcholine, a neurotransmitter important for memory, learning, and mental focus. Alpha GPC is commonly used in nootropic supplements for its ability to improve cognitive performance, support mental clarity, and enhance overall brain function. It is also valued for its potential to boost physical performance, particularly in athletes, by supporting growth hormone production and muscle recovery.",
  },
  {
    value: "ala",
    label: "Alpha-Lipoic Acid",
    unit: "mg",
    definition:
      "Alpha-Lipoic Acid (ALA) is a powerful antioxidant that helps protect cells from oxidative damage and supports overall health. It plays a crucial role in energy production by aiding in the conversion of glucose into energy within the mitochondria. ALA is unique because it is both water- and fat-soluble, allowing it to work throughout the body. It is often used in supplements to support healthy blood sugar levels, enhance skin health, and protect against oxidative stress. ALA is also known for its potential to support nerve function and promote healthy aging.",
  },
  {
    value: "alphasize",
    label: "AlphaSize®",
    unit: "mg",
    min: 300,
    max: 600,
    definition:
      "AlphaSize® is a patented form of Alpha-Glyceryl Phosphoryl Choline (Alpha-GPC), known for its effectiveness in enhancing cognitive function and physical performance. It works by increasing the production of acetylcholine, a key neurotransmitter involved in memory, learning, and muscle control. AlphaSize® is often used in nootropic supplements to boost mental clarity, focus, and memory. Additionally, it is popular in sports supplements for its ability to improve strength, power output, and recovery by supporting optimal brain-muscle communication.",
  },
  {
    value: "alphazone",
    label: "AlphaZone®",
    unit: "mg",
    min: 300,
    max: 600,
    definition:
      "AlphaZone® is a branded form of Alpha-Glyceryl Phosphoryl Choline (Alpha-GPC), designed to enhance both cognitive and physical performance. It works by increasing levels of acetylcholine, a neurotransmitter crucial for memory, learning, and muscle function. AlphaZone® is commonly used in nootropic supplements to boost focus, mental clarity, and cognitive sharpness. In sports and fitness supplements, it's valued for its ability to enhance strength, power output, and recovery, making it a popular choice for athletes and individuals seeking to improve both mental and physical performance.",
  },
  {
    value: "alphayohimbine",
    label: "Alpha Yohimbine",
    unit: "mg",
    definition:
      "Alpha Yohimbine, also known as Rauwolscine, is a natural alkaloid similar to yohimbine but with a more refined and potent effect. It is primarily used in fat-burning supplements for its ability to promote fat loss by blocking alpha-2 adrenergic receptors, which can enhance the body's ability to burn stored fat. Additionally, Alpha Yohimbine is known to increase energy levels, suppress appetite, and improve mood. It is often included in pre-workout and weight management supplements for those looking to accelerate fat loss while maintaining focus and energy during exercise.",
  },
  {
    value: "alphayohimbinehcl",
    label: "Alpha Yohimbine HCl",
    unit: "mg",
    definition:
      "Alpha Yohimbine HCl, also known as Rauwolscine HCl, is a hydrochloride form of Alpha Yohimbine, which enhances its absorption and potency. It is commonly used in fat-burning supplements for its powerful ability to block alpha-2 adrenergic receptors, leading to increased fat breakdown and improved weight loss. Additionally, Alpha Yohimbine HCl is valued for its stimulant properties, boosting energy, focus, and mood, making it a popular ingredient in pre-workout supplements. Its effects are often considered smoother and more refined than traditional yohimbine, with fewer side effects.",
  },
  {
    value: "amatea",
    label: "AmaTea®",
    unit: "mg",
    definition:
      "AmaTea® is a branded organic extract derived from the guayusa leaf, a naturally caffeinated plant native to the Amazon rainforest. It is known for providing a smooth, sustained energy boost without the jitters or crash often associated with other sources of caffeine. In addition to its stimulating effects, AmaTea® is rich in antioxidants, which help protect cells from oxidative stress. It also contains theobromine, an alkaloid that can enhance mood and focus. AmaTea® is commonly used in energy drinks, supplements, and wellness products for those seeking a natural, balanced energy source with added health benefits.",
  },
  {
    value: "amentopump",
    label: "AmentoPump®",
    unit: "mg",
    definition:
      "AmentoPump® is a patented extract derived from the Amentoflavone compound found in certain plants like Selaginella tamariscina. It is popular in sports and pre-workout supplements for its ability to enhance muscle pumps, improve vascularity, and boost athletic performance. AmentoPump® works by increasing nitric oxide production and inhibiting the enzyme phosphodiesterase, leading to better blood flow and nutrient delivery to muscles during exercise. Additionally, it has been noted for its potential to enhance strength, endurance, and overall workout performance without the need for stimulants.",
  },
  {
    value: "aminoisoheptane",
    label: "2-Aminoisoheptane",
    unit: "mg",
    definition:
      "2-Aminoisoheptane, also known as DMHA (Dimethylhexylamine), is a synthetic stimulant often used in pre-workout and fat-burning supplements. It is known for its ability to increase energy, focus, and mental alertness, making it popular among athletes and fitness enthusiasts. 2-Aminoisoheptane works by stimulating the central nervous system, similar to other stimulants like DMAA, but with a slightly milder effect. It can also enhance mood and motivation, making it useful for intense training sessions. However, due to its potent effects, it is recommended to use 2-Aminoisoheptane with caution, as it can lead to increased heart rate, blood pressure, and other side effects if not properly dosed.",
  },
  {
    value: "arginine",
    label: "Arginine",
    unit: "mg",
    min: 3000,
    max: 6000,
    definition:
      "Arginine, also known as L-Arginine, is an amino acid that plays a crucial role in the body's production of nitric oxide, a compound that helps relax blood vessels and improve blood flow. This makes Arginine popular in pre-workout supplements for enhancing muscle pumps, endurance, and overall exercise performance. It also supports protein synthesis, which is important for muscle growth and recovery. Beyond its athletic benefits, Arginine is used to support heart health, immune function, and wound healing. It's naturally found in foods like meat, dairy, and nuts, and is often supplemented to boost physical performance and cardiovascular health.",
  },
  {
    value: "argininehcl",
    label: "Arginine HCl",
    unit: "mg",
    definition:
      "Arginine HCl, or L-Arginine Hydrochloride, is a form of the amino acid Arginine that is combined with hydrochloride to enhance its absorption and stability in the body. Like regular Arginine, it plays a key role in the production of nitric oxide, which helps improve blood flow, leading to better muscle pumps, endurance, and overall exercise performance. Arginine HCl is commonly used in pre-workout supplements for its ability to boost vascularity and support muscle recovery. It is also beneficial for cardiovascular health, as it promotes healthy blood circulation and supports immune function. The hydrochloride form makes it easier for the body to absorb, making it a popular choice for those looking to maximize the benefits of Arginine.",
  },
  {
    value: "aakg",
    label: "Arginine AKG",
    unit: "mg",
    definition:
      "Arginine AKG, or Arginine Alpha-Ketoglutarate, is a salt formed by combining the amino acid L-Arginine with alpha-ketoglutarate, a compound involved in the energy production process. This combination enhances the absorption and effectiveness of Arginine, particularly in boosting nitric oxide production. Increased nitric oxide levels improve blood flow, leading to better muscle pumps, enhanced endurance, and improved nutrient delivery during exercise. Arginine AKG is commonly used in pre-workout and performance supplements to support muscle growth, recovery, and overall exercise performance. Additionally, it is believed to aid in the synthesis of other amino acids and promote overall cardiovascular health.",
  },
  {
    value: "argininenitrate",
    label: "Arginine Nitrate",
    unit: "mg",
    definition:
      "Arginine Nitrate is a combination of the amino acid L-Arginine and nitrate, designed to maximize nitric oxide production in the body. This dual-action compound significantly enhances blood flow by not only increasing nitric oxide levels through the Arginine pathway but also by directly supplying nitrates, which are converted into nitric oxide. The result is improved muscle pumps, increased vascularity, and better endurance during workouts. Arginine Nitrate is often used in pre-workout supplements for its powerful effects on exercise performance, helping to deliver more oxygen and nutrients to muscles. Additionally, it supports cardiovascular health by promoting healthy blood circulation.",
  },
  {
    value: "ksmashwaganda",
    label: "KSM-66 Ashwaganda®",
    unit: "mg",
    definition:
      "KSM-66 Ashwagandha® is a highly concentrated and standardized extract of the Ashwagandha root, known for its potent adaptogenic properties. It is widely used to reduce stress, anxiety, and cortisol levels, while also enhancing mental clarity, focus, and overall well-being. KSM-66 is particularly valued for its ability to support physical performance by improving strength, endurance, and recovery. Additionally, it has been shown to support hormonal balance, boost libido, and enhance sleep quality. As a full-spectrum extract, KSM-66 retains the natural balance of the herb's constituents, making it a popular choice in supplements aimed at promoting both mental and physical health.",
  },
  {
    value: "astragin",
    label: "AstraGin®",
    unit: "mg",
    definition:
      "AstraGin® is a patented blend of natural compounds derived from Astragalus membranaceus and Panax notoginseng, designed to enhance nutrient absorption and promote gut health. It works by increasing the absorption of various nutrients, including amino acids, vitamins, and minerals, which can improve the effectiveness of supplements and overall nutritional intake. AstraGin® is also known for its ability to support a healthy gut lining, reduce inflammation, and promote overall digestive health. It's commonly included in a wide range of supplements to enhance their bioavailability and ensure that the body receives the maximum benefits from the nutrients consumed.",
  },
  {
    value: "aquamin",
    label: "Aquamin®",
    unit: "mg",
    definition:
      "Aquamin® is a natural, multi-mineral complex derived from red marine algae (Lithothamnion species) harvested from the pristine waters off the coast of Iceland. It is rich in calcium, magnesium, and over 70 other trace minerals, making it a popular ingredient in supplements aimed at supporting bone, joint, and digestive health. Aquamin® is known for its high bioavailability, meaning the minerals are easily absorbed and utilized by the body. In addition to bone health, it has anti-inflammatory properties that can benefit joint function and overall wellness. Aquamin® is often included in dietary supplements, fortified foods, and beverages to provide a natural source of essential minerals.",
  },
  {
    value: "bacowize",
    label: "Bacowize®",
    unit: "mg",
    definition:
      "Bacowize® is a proprietary blend of natural ingredients designed to support gut health and overall digestive wellness. It typically contains a combination of botanicals and digestive enzymes that work together to promote a balanced gut microbiome, enhance nutrient absorption, and reduce digestive discomfort. Bacowize® is often included in supplements aimed at improving digestion, alleviating bloating, and supporting a healthy digestive system. It may also have immune-boosting properties, as a healthy gut is closely linked to overall immune function.",
  },
  {
    value: "beetroot",
    label: "Beet Root Extract",
    unit: "mg",
    definition:
      "Beet Root Extract is derived from the root of the beet plant (Beta vulgaris) and is commonly used in supplements for its numerous health benefits, particularly for cardiovascular and athletic performance. Rich in nitrates, Beet Root Extract helps increase nitric oxide production in the body, which improves blood flow, enhances oxygen delivery to muscles, and boosts endurance. This makes it popular among athletes for improving exercise performance and reducing fatigue. Additionally, Beet Root Extract is high in antioxidants, vitamins, and minerals, supporting overall health, including heart health and blood pressure regulation. It's often included in pre-workout supplements, as well as general health products.",
  },
  {
    value: "betaalanine",
    label: "Beta Alanine",
    unit: "mg",
    definition:
      "Beta-Alanine is an amino acid that enhances athletic performance by increasing muscle carnosine levels. This helps buffer acid buildup during intense exercise, delaying muscle fatigue and improving endurance. Commonly found in pre-workout supplements, Beta-Alanine is known for its tingling sensation, a harmless side effect called paresthesia, which some users experience.",
  },
  {
    value: "betaine",
    label: "Betaine Anhydrous",
    unit: "mg",
    definition:
      "Betaine Anhydrous is a compound derived from beets that supports muscle strength and endurance. It works by promoting hydration within cells and aiding in the synthesis of creatine, which helps improve exercise performance. Betaine is also known for its role in supporting heart health by reducing homocysteine levels. It's commonly found in pre-workout supplements and muscle-building products.",
  },
  {
    value: "betainehcl",
    label: "Betaine HCl",
    unit: "mg",
    definition:
      "Betaine HCl (Betaine Hydrochloride) is a form of betaine combined with hydrochloric acid, commonly used to support digestive health. It is often taken to increase stomach acid levels, which can help improve the digestion and absorption of nutrients, particularly in individuals with low stomach acid. Betaine HCl is also believed to support the body's natural detoxification processes by aiding in the proper digestion of proteins and the absorption of vitamins and minerals. Additionally, it may help reduce symptoms of indigestion, bloating, and acid reflux. Betaine HCl is often included in digestive enzyme supplements to promote optimal digestive function and overall gut health.",
  },
  {
    value: "betainenitrate",
    label: "Betaine Nitrate",
    unit: "mg",
    definition:
      "Betaine Nitrate is a compound that combines betaine, a naturally occurring substance found in foods like beets, with nitrate, a molecule known for its ability to boost nitric oxide production in the body. This combination is particularly effective for enhancing athletic performance. Betaine supports cellular hydration, muscle endurance, and strength, while nitrate increases blood flow by expanding blood vessels, leading to better oxygen and nutrient delivery to muscles during exercise. Betaine Nitrate is commonly used in pre-workout supplements to improve muscle pumps, endurance, and overall workout performance. Additionally, it supports cardiovascular health by promoting healthy blood circulation.",
  },
  {
    value: "betaphenyl",
    label: "Beta Phenylethylamine",
    unit: "mg",
    definition:
      "Beta Phenylethylamine (PEA) is a natural compound that acts as a neurotransmitter in the brain. It is known for its mood-enhancing and cognitive-boosting effects. PEA stimulates the release of dopamine and norepinephrine, which can lead to improved mood, increased focus, and enhanced mental clarity. It is often used in supplements aimed at boosting energy, elevating mood, and supporting cognitive function. Due to its stimulating effects, PEA is sometimes included in pre-workout supplements to enhance motivation and performance. However, its effects are typically short-lived, as it is rapidly broken down in the body.",
  },
  {
    value: "betaphenylhcl",
    label: "Beta Phenylethylamine HCl",
    unit: "mg",
    definition:
      "Beta Phenylethylamine HCl (PEA HCl) is a more stable, hydrochloride form of Beta Phenylethylamine, which enhances its absorption and effectiveness in the body. Like regular PEA, it acts as a neurotransmitter that promotes the release of dopamine and norepinephrine, leading to improved mood, increased focus, and heightened mental clarity. PEA HCl is commonly used in mood-enhancing supplements and nootropics for its ability to boost energy, motivation, and cognitive function. Additionally, it is often included in pre-workout supplements for its stimulating effects, which can enhance motivation and performance during exercise. Despite its rapid breakdown in the body, the HCl form provides a slightly more sustained effect compared to regular PEA.",
  },
  {
    value: "bioperine",
    label: "BioPerine®",
    unit: "mg",
    definition:
      "BioPerine® is a patented extract derived from black pepper (Piper nigrum) that is standardized to contain 95% piperine, the active compound responsible for its health benefits. BioPerine® is widely recognized for its ability to enhance the bioavailability and absorption of various nutrients and supplements, making them more effective. By inhibiting certain enzymes and transporters in the digestive tract, BioPerine® allows for better absorption of vitamins, minerals, and other active compounds, such as curcumin, CoQ10, and resveratrol. It is commonly included in a wide range of dietary supplements to improve their efficacy and ensure that the body can fully utilize the ingredients.",
  },
  {
    value: "bitterorange",
    label: "Bitter Orange Extract",
    unit: "mg",
    definition:
      "Bitter Orange Extract is derived from the fruit of the bitter orange tree (Citrus aurantium) and is commonly used in weight loss and energy-boosting supplements. The extract contains several active compounds, most notably synephrine, which is known for its thermogenic properties. Synephrine works by stimulating the body's metabolism, increasing energy expenditure, and promoting fat loss. It is often used as a natural alternative to ephedra, offering similar benefits but with a lower risk of side effects. Bitter Orange Extract is also known for its appetite-suppressing effects, making it a popular ingredient in products aimed at supporting weight management and enhancing physical performance.",
  },
  {
    value: "blackpepper",
    label: "Black Pepper Extract",
    unit: "mg",
    definition:
      "Black Pepper Extract, often standardized to contain piperine, is derived from the dried fruit of the black pepper plant (Piper nigrum). Piperine is the active compound responsible for its health benefits, particularly its ability to enhance the bioavailability of various nutrients and supplements. By inhibiting certain enzymes and transporters in the digestive system, black pepper extract helps increase the absorption and effectiveness of vitamins, minerals, and other active compounds, such as curcumin and resveratrol. This makes it a common addition to dietary supplements, especially those containing ingredients that are otherwise poorly absorbed. Beyond its role in enhancing absorption, black pepper extract may also have antioxidant and anti-inflammatory properties.",
  },
  {
    value: "biophytum",
    label: "Biophytum",
    unit: "mg",
    definition:
      "Biophytum is a genus of small, flowering plants commonly found in tropical regions. The most well-known species, Biophytum sensitivum, is traditionally used in Ayurvedic medicine for its various health benefits. It is valued for its adaptogenic properties, helping the body manage stress and improve overall resilience. Biophytum is also known for its anti-inflammatory, antioxidant, and wound-healing properties. It is often used to support immune health, enhance energy levels, and promote overall well-being. In supplements, Biophytum extract is included for its potential to support physical and mental health, particularly in managing stress and improving recovery.",
  },
  {
    value: "boron",
    label: "Boron",
    unit: "mcg",
    definition:
      "Boron is a trace mineral that plays a crucial role in supporting bone health, hormone regulation, and cognitive function. It helps the body efficiently use calcium, magnesium, and vitamin D, which are vital for maintaining strong bones and preventing osteoporosis. Boron is also known to influence the metabolism of key hormones such as estrogen and testosterone, potentially enhancing muscle growth, recovery, and overall hormonal balance. Additionally, boron may support brain function, improving memory and cognitive performance. It is often included in dietary supplements aimed at promoting bone health, hormonal balance, and mental clarity.",
  },
  {
    value: "caffeine",
    label: "Caffeine",
    unit: "mg",
    definition:
      "Caffeine is a natural stimulant that boosts alertness and energy by blocking sleep-promoting neurotransmitters. It's commonly found in coffee, tea, and supplements to enhance focus and performance. While effective, excessive intake can lead to jitteriness and sleep issues.",
  },
  {
    value: "calcik",
    label: "Calci-K®",
    unit: "mg",
    definition:
      "Calci-K® is a patented form of calcium that combines calcium with potassium and phosphorus in a highly bioavailable complex. It is designed to support bone health, muscle function, and overall calcium metabolism. The inclusion of potassium and phosphorus enhances the absorption and effectiveness of calcium in the body, making Calci-K® an efficient way to maintain strong bones and teeth. Additionally, it supports muscle contraction and nerve function, playing a critical role in overall physical health. Calci-K® is often included in dietary supplements and fortified foods aimed at improving bone density, supporting cardiovascular health, and enhancing athletic performance.",
  },
  {
    value: "calcium",
    label: "Calcium",
    unit: "mg",
    definition:
      "Calcium is an essential mineral that plays a vital role in maintaining strong bones and teeth, as well as supporting overall health. It is the most abundant mineral in the human body, with the majority stored in bones and teeth, providing them with structure and strength. Calcium is also crucial for muscle function, nerve transmission, blood clotting, and the regulation of heart rhythms. Adequate calcium intake is important throughout life, particularly during childhood, adolescence, and older age to prevent bone-related conditions such as osteoporosis. It is commonly found in dairy products, leafy greens, and fortified foods, and is often included in supplements to ensure sufficient intake for bone health and overall wellness.",
  },
  {
    value: "calciumakg",
    label: "Calcium Alpha Ketoglutarate",
    unit: "mg",
    definition:
      "Calcium Alpha Ketoglutarate (Ca-AKG) is a compound that combines calcium with alpha-ketoglutarate, a key molecule involved in the Krebs cycle, which is essential for energy production in cells. This combination provides the benefits of both calcium and alpha-ketoglutarate. Calcium supports bone health, muscle function, and nerve transmission, while alpha-ketoglutarate plays a role in energy metabolism and may help reduce oxidative stress and support overall cellular health. Ca-AKG is often used in supplements aimed at enhancing athletic performance, supporting bone health, and promoting longevity by improving cellular function and energy production.",
  },
  {
    value: "caloriburn",
    label: "CaloriBurn GP®",
    unit: "mg",
    definition:
      "CaloriBurn GP® is a branded, natural extract derived from grains of paradise (Aframomum melegueta), a spice related to ginger. It is commonly used in weight management and fat-burning supplements due to its thermogenic properties. CaloriBurn GP® works by activating brown adipose tissue (BAT), which increases calorie expenditure and promotes fat loss by converting stored fat into energy. Additionally, it can enhance metabolic rate, improve energy levels, and support overall weight management goals. CaloriBurn GP® is often included in pre-workout and weight loss formulas for its ability to boost fat burning and support a lean physique.",
  },
  {
    value: "carnipure",
    label: "Carnipure®",
    unit: "mg",
    definition:
      "Carnipure® is a high-quality, branded form of L-carnitine, a naturally occurring amino acid derivative that plays a crucial role in energy production by transporting fatty acids into the mitochondria, where they are burned for fuel. Carnipure® is particularly known for its purity and effectiveness in supporting fat metabolism, enhancing athletic performance, and aiding in muscle recovery. It is commonly used in supplements aimed at boosting endurance, reducing fatigue, and promoting weight loss by increasing the body's ability to burn fat. Additionally, Carnipure® supports cardiovascular health by helping to maintain healthy cholesterol levels and promoting overall energy levels. It is often included in sports nutrition products, weight management supplements, and energy drinks.",
  },
  {
    value: "carnitine",
    label: "Carnitine",
    unit: "mg",
    definition:
      "Carnitine is a naturally occurring compound derived from the amino acids lysine and methionine. It plays a vital role in energy production by facilitating the transport of long-chain fatty acids into the mitochondria, where they are oxidized to produce energy. Carnitine is particularly important for muscle function, as it helps convert fat into energy, making it a popular ingredient in supplements aimed at enhancing athletic performance, supporting weight loss, and improving endurance.",
  },
  {
    value: "carnosyn",
    label: "CarnoSyn®",
    unit: "mg",
    definition:
      "CarnoSyn® is a patented form of beta-alanine, an amino acid that boosts muscle endurance by increasing carnosine levels. This helps delay muscle fatigue during intense exercise, allowing for longer and more effective workouts. It's commonly found in pre-workout supplements for athletes looking to enhance performance and recovery.",
  },
  {
    value: "cellflo6",
    label: "CellFlo6™",
    unit: "mg",
    definition:
      "CellFlo6™ is a patented green tea extract known for its cardiovascular and circulation benefits. It helps enhance blood flow, reduce oxidative stress, and support overall heart health. CellFlo6™ is often included in supplements aimed at improving vascularity, endurance, and athletic performance.",
  },
  {
    value: "choline",
    label: "Choline",
    unit: "mg",
    definition:
      "Choline is an essential nutrient that supports brain health, liver function, and metabolism. It plays a key role in the production of acetylcholine, a neurotransmitter important for memory, mood, and muscle control. Choline is commonly found in eggs, meat, and certain vegetables, and is often included in supplements to enhance cognitive function and support overall health.",
  },
  {
    value: "cholinebitartrate",
    label: "Choline Bitartrate",
    unit: "mg",
    definition:
      "Choline Bitartrate is a form of choline combined with tartaric acid to enhance absorption. It supports brain health by aiding in the production of acetylcholine, a neurotransmitter important for memory and learning. Choline Bitartrate is commonly used in supplements to improve cognitive function, support liver health, and boost metabolism.",
  },
  {
    value: "chromium",
    label: "Chromium",
    unit: "mcg",
    definition:
      "Chromium is a trace mineral that plays a crucial role in regulating blood sugar levels by enhancing the action of insulin. It supports metabolism and may help with weight management by reducing cravings and improving glucose utilization. Chromium is commonly found in supplements aimed at supporting healthy blood sugar levels and overall metabolic health.",
  },
  {
    value: "cdpcholine",
    label: "CDP-Choline",
    unit: "mg",
    definition:
      "CDP-Choline, also known as Citicoline, is a potent form of choline that supports brain health and cognitive function. It boosts the production of acetylcholine, a neurotransmitter vital for memory and learning, and also aids in maintaining the health of brain cell membranes. CDP-Choline is commonly used in nootropic supplements to enhance focus, memory, and overall mental clarity.",
  },
  {
    value: "chloride",
    label: "Chloride",
    unit: "mg",
    definition:
      "Chloride is an essential electrolyte that helps maintain fluid balance, supports proper nerve function, and aids in digestion by forming hydrochloric acid in the stomach. It is commonly found in salt (sodium chloride) and is crucial for maintaining overall hydration and pH balance in the body. Chloride is often included in electrolyte supplements to support hydration and overall electrolyte balance.",
  },
  {
    value: "citrapeak",
    label: "CitraPeak®",
    unit: "mg",
    definition:
      "CitraPeak® is a branded citrus extract known for its ability to enhance blood flow and vascularity. It works by increasing nitric oxide levels, which improves circulation and nutrient delivery to muscles during exercise. CitraPeak® is commonly included in pre-workout supplements to boost muscle pumps, endurance, and overall athletic performance.",
  },
  {
    value: "citicoline",
    label: "Citicoline",
    unit: "mg",
    definition:
      "Citicoline, also known as CDP-Choline, is a highly bioavailable form of choline that supports brain health and cognitive function. It enhances the production of acetylcholine, a neurotransmitter important for memory and learning, and supports the maintenance of healthy brain cell membranes. Citicoline is commonly used in nootropic supplements to improve focus, memory, and mental clarity.",
  },
  {
    value: "citicholinesodium",
    label: "Citicoline Sodium",
    unit: "mg",
    definition:
      "Citicoline Sodium is a salt form of Citicoline (CDP-Choline) that enhances its stability and absorption. It supports brain health by boosting acetylcholine production, which is essential for memory, learning, and cognitive function. Citicoline Sodium is often used in nootropic supplements to improve focus, mental clarity, and overall cognitive performance.",
  },
  {
    value: "citricacid",
    label: "Citric Acid",
    unit: "mg",
    definition:
      "Citric Acid is a natural compound found in citrus fruits, commonly used as a preservative, flavor enhancer, and pH adjuster in food and supplements. It plays a role in the Krebs cycle, a key metabolic pathway that generates energy in cells. In supplements, Citric Acid is often included to enhance flavor, improve absorption of minerals, and support energy production.",
  },
  {
    value: "citrulline",
    label: "Citrulline",
    unit: "mg",
    definition:
      "Citrulline is an amino acid that plays a key role in increasing nitric oxide production, which helps improve blood flow and oxygen delivery to muscles. This enhances endurance, reduces muscle fatigue, and promotes better exercise performance. Citrulline is commonly found in pre-workout supplements to boost athletic performance, support muscle recovery, and enhance muscle pumps.",
  },
  {
    value: "citrullinenitrate",
    label: "Citrulline Nitrate",
    unit: "mg",
    definition:
      "Citrulline Nitrate is a compound that combines the amino acid citrulline with nitrate, enhancing both nitric oxide production and blood flow. This dual-action formula improves muscle pumps, endurance, and exercise performance by delivering more oxygen and nutrients to muscles. Citrulline Nitrate is often included in pre-workout supplements to maximize athletic performance and muscle recovery.",
  },
  {
    value: "cinnulinpf",
    label: "Cinnulin PF®",
    unit: "mg",
    definition:
      "Cinnulin PF® is a patented, water-soluble extract derived from cinnamon bark, known for its ability to support healthy blood sugar levels and improve insulin sensitivity. It contains concentrated polyphenols that help regulate glucose metabolism, making it a popular ingredient in supplements aimed at supporting metabolic health and weight management. Cinnulin PF® is also valued for its antioxidant properties, which help protect cells from oxidative stress.",
  },
  {
    value: "citrusaurantium",
    label: "Citrus Aurantium",
    unit: "mg",
    definition:
      "Citrus Aurantium, also known as bitter orange, is a fruit extract commonly used in weight loss and energy-boosting supplements. It contains synephrine, a compound that stimulates metabolism, increases energy expenditure, and promotes fat burning. Citrus Aurantium is often included in formulations to support weight management and enhance athletic performance.",
  },
  {
    value: "clusterdextring",
    label: "Cluster Dextrin™",
    unit: "mg",
    definition:
      "Cluster Dextrin™, also known as Highly Branched Cyclic Dextrin (HBCD), is a complex carbohydrate derived from amylopectin. It is designed to provide a quick and sustained source of energy with minimal impact on blood sugar levels. Cluster Dextrin™ is easily digestible and helps maintain stable energy during workouts, reducing fatigue and promoting endurance. It is commonly used in intra-workout and post-workout supplements to enhance performance and recovery.",
  },
  {
    value: "cocoaalkaloid",
    label: "Cocoa Alkaloids Extract",
    unit: "mg",
    definition:
      "Cocoa Alkaloids Extract is derived from cocoa beans and contains natural stimulants like theobromine and caffeine. These alkaloids are known for their ability to enhance energy, improve mood, and increase mental focus. Cocoa Alkaloids Extract is often included in supplements aimed at boosting cognitive performance, supporting weight management, and enhancing overall well-being. Additionally, it has antioxidant properties that help protect cells from oxidative stress.",
  },
  {
    value: "cocoabuterol",
    label: "Cocoabuterol®",
    unit: "mg",
    definition:
      "Cocoabuterol® is a natural cocoa extract supplement designed to enhance athletic performance and fat loss, often used in pre-workout formulations. It contains compounds like theobromine and polyphenols, which are believed to increase energy levels, improve focus, and support fat metabolism. Theobromine, a stimulant similar to caffeine but milder, helps promote endurance and reduce fatigue, while polyphenols are antioxidants that may aid in muscle recovery and overall cardiovascular health. This combination makes Cocoabuterol® a popular choice for those looking to boost their workout performance without the jitters or crash associated with high-caffeine supplements.",
  },
  {
    value: "cocoaseed",
    label: "Cocoa Seed Extract",
    unit: "mg",
    definition:
      "Cocoa Seed Extract is derived from the seeds of the cocoa plant (Theobroma cacao) and is rich in flavonoids, particularly epicatechin, which have powerful antioxidant properties. It is known for supporting cardiovascular health by improving blood flow, reducing inflammation, and lowering blood pressure. Cocoa Seed Extract is also associated with mood enhancement and cognitive benefits, as it can increase the production of feel-good neurotransmitters like serotonin. It's commonly included in supplements for its heart health benefits, mood-boosting effects, and antioxidant protection.",
  },
  {
    value: "cocomineral",
    label: "CocoMineral®",
    unit: "mg",
    definition:
      "CocoMineral® is a branded, natural source of coconut water powder rich in electrolytes, particularly potassium and magnesium. It is designed to support hydration, replenish electrolytes, and improve overall physical performance. CocoMineral® is often included in sports drinks and hydration supplements to help maintain fluid balance, enhance endurance, and aid in recovery during and after exercise.",
  },
  {
    value: "coconutpowder",
    label: "Coconut Powder",
    unit: "mg",
    definition:
      "Coconut Powder is made from dried and ground coconut meat, preserving the rich nutrients found in fresh coconut. It is a natural source of healthy fats, particularly medium-chain triglycerides (MCTs), which provide quick energy and support metabolic health. Coconut Powder is also rich in fiber, which aids in digestion, and contains essential minerals like potassium and magnesium. It is commonly used in food products, smoothies, and supplements to add flavor, enhance nutrition, and support overall health, particularly in promoting energy and digestive health.",
  },
  {
    value: "coconutwater",
    label: "Coconut Water Concentrate",
    unit: "mg",
    definition:
      "Coconut Water Concentrate is a concentrated form of coconut water that retains its natural electrolytes, such as potassium, magnesium, and sodium. It is used to support hydration, replenish lost electrolytes, and provide a natural source of energy. Coconut Water Concentrate is often included in sports drinks, hydration supplements, and wellness products to enhance fluid balance, improve endurance, and promote recovery during and after physical activity.",
  },
  {
    value: "coffeeberry",
    label: "Coffeeberry®",
    unit: "mg",
    definition:
      "Coffeeberry® is a patented extract derived from the whole fruit of the coffee plant, including the bean, pulp, and skin. It is rich in antioxidants, particularly polyphenols, and natural caffeine, making it beneficial for both cognitive function and overall health. Coffeeberry® is known for its ability to enhance mental alertness, improve focus, and provide a smooth, sustained energy boost without the jitters often associated with synthetic caffeine. It is commonly used in nootropic supplements, energy drinks, and wellness products for its brain-boosting and antioxidant properties.",
  },
  {
    value: "cognatiq",
    label: "CognatiQ™",
    unit: "mg",
    definition:
      "CognatiQ™ is a branded extract derived from the coffee fruit, known for its cognitive-enhancing properties. It is rich in neuroprotective compounds, such as polyphenols and other antioxidants, that support brain health, improve memory, and enhance focus. CognatiQ™ is often included in nootropic supplements to promote mental clarity, support brain function, and protect against age-related cognitive decline. Its natural origin and effectiveness make it a popular ingredient for those looking to boost cognitive performance.",
  },
  {
    value: "cognizin",
    label: "Cognizin®",
    unit: "mg",
    definition:
      "Cognizin® is a patented form of Citicoline, a brain-health nutrient that supports cognitive function, memory, and mental energy. It works by enhancing the production of acetylcholine, a neurotransmitter crucial for learning and memory, and by supporting the health of brain cell membranes. Cognizin® is commonly used in nootropic supplements to boost focus, improve mental clarity, and protect against cognitive decline. Its effectiveness and high bioavailability make it a popular choice for those seeking to enhance cognitive performance.",
  },
  {
    value: "coleus",
    label: "Coleus",
    unit: "mg",
    definition:
      "Coleus, specifically Coleus forskohlii, is a plant traditionally used in Ayurvedic medicine. It is known for its active compound forskolin, which is believed to support weight loss, boost metabolism, and promote heart health. Forskolin works by increasing levels of cyclic AMP (cAMP) in cells, which can enhance fat breakdown, improve energy levels, and support muscle preservation. Coleus is commonly included in weight management and energy-boosting supplements.",
  },
  {
    value: "copper",
    label: "Copper",
    unit: "mg",
    definition:
      "Copper is an essential trace mineral that plays a vital role in numerous physiological processes, including the formation of red blood cells, maintenance of healthy bones, and support of the immune system. It is also crucial for the proper functioning of enzymes involved in energy production and antioxidant defense. Copper helps in the absorption and utilization of iron, supports cardiovascular health, and contributes to the formation of collagen, which is important for skin, bones, and connective tissues. It is commonly found in foods like shellfish, nuts, seeds, and whole grains, and is often included in multivitamins and mineral supplements to ensure adequate intake.",
  },
  {
    value: "cordyceps",
    label: "Cordyceps mycelia/sinesis Whole Mushroom",
    unit: "mg",
    definition:
      "Cordyceps mycelia/sinensis Whole Mushroom refers to the entire mushroom, including the mycelium and fruiting body, of the Cordyceps sinensis species, traditionally used in Chinese medicine. This mushroom is known for its ability to enhance energy, stamina, and endurance by boosting ATP production, which is crucial for muscle function. It also supports immune health, respiratory function, and acts as an adaptogen, helping the body manage stress. The whole mushroom is valued for its full spectrum of beneficial compounds, making it a popular ingredient in supplements aimed at boosting overall vitality, athletic performance, and immune support.",
  },
  {
    value: "covico",
    label: "Covico®",
    unit: "mg",
    definition:
      "Covico® is a branded coconut water powder known for its purity and high electrolyte content. It is derived from fresh coconut water and is rich in natural electrolytes like potassium, sodium, and magnesium, making it ideal for hydration and replenishment during and after physical activity. Covico® is often included in sports drinks, hydration supplements, and wellness products to support fluid balance, enhance endurance, and aid in recovery. It offers the benefits of coconut water in a convenient, concentrated form.",
  },
  {
    value: "creaclear",
    label: "CreaClear™",
    unit: "mg",
    definition:
      "CreaClear™ is a patented form of creatine monohydrate designed for improved solubility and stability in liquids. It provides the same benefits as traditional creatine, such as increased muscle strength, enhanced endurance, and improved recovery, but with better absorption and reduced clumping in water. CreaClear™ is often used in sports supplements to support athletic performance and muscle growth, offering a more efficient and user-friendly way to take creatine.",
  },
  {
    value: "creatineanhydrous",
    label: "Creatine Anhydrous",
    unit: "mg",
    definition:
      "Creatine Anhydrous is a highly concentrated form of creatine, containing no water molecules, which makes it more potent by weight than creatine monohydrate. It is used to increase muscle strength, power, and endurance by replenishing ATP (adenosine triphosphate) stores in muscles during high-intensity exercise. Creatine Anhydrous is commonly included in sports supplements to support muscle growth, enhance athletic performance, and improve recovery, offering a more concentrated dose of creatine compared to other forms.",
  },
  {
    value: "creatinegluconate",
    label: "Creatine Gluconate",
    unit: "mg",
    definition:
      "Creatine Gluconate is a form of creatine bonded with gluconic acid, designed to improve creatine absorption in the body. This combination allows for better solubility and enhanced uptake by muscles, making it effective for boosting strength, increasing muscle mass, and improving workout performance. Creatine Gluconate is commonly used in sports supplements for its benefits in promoting muscle energy and endurance.",
  },
  {
    value: "creatinehcl",
    label: "Creatine HCl",
    unit: "mg",
    definition:
      "Creatine HCl (Creatine Hydrochloride) is a form of creatine bonded with hydrochloric acid, known for its high solubility and absorption. This allows for effective delivery to muscles with a smaller dose compared to other forms of creatine. Creatine HCl is popular in sports supplements for enhancing strength, boosting muscle mass, and improving workout performance, while minimizing potential bloating or digestive discomfort.",
  },
  {
    value: "creatinemagna",
    label: "Creatine MagnaPower™",
    unit: "mg",
    definition:
      "Creatine MagnaPower™ is a patented form of creatine that combines creatine with magnesium, creating a chelate that may enhance absorption and utilization in the body. This combination not only provides the benefits of traditional creatine, such as improved strength, power, and endurance, but also helps support muscle hydration and ATP production, crucial for high-intensity workouts. The addition of magnesium may aid in reducing the potential for stomach discomfort sometimes associated with other forms of creatine, making it a popular choice in pre-workout supplements.",
  },
  {
    value: "creatine",
    label: "Creatine Monohydrate",
    unit: "mg",
    definition:
      "Creatine Monohydrate is the most common and well-researched form of creatine, widely used to increase muscle strength, enhance endurance, and support muscle growth. It works by replenishing ATP, the primary energy source for muscle contractions, allowing for improved performance during high-intensity exercise. Creatine Monohydrate is known for its effectiveness in building lean muscle mass and is a staple ingredient in many sports and bodybuilding supplements.",
  },
  {
    value: "creatinenitrate",
    label: "Creatine Nitrate",
    unit: "mg",
    definition:
      "Creatine Nitrate is a compound that combines creatine with nitrate, enhancing both muscle energy and blood flow. The nitrate component helps increase nitric oxide levels, improving vascularity and oxygen delivery to muscles during exercise. This dual-action formula supports increased strength, endurance, and muscle pumps, making Creatine Nitrate a popular ingredient in pre-workout supplements for enhancing overall workout performance.",
  },
  {
    value: "creatinepyrinox",
    label: "Creatine Pyrinox™",
    unit: "mg",
    definition:
      "Creatine Pyrinox™ is a specialized form of creatine combined with pyridoxine (Vitamin B6), designed to enhance creatine absorption and utilization in the body. The addition of Vitamin B6 supports energy metabolism, helping to maximize the effectiveness of creatine in boosting muscle strength, endurance, and overall workout performance. Creatine Pyrinox™ is often included in sports supplements aimed at improving physical performance and muscle recovery.",
  },
  {
    value: "creatinepyruvate",
    label: "Creatine Pyruvate",
    unit: "mg",
    definition:
      "Creatine Pyruvate is a form of creatine bonded with pyruvic acid, designed to enhance energy production and improve athletic performance. This combination allows for faster absorption and increased muscle energy, making it effective for boosting strength, endurance, and muscle recovery. Creatine Pyruvate is commonly used in sports supplements to support high-intensity workouts and promote faster recovery times.",
  },
  {
    value: "dendrobium",
    label: "Dendrobium Extract",
    unit: "mg",
    definition:
      "Dendrobium Extract is derived from the Dendrobium orchid, traditionally used in Chinese medicine for its energizing and performance-enhancing properties. It is known for boosting energy levels, improving focus, and supporting athletic performance by acting as a natural stimulant. Dendrobium Extract is often included in pre-workout supplements to enhance mental clarity, endurance, and overall workout intensity.",
  },
  {
    value: "dextrose",
    label: "Dextrose",
    unit: "mg",
    definition:
      "Dextrose is a simple sugar derived from corn, chemically identical to glucose. It is quickly absorbed by the body, making it an ideal fast-acting carbohydrate for replenishing glycogen stores and providing immediate energy. Dextrose is commonly used in sports supplements and post-workout products to support recovery, boost energy levels, and enhance nutrient absorption.",
  },
  {
    value: "dmae",
    label: "DMAE",
    unit: "mg",
    definition:
      "DMAE (Dimethylaminoethanol) is a compound naturally produced in the brain and also found in small amounts in certain foods like fish. It is known for its cognitive-enhancing properties, helping to improve memory, focus, and mental clarity. DMAE is often included in nootropic supplements for its potential to support brain health, enhance mood, and reduce signs of aging by promoting skin firmness and elasticity.",
  },
  {
    value: "dmaebitartrate",
    label: "DMAE Bitartrate",
    unit: "mg",
    definition:
      "DMAE Bitartrate is a salt form of DMAE (Dimethylaminoethanol) combined with tartaric acid to enhance stability and absorption. It is used for its cognitive-enhancing properties, helping to improve memory, focus, and mental clarity. DMAE Bitartrate is commonly found in nootropic supplements and anti-aging products, supporting brain health, mood enhancement, and skin firmness by promoting the production of acetylcholine, a key neurotransmitter involved in learning and memory.",
  },
  {
    value: "dynamine",
    label: "Dynamine™",
    unit: "mg",
    definition:
      "Dynamine™ is a branded form of methylliberine, a natural compound found in the kucha tea leaf. It is known for its stimulant properties, providing a quick and sustained boost in energy, focus, and mood without the jitters or crash often associated with caffeine. Dynamine™ works by activating dopamine receptors and inhibiting adenosine, enhancing mental clarity and physical performance. It is commonly included in pre-workout and energy supplements for those seeking increased alertness, improved mood, and enhanced workout intensity.",
  },
  {
    value: "elevatp",
    label: "elevATP®",
    unit: "mg",
    definition:
      "elevATP® is a patented blend of ancient peat and apple extract designed to naturally increase the body's production of ATP (adenosine triphosphate), the primary energy carrier in cells. By boosting ATP levels, elevATP® enhances physical performance, strength, and power output during workouts. It is commonly used in sports supplements to improve energy levels, support muscle endurance, and promote overall exercise performance without relying on stimulants.",
  },
  {
    value: "englishivy",
    label: "English Ivy Extract",
    unit: "mg",
    definition:
      "English Ivy Extract is derived from the leaves of the English ivy plant (Hedera helix). It is traditionally used for its respiratory benefits, particularly in easing congestion and supporting lung health. The extract contains saponins, which help to thin mucus and make it easier to clear the airways. English Ivy Extract is commonly included in supplements and herbal remedies aimed at supporting respiratory health and relieving symptoms of bronchitis, coughs, and other respiratory conditions.",
  },
  {
    value: "endoflo",
    label: "EndoFlo™",
    unit: "mg",
    definition:
      "EndoFlo™ is a proprietary blend of botanical extracts designed to support cardiovascular health and enhance blood flow. It typically includes ingredients known for their nitric oxide-boosting properties, which help improve circulation, oxygen delivery to muscles, and overall vascular health. EndoFlo™ is often included in pre-workout and performance supplements to enhance muscle pumps, endurance, and exercise performance by promoting optimal blood flow during physical activity.",
  },
  {
    value: "enxtra",
    label: "EnXtra®",
    unit: "mg",
    definition:
      "EnXtra® is a patented extract derived from the Alpinia galanga plant, known for its natural stimulant properties. Unlike caffeine, EnXtra® provides a smooth and sustained boost in energy and mental alertness without causing jitters or a crash. It is often used in energy supplements and nootropics to enhance focus, cognitive function, and overall performance. EnXtra® is particularly popular for its ability to amplify the effects of caffeine, making it a common addition to pre-workout and energy-boosting formulas.",
  },
  {
    value: "epicatechin",
    label: "Epicatechin",
    unit: "mg",
    definition:
      "Epicatechin is a naturally occurring flavonoid found in foods like dark chocolate and green tea. It is known for its antioxidant properties and its ability to promote muscle growth, improve vascularity, and enhance exercise performance. Epicatechin works by inhibiting myostatin, a protein that limits muscle growth, allowing for increased muscle mass and strength. It is also beneficial for heart health by improving blood flow and reducing oxidative stress. Epicatechin is commonly included in muscle-building and performance-enhancing supplements.",
  },
  {
    value: "eriajarensis",
    label: "Eria Jarensis",
    unit: "mg",
    definition:
      "Eria Jarensis is a plant extract known for its stimulant and mood-enhancing properties. It contains compounds that act as neuromodulators, increasing levels of dopamine and noradrenaline in the brain, which can boost mood, focus, and energy. Eria Jarensis is often included in pre-workout and fat-burning supplements for its ability to enhance mental clarity, improve motivation, and provide a smooth, uplifting energy boost without the crash associated with other stimulants.",
  },
  {
    value: "evodiamine",
    label: "Evodiamine",
    unit: "mg",
    definition:
      "Evodiamine is a natural compound extracted from the fruit of the Evodia rutaecarpa plant, traditionally used in Chinese medicine. It is known for its thermogenic and fat-burning properties, helping to increase body temperature and promote fat loss. Evodiamine is often included in weight loss supplements for its ability to boost metabolism, enhance energy expenditure, and support fat oxidation. Additionally, it may have mild stimulant effects, contributing to increased energy levels and focus during workouts.",
  },
  {
    value: "fitgbb",
    label: "FitGBB®",
    unit: "mg",
    definition:
      "FitGBB® is a patented form of gamma-butyrobetaine ethyl ester, a compound that naturally occurs in the body as a precursor to L-carnitine. It is known for its ability to increase the body's production of L-carnitine, which supports fat metabolism and energy production. FitGBB® is commonly included in fat-burning and weight management supplements to enhance thermogenesis, increase calorie expenditure, and promote the conversion of fat into energy, leading to improved workout performance and fat loss. It is often referred to as a 'super carnitine' booster for its effectiveness in supporting fat metabolism.",
  },
  {
    value: "fitnox",
    label: "FitNox®",
    unit: "mg",
    definition:
      "FitNox® is a patented blend of three natural ingredients—Moringa, Pomegranate, and Black Ginger—designed to boost nitric oxide (NO) levels in the body. It enhances blood flow, improves oxygen delivery to muscles, and supports overall cardiovascular health. FitNox® is commonly used in pre-workout supplements to increase muscle pumps, endurance, and exercise performance by promoting optimal circulation and vascularity during workouts. Unlike some other nitric oxide boosters, FitNox® provides a sustained increase in NO levels without relying on stimulants.",
  },
  {
    value: "folate",
    label: "Folate",
    unit: "mg",
    definition:
      "Folate, also known as Vitamin B9, is an essential nutrient that plays a crucial role in DNA synthesis, cell division, and the formation of red blood cells. It is particularly important during periods of rapid growth, such as pregnancy and fetal development, as it helps prevent neural tube defects in the developing fetus. Folate also supports overall cardiovascular health by aiding in the breakdown of homocysteine, an amino acid linked to heart disease. It is commonly found in leafy greens, legumes, and fortified foods, and is often included in multivitamins and prenatal supplements to ensure adequate intake.",
  },
  {
    value: "folicacid",
    label: "Folic Acid",
    unit: "mcg",
    definition:
      "Folic Acid is the synthetic form of Folate (Vitamin B9), commonly used in supplements and fortified foods. It plays a vital role in DNA synthesis, cell division, and the production of red blood cells. Folic Acid is especially important during pregnancy, as it helps prevent neural tube defects in the developing fetus. It also supports heart health by helping to regulate homocysteine levels in the blood. Folic Acid is widely used in multivitamins, prenatal supplements, and fortified foods to ensure sufficient intake of this essential nutrient.",
  },
  {
    value: "fruitflow",
    label: "Fruitflow®",
    unit: "mg",
    definition:
      "Fruitflow® is a patented natural extract derived from tomatoes, known for its ability to support healthy blood flow and cardiovascular health. It works by helping to maintain normal platelet function, reducing the tendency of platelets to clump together, which supports smoother blood flow. Fruitflow® is often included in supplements aimed at promoting heart health and reducing the risk of blood clots. It is recognized for its natural, gentle action on the cardiovascular system, making it a popular choice for individuals seeking to support healthy circulation without the use of synthetic blood thinners.",
  },
  {
    value: "gbb",
    label: "GBB",
    unit: "mg",
    definition:
      "Gamma-Butyrobetaine (GBB) is a naturally occurring compound in the body and a direct precursor to L-carnitine, a molecule that plays a key role in fat metabolism and energy production. By increasing the body's production of L-carnitine, Gamma-Butyrobetaine helps enhance the conversion of fat into energy, supporting fat loss and improving exercise performance. It is often included in fat-burning and pre-workout supplements for its ability to boost thermogenesis, increase calorie expenditure, and promote more efficient fat utilization during workouts.",
  },
  {
    value: "gbbgo",
    label: "GBBGO®",
    unit: "mg",
    definition:
      "GBBGO® is a patented form of Gamma-Butyrobetaine (GBB), known for its ability to significantly increase the body's production of L-carnitine. This enhanced L-carnitine production helps boost fat metabolism, leading to increased energy levels and improved fat loss. GBBGO® is often included in fat-burning and pre-workout supplements to promote thermogenesis, increase calorie burning, and support overall exercise performance. It is sometimes referred to as a 'super carnitine' precursor due to its effectiveness in enhancing the body's natural fat-burning processes.",
  },
  {
    value: "gaba",
    label: "GABA",
    unit: "mg",
    definition:
      "GABA (Gamma-Aminobutyric Acid) is a naturally occurring neurotransmitter in the brain that plays a key role in promoting relaxation and reducing anxiety. It works by inhibiting nerve transmission, which helps to calm the nervous system, reduce stress, and improve sleep quality. GABA is often included in supplements aimed at promoting relaxation, improving mood, and supporting restful sleep. It is also sometimes used in sports supplements to aid in recovery and reduce stress-related muscle tension.",
  },
  {
    value: "geranium",
    label: "Geranium Extract",
    unit: "mg",
    definition:
      "Geranium Extract, particularly from the species Pelargonium graveolens, has been traditionally used for its aromatic and therapeutic properties. In the context of sports supplements, Geranium Extract is sometimes associated with stimulant effects due to its content of compounds like DMAA (1,3-dimethylamylamine), though pure Geranium Extract does not naturally contain DMAA. It has been used to enhance energy, focus, and athletic performance. However, it's important to note that DMAA and related compounds have been banned in many countries due to safety concerns. Pure Geranium Extract without synthetic additives may be used for its mild uplifting effects and is commonly included in skincare products for its anti-inflammatory and astringent properties.",
  },
  {
    value: "glucuronolactone",
    label: "Glucuronolactone",
    unit: "mg",
    definition:
      "Glucuronolactone is a naturally occurring compound found in the human body, where it plays a role in detoxification processes by helping to remove waste products and toxins. It is often included in energy drinks and supplements for its potential to enhance mental focus, reduce fatigue, and support physical performance. Glucuronolactone is believed to improve energy levels by supporting the body's metabolism and aiding in the elimination of harmful substances, making it popular in products aimed at boosting energy and endurance.",
  },
  {
    value: "glucosylherperidin",
    label: "Glucosyl-Herperidin",
    unit: "mg",
    definition:
      "Glucosyl-Hesperidin is a bioflavonoid derived from citrus fruits, specifically modified for improved solubility and absorption. It is known for its antioxidant and anti-inflammatory properties, supporting cardiovascular health by improving circulation and reducing blood pressure. Glucosyl-Hesperidin is often included in supplements aimed at enhancing blood flow, reducing oxidative stress, and supporting overall heart health. Its enhanced bioavailability compared to regular hesperidin makes it more effective in delivering these benefits.",
  },
  {
    value: "glutamine",
    label: "Glutamine",
    unit: "mg",
    definition:
      "Glutamine is a conditionally essential amino acid that plays a crucial role in muscle recovery, immune function, and gut health. During intense exercise, glutamine levels can be depleted, leading to muscle breakdown and a weakened immune system. Supplementing with glutamine helps replenish these levels, promoting muscle repair, reducing recovery time, and supporting the body's immune response. It is commonly used in sports supplements to aid in recovery, enhance endurance, and support overall health, particularly for athletes and those engaged in intense physical activity.",
  },
  {
    value: "glutathione",
    label: "Glutathione",
    unit: "mg",
    definition:
      "Glutathione is a powerful antioxidant naturally produced in the body, composed of three amino acids: glutamine, cysteine, and glycine. It plays a critical role in protecting cells from oxidative stress, supporting detoxification processes in the liver, and maintaining overall immune function. Glutathione helps neutralize free radicals and regenerate other antioxidants, like vitamins C and E, enhancing their effectiveness. Due to its importance in cellular health, glutathione is often included in supplements aimed at boosting immune function, promoting detoxification, and supporting overall well-being, particularly in the context of anti-aging and chronic disease prevention.",
  },
  {
    value: "glutapep",
    label: "Glutapep®",
    unit: "mg",
    definition:
      "Glutapep® is a branded form of glutamine peptides, designed to enhance the absorption and effectiveness of glutamine in the body. Glutamine peptides are more stable and better absorbed than free-form glutamine, making them more effective in promoting muscle recovery, supporting immune function, and maintaining gut health. Glutapep® is often included in sports supplements to aid in muscle repair, reduce recovery time, and support overall endurance and performance, particularly for athletes and individuals engaged in intense physical activity.",
  },
  {
    value: "glycerol",
    label: "Glycerol",
    unit: "mg",
    definition:
      "Glycerol is a naturally occurring compound that acts as a humectant, drawing water into the body's tissues. In sports and fitness supplements, glycerol is often used for its ability to enhance hydration, improve endurance, and promote fuller, more hydrated muscles during workouts. By increasing fluid retention, glycerol helps maintain optimal hydration levels, which can improve exercise performance and reduce the risk of dehydration. It is commonly included in pre-workout and endurance supplements to support sustained physical activity and enhance muscle pumps.",
  },
  {
    value: "glycerolmonostearate",
    label: "Glycerol Monostearate",
    unit: "mg",
    definition:
      "Glycerol Monostearate (GMS) is a compound derived from glycerol and stearic acid, commonly used in sports supplements to enhance hydration and improve exercise performance. It works by drawing water into the muscles, increasing their volume and leading to better muscle pumps during workouts. Glycerol Monostearate also helps maintain hydration levels, which can improve endurance and reduce fatigue during prolonged physical activity. It is often included in pre-workout formulas to support sustained energy, improved vascularity, and enhanced overall workout performance.",
  },
  {
    value: "glycerpump",
    label: "GlycerPump™",
    unit: "mg",
    definition:
      "GlycerPump™ is a branded, stable form of glycerol powder, designed to enhance hydration and improve muscle pumps during workouts. Unlike traditional glycerol, GlycerPump™ is more resistant to clumping and better absorbed by the body. It works by drawing water into muscle cells, leading to increased muscle volume, better vascularity, and enhanced endurance. GlycerPump™ is commonly included in pre-workout supplements to support hydration, boost exercise performance, and provide fuller, more hydrated muscles during intense physical activity.",
  },
  {
    value: "glycersize",
    label: "GlycerSize™",
    unit: "mg",
    definition:
      "GlycerSize™ is a branded form of glycerol powder that is designed to enhance hydration and improve muscle pumps during exercise. It is a more stable and concentrated form of glycerol, known for its ability to increase water retention within muscle cells. This leads to greater muscle fullness, improved vascularity, and enhanced endurance. GlycerSize™ is often included in pre-workout supplements to support optimal hydration, boost workout performance, and help athletes achieve better muscle pumps and sustained energy during intense physical activity.",
  },
  {
    value: "glycine",
    label: "Glycine",
    unit: "mg",
    definition:
      "Glycine is a non-essential amino acid that plays a vital role in the body, particularly in the synthesis of proteins, collagen, and the neurotransmitter serotonin. It is known for its calming effects on the brain, improving sleep quality, and reducing stress. Glycine also supports muscle growth, joint health, and the detoxification process in the liver. It is often included in supplements to promote restful sleep, support muscle recovery, and enhance overall well-being. Additionally, glycine is important for maintaining healthy skin and connective tissues due to its role in collagen production.",
  },
  {
    value: "glycogenpolymers",
    label: "Glycogen Polymers",
    unit: "mg",
    definition:
      "Glycogen Polymers are complex carbohydrates that serve as a stored form of glucose in the body, primarily found in the liver and muscles. They act as a readily available energy source during prolonged or intense physical activity. In supplements, glycogen polymers are often used to help replenish muscle glycogen stores, which are depleted during exercise. This replenishment supports sustained energy, improves endurance, and aids in recovery after workouts. Glycogen polymers are commonly included in endurance and recovery supplements to help athletes maintain performance and recover more effectively after physical exertion.",
  },
  {
    value: "grainsparadise",
    label: "Grains of Paradise",
    unit: "mg",
    definition:
      "Grains of Paradise, also known as Aframomum melegueta, is a spice related to ginger, traditionally used for its medicinal properties. In the context of supplements, it is known for its thermogenic effects, helping to increase metabolism and promote fat loss. Grains of Paradise work by activating brown adipose tissue (BAT), which increases calorie burning and enhances overall energy expenditure. It is often included in fat-burning and weight management supplements to support fat loss, boost metabolism, and improve body composition.",
  },
  {
    value: "grapefruit",
    label: "Grapefruit Seed Extract",
    unit: "mg",
    definition:
      "Grapefruit Seed Extract (GSE) is a natural extract derived from the seeds, pulp, and white membranes of grapefruits. It is known for its antimicrobial properties, helping to fight bacteria, fungi, and viruses. GSE is often used in supplements to support immune health, promote digestive wellness, and act as a natural preservative. Additionally, it has antioxidant properties that help protect cells from oxidative stress. Grapefruit Seed Extract is commonly included in products aimed at supporting overall health, boosting immunity, and maintaining a healthy gut.",
  },
  {
    value: "grapeseed",
    label: "Grape Seed Extract",
    unit: "mg",
    definition:
      "Grape Seed Extract is derived from the seeds of grapes and is rich in antioxidants, particularly proanthocyanidins. It is known for its cardiovascular benefits, helping to improve blood flow, reduce blood pressure, and support overall heart health. Grape Seed Extract also has anti-inflammatory and anti-aging properties, making it beneficial for skin health and protecting against oxidative stress. It is commonly included in supplements aimed at promoting cardiovascular health, supporting healthy aging, and enhancing skin health.",
  },
  {
    value: "greatergalangal",
    label: "Greater Galangal Rhizome Extract",
    unit: "mg",
  },
  {
    value: "greencoffee",
    label: "Green Coffee Extract",
    unit: "mg",
    definition:
      "Green Coffee Extract is derived from unroasted coffee beans and is rich in chlorogenic acids, which are believed to have antioxidant and anti-inflammatory effects. It is commonly used in weight management supplements due to its potential to boost metabolism, reduce fat absorption, and support healthy blood sugar levels. Green Coffee Extract is also known for providing a mild energy boost without the jittery effects often associated with regular coffee. It is often included in supplements aimed at weight loss, metabolic support, and overall health.",
  },
  {
    value: "greentealeaf",
    label: "Green Tea Leaf Extract",
    unit: "mg",
    definition:
      "Green Tea Leaf Extract is derived from the leaves of the Camellia sinensis plant and is rich in antioxidants, particularly catechins like EGCG (epigallocatechin gallate). It is known for its numerous health benefits, including supporting weight loss, boosting metabolism, and improving cardiovascular health. Green Tea Leaf Extract also has anti-inflammatory and anti-aging properties, making it beneficial for overall wellness and skin health. It is commonly included in supplements aimed at promoting fat loss, enhancing energy levels, and protecting against oxidative stress.",
  },
  {
    value: "ginseng",
    label: "Ginseng Extract",
    unit: "mg",
    definition:
      "Ginseng Extract is derived from the roots of the ginseng plant, typically either Asian (Panax ginseng) or American (Panax quinquefolius). It is widely known for its adaptogenic properties, helping the body manage stress, improve energy levels, and enhance mental clarity. Ginseng Extract is also used to support immune function, boost physical performance, and improve overall well-being. It is commonly included in supplements aimed at reducing fatigue, promoting cognitive function, and supporting immune health.",
  },
  {
    value: "guarana",
    label: "Guarana Extract",
    unit: "mg",
    definition:
      "Guarana Extract is derived from the seeds of the guarana plant (Paullinia cupana), native to the Amazon basin. It is known for its high caffeine content, which provides a natural energy boost, enhances mental alertness, and improves focus. Guarana is often used in energy supplements and weight loss products due to its ability to increase metabolism, reduce fatigue, and suppress appetite. Additionally, Guarana Extract contains antioxidants that support overall health and help protect against oxidative stress. It is commonly included in pre-workout formulas, energy drinks, and fat-burning supplements.",
  },
  {
    value: "halostachine",
    label: "Halostachine",
    unit: "mg",
    definition:
      "Halostachine is a naturally occurring alkaloid similar in structure to ephedrine. It acts as a stimulant, increasing energy levels, enhancing focus, and promoting fat loss by stimulating the release of norepinephrine, which boosts metabolism and lipolysis (fat breakdown). Halostachine is often included in pre-workout and fat-burning supplements for its ability to increase energy expenditure, improve physical performance, and support weight loss. Its effects are generally milder than those of ephedrine, making it a popular choice for those seeking a stimulant with fewer side effects.",
  },
  {
    value: "halostachinehydro",
    label: "Halostachine Hydrochloride",
    unit: "mcg",
    definition:
      "Halostachine Hydrochloride is the hydrochloride salt form of Halostachine, an alkaloid similar to ephedrine. This form enhances the stability and absorption of Halostachine in the body. Halostachine Hydrochloride acts as a stimulant, boosting energy, focus, and fat metabolism by increasing the release of norepinephrine. It is commonly used in pre-workout and fat-burning supplements to enhance physical performance, promote fat loss, and provide a smooth energy boost. The hydrochloride form ensures more efficient delivery and effectiveness compared to its base form.",
  },
  {
    value: "hmbca",
    label: "HMB-Ca",
    unit: "mg",
    definition:
      "HMB-Ca (Calcium β-Hydroxy β-Methylbutyrate) is a calcium salt form of HMB, a metabolite of the amino acid leucine. It is known for its ability to support muscle growth, reduce muscle breakdown, and enhance recovery after exercise. HMB-Ca is often used in sports supplements to promote muscle strength, increase lean body mass, and improve overall athletic performance. The addition of calcium in HMB-Ca enhances its stability and absorption, making it an effective choice for those looking to maximize muscle preservation and recovery during intense training.",
  },
  {
    value: "hmbfa",
    label: "HMB-FA",
    unit: "mg",
    definition:
      "HMB-FA (Free Acid β-Hydroxy β-Methylbutyrate) is a rapidly absorbed form of HMB, a metabolite of the amino acid leucine. It is known for its ability to quickly enter the bloodstream, providing faster effects on muscle preservation and recovery compared to other forms of HMB. HMB-FA helps reduce muscle breakdown, promote muscle growth, and enhance recovery after intense exercise. It is commonly used in sports supplements to support strength, increase lean muscle mass, and improve overall athletic performance, particularly during periods of heavy training or caloric deficit.",
  },
  {
    value: "hordenine",
    label: "Hordenine",
    unit: "mg",
    definition:
      "Hordenine is a naturally occurring alkaloid found in plants like barley. It is known for its stimulant properties, which can boost energy levels, enhance focus, and promote fat loss. Hordenine works by increasing the release of norepinephrine, a neurotransmitter that stimulates metabolism and fat breakdown. It is often included in pre-workout and fat-burning supplements to support weight loss, improve mental clarity, and provide a smooth energy boost without the jitters associated with stronger stimulants.",
  },
  {
    value: "huperzinea",
    label: "Huperzine-A",
    unit: "mcg",
    definition:
      "Huperzine-A is a natural compound extracted from the Chinese club moss plant (Huperzia serrata). It is known for its ability to enhance cognitive function by inhibiting the enzyme acetylcholinesterase, which breaks down the neurotransmitter acetylcholine. By increasing acetylcholine levels, Huperzine-A supports improved memory, learning, and mental clarity. It is commonly used in nootropic supplements to boost cognitive performance, enhance focus, and support overall brain health. Additionally, Huperzine-A is being studied for its potential benefits in managing neurodegenerative conditions like Alzheimer's disease.",
  },
  {
    value: "hydromax",
    label: "HydroMax™",
    unit: "mg",
    definition:
      "HydroMax™ is a highly concentrated, stable form of glycerol powder, designed to enhance hydration and improve muscle pumps during exercise. It works by drawing water into muscle cells, increasing their volume and leading to better vascularity and endurance. HydroMax™ is commonly included in pre-workout supplements to support optimal hydration, boost exercise performance, and provide fuller, more hydrated muscles during intense physical activity. Its stability and high glycerol content make it a popular choice for athletes seeking enhanced muscle pumps and sustained energy.",
  },
  {
    value: "hydroprime",
    label: "HydroPrime®",
    unit: "mg",
    definition:
      "HydroPrime® is a branded, stable form of glycerol powder, specifically designed to enhance hydration and improve muscle pumps during workouts. It works by increasing water retention within muscle cells, leading to greater muscle fullness, improved vascularity, and enhanced endurance. HydroPrime® is commonly included in pre-workout supplements to support optimal hydration, boost exercise performance, and provide a fuller, more hydrated appearance during intense physical activity. Its stability and effectiveness make it a popular choice for those looking to maximize muscle pumps and workout intensity.",
  },
  {
    value: "fivehydrox",
    label: "5-Hydroxytryptophan",
    unit: "mg",
    definition:
      "5-Hydroxytryptophan (5-HTP) is a naturally occurring amino acid and chemical precursor to serotonin, a neurotransmitter that plays a key role in regulating mood, sleep, and appetite. 5-HTP is commonly used in supplements to support mental well-being, reduce symptoms of depression and anxiety, promote better sleep, and aid in weight management by reducing cravings. By increasing serotonin levels in the brain, 5-HTP can help improve mood, enhance relaxation, and contribute to overall emotional balance. It is often included in mood-enhancing and sleep support supplements.",
  },
  {
    value: "immunolin",
    label: "ImmunoLin®",
    unit: "mg",
    definition:
      "ImmunoLin® is a high-quality, concentrated source of immunoglobulins, which are antibodies derived from bovine colostrum. These immunoglobulins help support the immune system by binding to and neutralizing pathogens, such as bacteria and viruses, in the gut. ImmunoLin® is commonly included in supplements aimed at promoting gut health, enhancing immune function, and supporting overall well-being. It is particularly beneficial for maintaining a healthy gut barrier and protecting against infections and inflammatory conditions in the digestive tract.",
  },
  {
    value: "inositol",
    label: "Inositol",
    unit: "mg",
    definition:
      "Inositol is a naturally occurring carbohydrate that plays a crucial role in cell signaling and the regulation of various hormones, including insulin. It is often used in supplements to support mental health, particularly in reducing anxiety and improving mood. Inositol is also known for its benefits in promoting healthy ovarian function and supporting fertility, especially in women with polycystic ovary syndrome (PCOS). Additionally, it is used to enhance insulin sensitivity and support metabolic health. Inositol is commonly included in supplements aimed at improving mental well-being, hormonal balance, and metabolic function.",
  },
  {
    value: "iodine",
    label: "Iodine",
    unit: "mcg",
    definition:
      "Iodine is an essential trace mineral vital for the production of thyroid hormones, which regulate metabolism, growth, and development. It plays a crucial role in maintaining proper thyroid function and is necessary for brain development during pregnancy and early life. Iodine is commonly found in iodized salt, seafood, and dairy products, and is often included in supplements to ensure adequate intake, especially in regions where iodine deficiency is common. Adequate iodine levels are important for preventing thyroid disorders such as goiter and hypothyroidism, and for supporting overall metabolic health.",
  },
  {
    value: "iron",
    label: "Iron",
    unit: "mg",
    definition:
      "Iron is an essential mineral that plays a key role in the production of hemoglobin, a protein in red blood cells responsible for transporting oxygen throughout the body. It is crucial for maintaining energy levels, supporting immune function, and promoting healthy cognitive development. Iron is commonly found in foods like red meat, beans, and fortified cereals, and is often included in supplements to prevent or treat iron deficiency anemia. Adequate iron intake is particularly important for women of childbearing age, pregnant women, and individuals with increased iron needs or limited dietary intake.",
  },
  {
    value: "isoleucine",
    label: "Isoleucine",
    unit: "mg",
    definition:
      "Isoleucine is one of the three branched-chain amino acids (BCAAs), alongside leucine and valine, that play a crucial role in muscle metabolism. It supports muscle repair, growth, and recovery after exercise. Isoleucine also helps regulate blood sugar levels by assisting in glucose uptake into cells and promotes energy production during physical activity. It is commonly included in BCAA supplements and protein powders to enhance athletic performance, reduce muscle soreness, and support muscle preservation during intense training or dieting.",
  },
  {
    value: "ivy",
    label: "Ivy Extract",
    unit: "mg",
    definition:
      "Ivy Extract, typically derived from the leaves of the Hedera helix plant, is known for its respiratory benefits. It helps to soothe coughs, reduce bronchial inflammation, and improve breathing by thinning mucus, making it easier to clear the airways. Ivy Extract is commonly included in herbal remedies and cough syrups to support respiratory health, alleviate symptoms of bronchitis, and relieve congestion. Its natural expectorant properties make it a popular choice for supporting lung health and easing respiratory discomfort.",
  },
  {
    value: "nisopropyl",
    label: "Isopropylnorsynephrine",
    unit: "mg",
    definition:
      "Isopropylnorsynephrine is a synthetic compound similar to synephrine, a naturally occurring alkaloid found in bitter orange. It is known for its stimulant and thermogenic properties, which help boost metabolism, increase energy levels, and promote fat loss. Isopropylnorsynephrine works by stimulating beta-adrenergic receptors, leading to enhanced lipolysis (fat breakdown) and increased calorie expenditure. It is often included in fat-burning and pre-workout supplements to support weight loss, improve focus, and enhance athletic performance. Its effects are generally considered milder than those of stronger stimulants, making it a popular choice for those seeking a smoother energy boost.",
  },
  {
    value: "juglan",
    label: "Juglans Whole Fruit",
    unit: "mg",
    definition:
      "Juglans Whole Fruit refers to the entire fruit of the walnut tree (Juglans regia), including the hull, shell, and kernel. It is often used in traditional medicine and supplements for its rich content of nutrients, including healthy fats, antioxidants, and bioactive compounds. The extract from Juglans Whole Fruit is known for its potential to support brain health, improve cognitive function, and promote heart health due to its high levels of omega-3 fatty acids and polyphenols. In some formulations, it may also be included for its potential thermogenic and stimulant properties, particularly in pre-workout and fat-burning supplements, although these effects are more commonly associated with specific compounds extracted from the fruit.",
  },
  {
    value: "juniper",
    label: "Juniper Extract",
    unit: "mg",
    definition:
      "Juniper Extract is derived from the berries of the juniper plant (Juniperus communis) and is known for its diuretic, antioxidant, and anti-inflammatory properties. Traditionally, it has been used to support kidney and urinary tract health by promoting the elimination of excess fluids and toxins. Juniper Extract is also used in supplements for its potential to reduce bloating, improve digestion, and support overall detoxification. Additionally, it has antimicrobial properties that may help in fighting infections. Juniper Extract is commonly included in detox, weight management, and digestive health supplements.",
  },
  {
    value: "kanna",
    label: "Kanna Extract",
    unit: "mg",
    definition:
      "Kanna Extract, derived from the Sceletium tortuosum plant native to South Africa, is known for its mood-enhancing and stress-relieving properties. It has been traditionally used by indigenous peoples for its calming effects and ability to elevate mood. Kanna works by inhibiting the reuptake of serotonin, a neurotransmitter associated with mood regulation, leading to improved emotional well-being and reduced anxiety. Kanna Extract is often included in supplements aimed at promoting relaxation, reducing stress, and enhancing mood without the sedative effects associated with some other herbal remedies. It is also sometimes used in nootropic formulations for its potential cognitive benefits.",
  },
  {
    value: "kelp",
    label: "Kelp",
    unit: "mg",
    definition:
      "Kelp is a type of large, brown seaweed that is rich in essential nutrients, particularly iodine, which is crucial for maintaining healthy thyroid function. In addition to iodine, kelp contains vitamins, minerals, and antioxidants that support overall health. Kelp is commonly used in supplements to promote thyroid health, support metabolism, and provide a natural source of trace minerals. Its high iodine content makes it particularly beneficial for individuals with low iodine levels or those looking to support healthy thyroid hormone production. Kelp is also valued for its potential detoxifying properties and its ability to support healthy skin and hair.",
  },
  {
    value: "kigelia",
    label: "Kigelia Africana",
    unit: "mg",
    definition:
      "Kigelia Africana, commonly known as the sausage tree, is a plant native to Africa and is known for its medicinal and cosmetic uses. Extracts from the fruit, bark, and leaves of Kigelia Africana are rich in bioactive compounds such as flavonoids, saponins, and iridoids, which have anti-inflammatory, antimicrobial, and antioxidant properties. It is often used in skincare products for its ability to improve skin firmness, reduce the appearance of blemishes, and treat conditions like eczema and psoriasis. Kigelia Africana is also traditionally used in herbal medicine to address various health issues, including infections and inflammation.",
  },
  {
    value: "koreand",
    label: "Korean Ginseng Extract",
    unit: "mg",
    definition:
      "Korean Ginseng Extract, derived from the root of Panax ginseng, is renowned for its adaptogenic properties, helping the body manage stress and enhance overall energy levels. It is often used to improve mental clarity, boost immune function, and increase physical stamina. Korean Ginseng is also known for its potential to enhance cognitive performance, support heart health, and promote a sense of well-being. It is commonly included in supplements aimed at reducing fatigue, improving focus, and supporting overall vitality.",
  },
  {
    value: "leucine",
    label: "Leucine",
    unit: "mg",
    definition:
      "Leucine is one of the three branched-chain amino acids (BCAAs), alongside isoleucine and valine, and plays a critical role in muscle protein synthesis. It is the most potent of the BCAAs in stimulating muscle growth and repair, making it essential for athletes and individuals engaged in intense physical activity. Leucine helps to promote muscle recovery, reduce muscle breakdown, and enhance overall muscle mass. It is commonly included in BCAA supplements, protein powders, and muscle-building formulas to support muscle growth, improve workout performance, and aid in recovery after exercise.",
  },
  {
    value: "lionize",
    label: "Lionize™",
    unit: "mg",
    definition:
      "Lionize™ is a branded supplement ingredient known for its potential to enhance cognitive function, focus, and mental clarity. It typically combines natural nootropic compounds designed to support brain health, improve mood, and boost mental performance. Lionize™ is often included in nootropic and pre-workout supplements for its ability to increase energy, motivation, and cognitive endurance, making it popular among individuals seeking to enhance both mental and physical performance.",
  },
  {
    value: "lionsmane",
    label: "Lion's Mane",
    unit: "mg",
    definition:
      "Lion's Mane (Hericium erinaceus) is a medicinal mushroom known for its potential cognitive and neurological benefits. It is recognized for its ability to support brain health by promoting the production of nerve growth factor (NGF), which is essential for the growth, maintenance, and survival of nerve cells. Lion's Mane is commonly used in nootropic supplements to enhance memory, focus, and mental clarity, as well as to support overall cognitive function and protect against age-related cognitive decline. Additionally, it has anti-inflammatory and antioxidant properties that contribute to overall well-being.",
  },
  {
    value: "magnesium",
    label: "Magnesium",
    unit: "mg",
    definition:
      "Magnesium is an essential mineral that plays a vital role in numerous bodily functions, including muscle and nerve function, energy production, and bone health. It is involved in over 300 enzymatic reactions, helping to regulate blood pressure, support immune function, and maintain normal muscle contractions, including the heart. Magnesium is also important for protein synthesis and glucose metabolism. It is commonly found in foods like leafy greens, nuts, seeds, and whole grains, and is often included in supplements to support muscle relaxation, reduce cramps, improve sleep, and promote overall cardiovascular health.",
  },
  {
    value: "magnesiumbisglycinate",
    label: "Magnesium Bisglycinate",
    unit: "mg",
    definition:
      "Magnesium Bisglycinate is a chelated form of magnesium, where magnesium is bound to the amino acid glycine. This form is known for its high bioavailability and gentle effect on the digestive system, making it less likely to cause digestive discomfort compared to other forms of magnesium. Magnesium Bisglycinate is commonly used in supplements to support muscle relaxation, improve sleep quality, reduce anxiety, and promote overall cardiovascular health. Its enhanced absorption makes it an effective option for individuals looking to increase their magnesium intake for better overall health and well-being.",
  },
  {
    value: "magnesiumcitrate",
    label: "Magnesium Citrate",
    unit: "mg",
    definition:
      "Magnesium Citrate is a highly absorbable form of magnesium, commonly used to support digestive health and relieve constipation due to its mild laxative effect. It is also used to support muscle and nerve function, energy production, and overall cardiovascular health. Magnesium Citrate is favored for its high bioavailability, meaning it is easily absorbed by the body. It is often included in supplements to help maintain normal muscle contractions, support bone health, and promote relaxation, making it a popular choice for individuals looking to increase their magnesium intake for both digestive and general health benefits.",
  },
  {
    value: "malicacid",
    label: "Malic Acid",
    unit: "mg",
    definition:
      "Malic Acid is a naturally occurring organic acid found in many fruits, especially apples. It plays a key role in the body's energy production cycle, known as the Krebs cycle, where it helps convert food into energy. Malic Acid is often included in supplements to boost energy levels, reduce muscle fatigue, and improve overall exercise performance. It is also used to enhance the absorption of other nutrients and to add a tart flavor to food and beverages. Additionally, Malic Acid is sometimes used in skincare products for its exfoliating properties, helping to improve skin texture and appearance.",
  },
  {
    value: "mango",
    label: "Mango",
    unit: "mg",
    definition:
      "Mango is a tropical fruit known for its rich content of vitamins, minerals, and antioxidants. It is particularly high in vitamin C, which supports immune function and skin health, and vitamin A, which is important for vision and overall skin health. Mangoes are also a good source of dietary fiber, which aids digestion and promotes gut health. In addition to its nutritional benefits, mango extract is sometimes used in supplements and skincare products for its antioxidant properties, helping to protect the body from oxidative stress and support overall well-being.",
  },
  {
    value: "methylcobalamin",
    label: "Methylcobalamin",
    unit: "mcg",
    definition:
      "Methylcobalamin is a bioactive form of vitamin B12, which is essential for various bodily functions, including the production of red blood cells, DNA synthesis, and neurological health. Unlike other forms of vitamin B12, methylcobalamin is already in an active form, making it more readily utilized by the body. It plays a critical role in maintaining healthy nerve cells and supporting cognitive function. Methylcobalamin is commonly used in supplements to support energy levels, improve mood, and promote overall neurological health, especially in individuals with B12 deficiencies or those at risk of deficiency, such as vegetarians and older adults.",
  },
  {
    value: "methylliberine",
    label: "Methylliberine",
    unit: "mg",
    definition:
      "Methylliberine, commonly known by the brand name Dynamine™, is a natural alkaloid found in the kucha tea leaf. It is known for its stimulant properties, providing a quick and sustained boost in energy, mental clarity, and focus without the jittery side effects often associated with caffeine. Methylliberine works by activating dopamine receptors and inhibiting adenosine, which enhances mood, motivation, and cognitive performance. It is commonly included in pre-workout and nootropic supplements for those seeking increased alertness, improved mental performance, and enhanced physical endurance during workouts.",
  },
  {
    value: "mitoburn",
    label: "MitoBurn®",
    unit: "mg",
    definition:
      "MitoBurn® is a branded form of L-β-aminoisobutyric acid (BAIBA), a naturally occurring molecule produced during exercise. It is known for its role in promoting fat metabolism and supporting healthy body composition. MitoBurn® helps convert white fat, which stores energy, into brown fat, which burns energy, thus enhancing fat loss and increasing calorie expenditure. It is commonly included in fat-burning and weight management supplements to support metabolism, improve exercise performance, and promote overall energy levels. MitoBurn® is popular among athletes and fitness enthusiasts looking to maximize the benefits of their workouts and improve body composition.",
  },
  {
    value: "mucunapruriens",
    label: "Mucuna Pruriens Powder",
    unit: "mg",
    definition:
      "Mucuna Pruriens Powder is derived from the seeds of the Mucuna pruriens plant, also known as velvet bean. It is rich in L-DOPA, a direct precursor to the neurotransmitter dopamine, which plays a crucial role in mood regulation, motivation, and overall mental well-being. Mucuna Pruriens is often used in supplements to enhance mood, reduce stress, and support cognitive function. Additionally, it is valued for its potential benefits in promoting sexual health, boosting energy levels, and supporting muscle growth and recovery. Mucuna Pruriens Powder is commonly included in nootropic and adaptogenic supplements for its ability to improve mental clarity, mood, and physical performance.",
  },
  {
    value: "mushroomcomplex",
    label: "Mushroom Complex",
    unit: "mg",
    definition:
      "Mushroom Complex typically refers to a blend of various medicinal mushrooms, each selected for its unique health benefits. Common mushrooms included in such complexes are Reishi, Shiitake, Maitake, Lion's Mane, and Cordyceps. These mushrooms are known for their immune-boosting properties, antioxidant effects, and ability to support cognitive function, energy levels, and overall well-being. A Mushroom Complex is often included in supplements to promote immune health, enhance mental clarity, support energy and endurance, and provide a natural source of vitamins, minerals, and bioactive compounds. These complexes are popular in adaptogenic formulas designed to help the body adapt to stress and maintain balance.",
  },
  {
    value: "nadh",
    label: "NADH",
    unit: "mg",
    definition:
      "NADH (Nicotinamide Adenine Dinucleotide + Hydrogen) is a coenzyme found in all living cells that plays a crucial role in cellular energy production. It is involved in the process of converting nutrients into energy (ATP) within the mitochondria, making it essential for maintaining energy levels, cognitive function, and overall cellular health. NADH is often used in supplements to boost energy, enhance mental clarity and focus, and support healthy aging by promoting optimal mitochondrial function. It is also believed to have antioxidant properties, helping to protect cells from oxidative stress and support overall vitality. NADH is particularly popular among individuals looking to improve energy levels, combat fatigue, and enhance cognitive performance.",
  },
  {
    value: "nuerofactor",
    label: "NeuroFactor®",
    unit: "mg",
    definition:
      "NeuroFactor® is a patented, natural extract derived from the whole fruit of the coffee plant (Coffea arabica), including the bean, pulp, and skin. It is known for its ability to support brain health by increasing levels of brain-derived neurotrophic factor (BDNF), a protein that plays a key role in neurogenesis, cognitive function, and overall brain health. NeuroFactor® is commonly included in nootropic supplements to enhance mental clarity, improve focus, and support memory. It is popular for its potential to boost cognitive performance naturally, without the need for stimulants.",
  },
  {
    value: "nuerorush",
    label: "NeuroRush™",
    unit: "mg",
    definition:
      "NeuroRush™ is a branded supplement formulation designed to enhance cognitive function, focus, and mental clarity. It typically combines a blend of nootropics, stimulants, and adaptogens to boost energy levels, improve concentration, and support overall brain health. NeuroRush™ is often used as a pre-workout or cognitive enhancement supplement, providing users with increased mental alertness, motivation, and endurance during both physical and mental tasks. It's popular among individuals seeking to maximize their performance in demanding activities, whether in the gym, at work, or in academic settings.",
  },
  {
    value: "naringin",
    label: "Naringin Extract",
    unit: "mg",
    definition:
      "Naringin Extract is derived from grapefruit and other citrus fruits, known for its bioflavonoid content. Naringin is valued for its antioxidant, anti-inflammatory, and cholesterol-lowering properties. It helps protect cells from oxidative stress, supports cardiovascular health by improving lipid profiles, and may enhance the effectiveness of other supplements by inhibiting certain enzymes that break down drugs and nutrients in the body. Naringin is often included in supplements aimed at supporting heart health, boosting immune function, and enhancing the bioavailability of other compounds.",
  },
  {
    value: "seasalt",
    label: "Natural Sea Salt",
    unit: "mg",
    definition:
      "Natural Sea Salt is a type of salt harvested from evaporated seawater, retaining trace minerals like magnesium, calcium, and potassium, which contribute to its distinct flavor and potential health benefits. Unlike refined table salt, natural sea salt is less processed and may contain a variety of minerals that support electrolyte balance, hydration, and overall health. It is often used in cooking for its rich flavor and in supplements to provide essential minerals that help maintain proper fluid balance, support nerve function, and promote healthy muscle contractions. Natural Sea Salt is a popular choice for those looking to use a more natural form of salt in their diet.",
  },
  {
    value: "niacin",
    label: "Niacin",
    unit: "mg",
    definition:
      "Niacin, also known as Vitamin B3, is an essential nutrient that plays a key role in energy production, DNA repair, and the maintenance of healthy skin, nerves, and digestive systems. It helps convert food into energy by assisting enzymes involved in metabolism. Niacin is also known for its ability to improve cholesterol levels by raising HDL (good) cholesterol and lowering LDL (bad) cholesterol and triglycerides. In supplements, niacin is often used to support cardiovascular health, boost energy levels, and promote overall wellness. It can cause a temporary flushing effect, which is a warm, tingling sensation on the skin, a common side effect known as the 'niacin flush'.",
  },
  {
    value: "nitrosigne",
    label: "Nitrosigine®",
    unit: "mg",
    definition:
      "Nitrosigine® is a patented complex of bonded arginine silicate, designed to enhance nitric oxide production, which improves blood flow and vascularity during exercise. It is commonly used in pre-workout supplements to increase muscle pumps, boost endurance, and enhance overall athletic performance. Nitrosigine® also supports cognitive function by improving blood flow to the brain, making it beneficial for both mental and physical performance. Its effects are longer-lasting compared to standard arginine supplements, providing sustained benefits throughout your workout.",
  },
  {
    value: "noogandha",
    label: "NooGandha®",
    unit: "mg",
    definition:
      "NooGandha® is a branded form of Ashwagandha, specifically formulated to enhance cognitive function, reduce stress, and improve mental clarity. It combines the traditional adaptogenic benefits of Ashwagandha with a focus on nootropic effects, making it effective in reducing cortisol levels, boosting mood, and enhancing focus and memory. NooGandha® is commonly included in nootropic supplements aimed at supporting overall brain health, stress management, and cognitive performance, offering both mental and physical benefits for users seeking to improve their daily well-being and productivity.",
  },
  {
    value: "noolvl",
    label: "nooLVL®",
    unit: "mg",
    definition:
      "nooLVL® is a patented complex of bonded arginine silicate and inositol, specifically designed to enhance cognitive performance, particularly in gamers and individuals engaged in activities requiring sustained focus and mental energy. It works by increasing nitric oxide production, which improves blood flow to the brain, leading to enhanced mental clarity, quicker reaction times, and improved focus. nooLVL® is commonly included in gaming supplements and nootropic formulas to support cognitive function, reduce mental fatigue, and boost overall performance during mentally demanding tasks. Its effects are fast-acting and sustained, making it ideal for both short bursts of intense focus and prolonged periods of mental exertion.",
  },
  {
    value: "noopept",
    label: "Noopept",
    unit: "mg",
    definition:
      "Noopept is a synthetic nootropic compound known for its potential cognitive-enhancing effects. It is believed to work by increasing the availability of neurotransmitters like acetylcholine, glutamate, and possibly dopamine, which are crucial for learning, memory, and overall cognitive function. Noopept is often used to improve memory retention, enhance focus, and support mental clarity. Additionally, it may have neuroprotective properties, helping to protect the brain from oxidative stress and damage. Noopept is popular in nootropic supplements for those looking to boost cognitive performance, particularly in areas of memory and concentration. Its effects are typically fast-acting and longer-lasting compared to other nootropics.",
  },
  {
    value: "norvaline",
    label: "Norvaline",
    unit: "mg",
    definition:
      "Norvaline is a derivative of the amino acid valine and is commonly used in supplements for its potential to enhance nitric oxide production. By inhibiting the enzyme arginase, which breaks down arginine, Norvaline helps maintain higher levels of arginine in the body, thereby boosting nitric oxide synthesis. This leads to improved blood flow, better muscle pumps, and enhanced nutrient delivery during exercise. Norvaline is often included in pre-workout supplements to support vascularity, endurance, and overall exercise performance. It is particularly valued by athletes and bodybuilders looking to maximize muscle pumps and workout efficiency.",
  },
  {
    value: "nutricog",
    label: "Nutricog®",
    unit: "mg",
    definition:
      "Nutricog® is a branded supplement ingredient designed to support cognitive function, memory, and mental clarity. It typically combines a blend of natural nootropics and adaptogens aimed at enhancing brain health and improving focus, learning, and cognitive endurance. Nutricog® is often included in nootropic formulas and brain health supplements for individuals seeking to boost their cognitive performance, particularly in demanding mental tasks or stressful situations. The ingredient is marketed for its ability to provide mental clarity and sustained cognitive support without relying on stimulants.",
  },
  {
    value: "oliveleaf",
    label: "Olive Leaf Extract",
    unit: "mg",
    definition:
      "Olive Leaf Extract is derived from the leaves of the olive tree (Olea europaea) and is known for its powerful antioxidant and anti-inflammatory properties. It contains a compound called oleuropein, which is believed to contribute to its health benefits, including supporting cardiovascular health, boosting the immune system, and promoting healthy blood pressure levels. Olive Leaf Extract is also used for its potential to fight infections, improve skin health, and protect against oxidative stress. It is commonly included in supplements aimed at supporting heart health, immune function, and overall wellness.",
  },
  {
    value: "ornithinehcl",
    label: "Ornithine HCl",
    unit: "mg",
    definition:
      "Ornithine HCl (Ornithine Hydrochloride) is a form of the amino acid ornithine bound to hydrochloric acid, which enhances its stability and absorption. Ornithine plays a key role in the urea cycle, helping to remove excess ammonia from the body, which can reduce fatigue and improve recovery after exercise. It is commonly used in supplements to support liver health, promote detoxification, and enhance athletic performance by reducing muscle fatigue and improving endurance. Ornithine HCl is often included in pre-workout and recovery supplements to help athletes and fitness enthusiasts achieve better workout results and faster recovery.",
  },
  {
    value: "ornithineakg",
    label: "Ornithine AKG",
    unit: "mg",
    definition:
      "Ornithine AKG (Ornithine Alpha-Ketoglutarate) is a combination of the amino acid ornithine and alpha-ketoglutarate, a key molecule in the Krebs cycle, which is involved in energy production. This combination is designed to enhance the effects of ornithine, particularly in supporting muscle growth, improving endurance, and aiding in recovery. Ornithine AKG helps reduce muscle fatigue by facilitating the removal of excess ammonia from the body during intense exercise, which can delay the onset of muscle fatigue and improve workout performance. It is commonly included in pre-workout and recovery supplements to support athletic performance, muscle recovery, and overall energy levels.",
  },
  {
    value: "oxygold",
    label: "OxyGold®",
    unit: "mg",
    definition:
      "OxyGold® is a branded form of fulvic acid, a natural compound derived from decomposed plant material. It is known for its potent antioxidant properties and ability to enhance nutrient absorption at the cellular level. OxyGold® helps improve energy production, supports detoxification, and boosts overall vitality by facilitating the transport of nutrients into cells and the removal of toxins. It is often included in supplements aimed at enhancing energy levels, promoting cellular health, and supporting overall well-being. OxyGold® is particularly valued for its role in optimizing the effectiveness of other nutrients in the body.",
  },
  {
    value: "oxystorm",
    label: "Oxystorm®",
    unit: "mg",
    definition:
      "Oxystorm® is a branded, standardized extract of red spinach (Amaranthus dubius), known for its exceptionally high nitrate content. Nitrates are converted into nitric oxide in the body, which helps improve blood flow, enhance oxygen delivery to muscles, and boost overall athletic performance. Oxystorm® is commonly used in pre-workout supplements to increase muscle pumps, endurance, and exercise efficiency. Its high nitrate content makes it one of the most potent natural sources of nitrates, providing a sustained release of nitric oxide for prolonged performance benefits. It is particularly popular among athletes and fitness enthusiasts looking to maximize their workout results.",
  },
  {
    value: "palatinose",
    label: "Palatinose™",
    unit: "mg",
    definition:
      "Palatinose™, also known as isomaltulose, is a naturally occurring carbohydrate derived from beet sugar. It is a low-glycemic index carbohydrate, meaning it provides a slow and sustained release of energy without causing rapid spikes in blood sugar levels. Palatinose™ is often used in sports and energy supplements to provide a steady source of energy during prolonged physical activity, helping to maintain endurance and reduce fatigue. It is also valued for its dental health benefits, as it is less likely to cause tooth decay compared to other sugars. Palatinose™ is ideal for athletes and individuals looking for a balanced, long-lasting energy source.",
  },
  {
    value: "palmitoyl",
    label: "Palmitoylethanolamide",
    unit: "mg",
    definition:
      "Palmitoylethanolamide (PEA) is a naturally occurring fatty acid amide that plays a key role in the body's response to pain and inflammation. It is known for its anti-inflammatory and analgesic properties, making it effective in reducing chronic pain, particularly in conditions like neuropathy, arthritis, and other inflammatory disorders. PEA works by modulating the immune response and interacting with cannabinoid receptors, helping to reduce pain and promote healing. It is often included in supplements aimed at supporting pain management, reducing inflammation, and improving overall comfort and quality of life.",
  },
  {
    value: "panax",
    label: "Panax Notoginseng",
    unit: "mg",
    definition:
      "Panax Notoginseng, also known as Notoginseng or Sanqi, is a traditional Chinese herb highly regarded for its medicinal properties, particularly in promoting cardiovascular health and improving blood circulation. It is a close relative of Panax Ginseng and contains active compounds called ginsenosides, which are known for their anti-inflammatory, antioxidant, and adaptogenic effects. Panax Notoginseng is commonly used to support heart health, reduce blood pressure, enhance blood flow, and accelerate the healing of wounds. It is also used to boost energy levels, reduce stress, and improve overall vitality. This herb is often included in supplements aimed at promoting cardiovascular health, improving circulation, and supporting overall well-being.",
  },
  {
    value: "pantothenic",
    label: "Pantothenic Acid",
    unit: "mg",
    definition:
      "Pantothenic Acid, also known as Vitamin B5, is an essential water-soluble vitamin that plays a key role in energy metabolism by helping to convert carbohydrates, fats, and proteins into usable energy. It is also crucial for the synthesis of coenzyme A, which is involved in the production of hormones, neurotransmitters, and red blood cells. Pantothenic Acid supports adrenal function, helps the body respond to stress, and is important for maintaining healthy skin, hair, and eyes. It is commonly found in foods like meat, eggs, legumes, and whole grains, and is often included in multivitamins and B-complex supplements to support energy levels, stress management, and overall health.",
  },
  {
    value: "pausinystalia",
    label: "Pausinystalia Yohimbe Extract",
    unit: "mg",
    definition:
      "Pausinystalia Yohimbe Extract is derived from the bark of the Yohimbe tree (Pausinystalia johimbe), native to Central Africa. It is known for its stimulating properties, primarily due to the active compound yohimbine. Yohimbine acts as an alpha-2 adrenergic receptor antagonist, which helps to increase adrenaline levels, promote fat burning, and improve blood flow. Pausinystalia Yohimbe Extract is often used in supplements for its potential to enhance athletic performance, support weight loss, and improve sexual health by increasing blood flow to certain areas of the body. However, due to its potent effects, Yohimbe should be used with caution, as it can cause side effects like increased heart rate, elevated blood pressure, and anxiety in some individuals.",
  },
  {
    value: "peakatp",
    label: "Peak ATP®",
    unit: "mg",
    definition:
      "Peak ATP® is a patented form of Adenosine 5'-Triphosphate (ATP) Disodium, a compound that plays a critical role in cellular energy production. It is designed to increase the levels of extracellular ATP in the body, which enhances muscular power, strength, and endurance during exercise. By boosting blood flow, improving oxygen delivery to muscles, and reducing muscle fatigue, Peak ATP® helps athletes achieve better performance and recovery. It is commonly included in pre-workout and performance-enhancing supplements to support increased energy, muscle contraction, and overall workout intensity. Peak ATP® is particularly popular among athletes and bodybuilders looking to maximize their training results.",
  },
  {
    value: "peako2",
    label: "PeakO2®",
    unit: "mg",
    definition:
      "PeakO2® is a patented blend of six adaptogenic mushrooms, including Cordyceps, Reishi, King Trumpet, Shiitake, Lion's Mane, and Turkey Tail, designed to enhance athletic performance and improve oxygen utilization. This blend is known for its ability to increase endurance, boost power output, and reduce fatigue during exercise. By improving the body's ability to use oxygen efficiently, PeakO2® helps athletes train harder and recover faster. It is commonly included in pre-workout and endurance supplements to support improved performance, increased stamina, and overall physical resilience, making it a popular choice for athletes and fitness enthusiasts looking to push their limits.",
  },
  {
    value: "pegapump",
    label: "PegaPump™",
    unit: "mcg",
    definition:
      "PegaPump™ is a branded ingredient designed to enhance nitric oxide production, leading to improved blood flow, better muscle pumps, and increased vascularity during workouts. It is typically included in pre-workout supplements to support enhanced muscle fullness, endurance, and overall exercise performance. PegaPump™ works by promoting the dilation of blood vessels, allowing for more efficient delivery of oxygen and nutrients to muscles, which can result in more intense and effective workouts. It is popular among bodybuilders and athletes looking to maximize their muscle pumps and performance during training sessions.",
  },
  {
    value: "performelon",
    label: "PerforMelon®",
    unit: "mg",
    definition:
      "PerforMelon® is a branded ingredient derived from watermelon extract, known for its high content of L-citrulline, an amino acid that plays a key role in enhancing nitric oxide production. By increasing nitric oxide levels, PerforMelon® supports improved blood flow, better muscle pumps, and enhanced exercise performance. It also helps reduce muscle soreness and fatigue by promoting efficient nutrient delivery and oxygenation to the muscles. PerforMelon® is commonly included in pre-workout and recovery supplements to support endurance, hydration, and overall athletic performance, making it a popular choice for athletes and fitness enthusiasts.",
  },
  { value: "pfbo", label: "PFBc®", unit: "mg" },
  {
    value: "propionylcarnitinehcl",
    label: "Propionyl Carnitine HCl",
    unit: "mg",
    definition:
      "PFBc® (Palm Fruit Bioactive Complex) is a branded ingredient derived from the fruit of the palm tree. It is rich in bioactive compounds such as tocotrienols, polyphenols, and carotenoids, which are known for their antioxidant and anti-inflammatory properties. PFBc® is used in supplements to support cardiovascular health, promote healthy aging, and protect cells from oxidative stress. Additionally, it may help improve skin health and boost immune function. This ingredient is valued for its ability to enhance overall wellness by providing a potent source of natural antioxidants and other beneficial nutrients.",
  },
  {
    value: "nphenethyl",
    label: "Phenethyl Dimethylamine",
    unit: "mg",
    definition:
      "Phenethyl Dimethylamine, often referred to as Eria Jarensis Extract, is a naturally occurring compound that acts as a neuromodulator in the central nervous system. It is known for its stimulant and mood-enhancing properties, as it can increase the levels of dopamine and noradrenaline, which are associated with improved focus, mood, and energy levels. Phenethyl Dimethylamine is commonly included in pre-workout and fat-burning supplements to enhance mental clarity, boost motivation, and provide a smooth, uplifting energy boost without the jittery effects often associated with stronger stimulants.",
  },
  {
    value: "nphenethylcitrate",
    label: "Phenethyl Dimethylamine Citrate",
    unit: "mg",
    definition:
      "Phenethyl Dimethylamine Citrate is a synthetic compound that combines Phenethyl Dimethylamine with citric acid to enhance its stability and absorption. Like its base form, it acts as a neuromodulator and stimulant, known for its ability to elevate mood, increase focus, and boost energy levels. The citrate form is designed to improve the compound's bioavailability, making it more effective at providing mental clarity, motivation, and a smooth, sustained energy boost. Phenethyl Dimethylamine Citrate is often included in pre-workout and energy supplements for those seeking enhanced cognitive and physical performance without the harsh side effects of stronger stimulants.",
  },
  {
    value: "phenylalanine",
    label: "Phenylalanine",
    unit: "mg",
    definition:
      "Phenylalanine is an essential amino acid that serves as a building block for proteins and is a precursor to important neurotransmitters such as dopamine, norepinephrine, and epinephrine. These neurotransmitters play a critical role in regulating mood, alertness, and cognitive function. Phenylalanine is also converted into tyrosine, another amino acid that supports brain health and mental clarity. Because the body cannot produce Phenylalanine on its own, it must be obtained through diet or supplements. It is commonly included in supplements aimed at improving mood, boosting mental performance, and supporting overall brain health.",
  },
  {
    value: "phenylhcl",
    label: "Phenylethylamine HCl",
    unit: "mg",
    definition:
      "Phenylethylamine HCl (PEA HCl) is a hydrochloride salt form of phenylethylamine, a naturally occurring compound that acts as a neurotransmitter and neuromodulator in the brain. It is known for its mood-enhancing and cognitive-boosting effects, as it stimulates the release of dopamine and norepinephrine, leading to increased focus, improved mood, and heightened mental clarity. The HCl form enhances the stability and absorption of phenylethylamine, making it more effective. Phenylethylamine HCl is commonly included in supplements to promote energy, motivation, and cognitive function, often as part of pre-workout or nootropic formulas. Its effects are typically fast-acting, though short-lived, due to its rapid metabolism in the body.",
  },
  {
    value: "phosphorus",
    label: "Phosphorus",
    unit: "mg",
    definition:
      "Phosphorus is an essential mineral that plays a critical role in many bodily functions, including the formation of bones and teeth, energy production, and the regulation of acid-base balance. It is a major component of ATP (adenosine triphosphate), the molecule that stores and transfers energy within cells, making it vital for energy metabolism. Phosphorus is also involved in the synthesis of DNA and RNA, and it helps maintain the structure and function of cell membranes. It is commonly found in foods like dairy products, meat, fish, and whole grains, and is often included in supplements to support bone health, energy production, and overall metabolic function. Phosphorus works closely with calcium to maintain healthy bones and teeth, and an appropriate balance of these two minerals is essential for optimal health.",
  },
  {
    value: "pinebark",
    label: "Pine Bark Extract",
    unit: "mg",
    definition:
      "Pine Bark Extract is derived from the bark of the maritime pine tree (Pinus pinaster) and is rich in powerful antioxidants known as proanthocyanidins. These compounds help protect cells from oxidative stress, support cardiovascular health, and improve blood circulation. Pine Bark Extract is also known for its anti-inflammatory properties, making it beneficial for joint health and reducing symptoms of conditions like arthritis. Additionally, it can enhance skin health by improving elasticity and reducing the signs of aging. Pine Bark Extract is commonly included in supplements aimed at supporting heart health, improving circulation, boosting immune function, and promoting overall well-being.",
  },
  {
    value: "piperine",
    label: "Piperine",
    unit: "mg",
    definition:
      "Piperine is an active compound found in black pepper (Piper nigrum), known for its ability to enhance the bioavailability and absorption of various nutrients and supplements. By inhibiting certain enzymes in the digestive system, piperine increases the amount of nutrients that enter the bloodstream, making them more effective. This property is especially useful in enhancing the absorption of curcumin, the active ingredient in turmeric, as well as vitamins and minerals. Piperine also has anti-inflammatory and antioxidant properties, contributing to overall health. It is commonly included in supplements to improve the efficacy of other ingredients and support overall nutrient absorption.",
  },
  {
    value: "himalayan",
    label: "Pink Himalayan Sea Salt",
    unit: "mg",
    definition:
      "Pink Himalayan Sea Salt is a type of salt mined from ancient sea salt deposits in the Himalayan region. It is known for its distinctive pink color, which comes from trace minerals such as magnesium, calcium, and potassium. Unlike refined table salt, Pink Himalayan Sea Salt is minimally processed and retains these natural minerals, making it a popular choice for culinary and health purposes. It is often used to enhance flavor in cooking, as well as in bath products and salt lamps for its potential health benefits. Pink Himalayan Sea Salt is also included in supplements and hydration products to support electrolyte balance, aid in muscle function, and promote overall well-being.",
  },
  {
    value: "potassium",
    label: "Potassium",
    unit: "mg",
    definition:
      "Potassium is an essential mineral and electrolyte that plays a crucial role in maintaining proper cell function, particularly in muscle and nerve cells. It helps regulate fluid balance, muscle contractions, and nerve signals. Potassium is vital for maintaining normal blood pressure levels, reducing the risk of stroke, and supporting cardiovascular health. It also aids in the proper functioning of the kidneys and helps prevent the formation of kidney stones. Potassium is commonly found in foods like bananas, oranges, potatoes, and leafy greens, and is often included in supplements to support heart health, muscle function, and overall electrolyte balance, especially for individuals who may be at risk of deficiency due to diet or medical conditions.",
  },
  {
    value: "potassiumbicarbonate",
    label: "Potassium Bicarbonate",
    unit: "mg",
    definition:
      "Potassium Bicarbonate is a compound that provides both potassium and bicarbonate, which are essential for maintaining electrolyte balance and acid-base balance in the body. Potassium is crucial for normal cell function, nerve signaling, and muscle contractions, while bicarbonate acts as a buffer to help maintain the body's pH levels. Potassium Bicarbonate is often used in supplements to support cardiovascular health, prevent muscle cramps, and reduce the risk of kidney stones by neutralizing excess acidity. It is also used as an antacid to relieve heartburn and indigestion. This form of potassium is particularly useful for individuals looking to increase their potassium intake while also benefiting from its alkalizing effects.",
  },
  {
    value: "polygonum",
    label: "Polygonum Cuspidatum Extract",
    unit: "mg",
    definition:
      "Polygonum Cuspidatum Extract, also known as Japanese Knotweed Extract, is a plant extract rich in resveratrol, a potent antioxidant known for its anti-aging and anti-inflammatory properties. Resveratrol is often associated with supporting heart health, improving circulation, and protecting against oxidative stress. Polygonum Cuspidatum Extract is commonly included in supplements aimed at promoting cardiovascular health, supporting healthy aging, and enhancing immune function. Additionally, it may help improve skin health and reduce inflammation, making it a popular ingredient in wellness and anti-aging formulations.",
  },
  {
    value: "potassiumcitrate",
    label: "Potassium Citrate",
    unit: "mg",
    definition:
      "Potassium Citrate is a compound that combines potassium with citric acid. It is commonly used in supplements to support electrolyte balance, prevent kidney stones, and maintain proper acid-base balance in the body. Potassium is essential for nerve function, muscle contractions, and maintaining healthy blood pressure levels, while citrate helps alkalize the urine, which can reduce the formation of kidney stones. Potassium Citrate is often included in supplements for its role in supporting cardiovascular health, improving muscle function, and promoting overall electrolyte balance, especially in individuals who may be at risk of potassium deficiency or require urinary alkalization.",
  },
  {
    value: "progbb",
    label: "ProGBB®",
    unit: "mg",
    definition:
      "ProGBB® is a branded form of Gamma-Butyrobetaine Ethyl Ester, a compound that acts as a precursor to L-carnitine in the body. By increasing the production of L-carnitine, ProGBB® enhances the body’s ability to convert fat into energy, making it a popular ingredient in fat-burning and energy-boosting supplements. ProGBB® is known for its thermogenic effects, promoting increased calorie expenditure and supporting fat loss. It is often included in pre-workout and weight management supplements to help improve exercise performance, boost energy levels, and enhance fat metabolism.",
  },
  {
    value: "pomegranate",
    label: "Pomegranate Extract",
    unit: "mg",
    definition:
      "Pomegranate Extract is derived from the seeds or fruit of the pomegranate (Punica granatum), known for its rich content of antioxidants, particularly polyphenols like ellagic acid and punicalagins. These compounds have potent anti-inflammatory and anti-aging properties, and they support cardiovascular health by improving blood flow and reducing oxidative stress. Pomegranate Extract is commonly included in supplements to promote heart health, support immune function, and enhance skin health. It may also help reduce inflammation and protect against chronic diseases by neutralizing harmful free radicals in the body. Additionally, pomegranate extract is valued for its potential to improve exercise performance and recovery due to its ability to enhance nitric oxide production and circulation.",
  },
  {
    value: "purewayc",
    label: "PureWay-C™",
    unit: "mg",
    definition:
      "PureWay-C™ is a patented, enhanced form of vitamin C that is combined with lipid metabolites to improve absorption and retention in the body. This advanced form of vitamin C is designed to provide faster and more efficient uptake into cells, leading to higher and more sustained levels of vitamin C in the bloodstream. PureWay-C™ is known for its superior bioavailability compared to traditional vitamin C supplements. It supports immune function, promotes healthy skin, and acts as a powerful antioxidant, protecting cells from oxidative stress. PureWay-C™ is commonly included in supplements aimed at boosting the immune system, enhancing skin health, and supporting overall well-being.",
  },
  {
    value: "rauwolfia",
    label: "Rauwolfia Vomitoria Root Extract",
    unit: "mg",
    definition:
      "Rauwolfia Vomitoria Root Extract is derived from the root of the Rauwolfia vomitoria plant, which is native to Africa. This extract is known for its active alkaloid, yohimbine, particularly its alpha-yohimbine (also known as rauwolscine) content. It is often used in supplements for its stimulant and fat-burning properties. Rauwolfia Vomitoria Root Extract works by blocking alpha-2 adrenergic receptors, which can enhance the release of norepinephrine, leading to increased energy levels, improved fat breakdown, and heightened focus. It is commonly included in fat-burning, pre-workout, and energy supplements to support weight loss, boost mental clarity, and enhance physical performance. Due to its potent effects, it should be used with caution, as it may cause side effects like increased heart rate or elevated blood pressure in some individuals.",
  },
  {
    value: "rauwolscine",
    label: "Rauwolscine",
    unit: "mg",
    definition:
      "Rauwolscine, also known as alpha-yohimbine, is a potent alkaloid that enhances fat burning, boosts energy, and improves focus by blocking alpha-2 adrenergic receptors. Commonly used in pre-workout and fat-burning supplements, it offers a more selective and powerful effect compared to yohimbine. However, due to its potency, it may cause side effects like increased heart rate and blood pressure, so it should be used with caution.",
  },
  {
    value: "rednite",
    label: "RedNite®",
    unit: "mg",
    definition:
      "RedNite® is a branded, concentrated beetroot powder known for its high nitrate content, which supports improved blood flow, endurance, and exercise performance. It is commonly used in pre-workout supplements to enhance muscle pumps, increase stamina, and boost overall cardiovascular health. RedNite® offers a natural and effective way to support athletic performance, making it popular among athletes and fitness enthusiasts.",
  },
  {
    value: "redpanax",
    label: "Red Panax Ginseng Extract",
    unit: "mg",
    definition:
      "Red Panax, also known as Red Panax Ginseng, is a type of ginseng that is often included in pre-workout supplements for its potential to enhance energy, focus, and endurance. Derived from the root of the Panax ginseng plant, this herb is known for its adaptogenic properties, which help the body manage stress and maintain balance during intense physical activity. Red Panax Ginseng is believed to improve circulation, boost nitric oxide levels, and reduce fatigue, thereby supporting better exercise performance and faster recovery. Its inclusion in pre-workout formulas is particularly favored for its potential to provide sustained, natural energy without the jittery effects associated with stimulants like caffeine.",
  },
  {
    value: "rhodiola",
    label: "Rhodiola",
    unit: "mg",
    definition:
      "Rhodiola, derived from the Rhodiola rosea plant, is an adaptogen known for its ability to help the body manage stress, enhance mental clarity, and improve energy levels. It is commonly used to reduce fatigue, support cognitive function, and boost physical endurance. Rhodiola is often included in supplements aimed at promoting resilience to stress, enhancing mood, and improving overall well-being.",
  },
  {
    value: "rhodioprime",
    label: "RhodioPrime 6x",
    unit: "mg",
    definition:
      "RhodioPrime 6X is a high-potency form of Rhodiola rosea extract, standardized to contain a significant concentration of the active compounds rosavins and salidrosides, which are responsible for its adaptogenic effects. This extract is designed to help the body manage stress, improve mental clarity, boost energy levels, and enhance physical endurance. RhodioPrime 6X is often included in supplements for stress relief, cognitive enhancement, and overall well-being, providing a more concentrated and effective dose of Rhodiola rosea.",
  },
  {
    value: "riboflavin",
    label: "Riboflavin",
    unit: "mg",
    definition:
      "Riboflavin, also known as Vitamin B2, is an essential nutrient that plays a key role in energy production, cellular function, and metabolism. It helps convert carbohydrates, fats, and proteins into energy and is crucial for maintaining healthy skin, eyes, and nervous system function. Riboflavin also acts as an antioxidant, protecting cells from oxidative stress. It is commonly found in foods like eggs, milk, and green vegetables, and is often included in multivitamins and B-complex supplements to support overall health and energy levels.",
  },
  {
    value: "sseven",
    label: "S7®",
    unit: "mg",
    definition:
      "S7® is a patented blend of seven plant-based ingredients, including green coffee bean extract, green tea extract, turmeric, tart cherry, blueberry, broccoli, and kale. It is designed to boost the body's natural production of nitric oxide, which enhances blood flow and oxygen delivery to muscles. This leads to improved endurance, better muscle pumps, and enhanced exercise performance. S7® is commonly included in pre-workout and performance supplements to support athletic performance, increase stamina, and promote overall cardiovascular health. It is a popular choice for those looking to naturally enhance their workout results without the use of stimulants.",
  },
  {
    value: "saffron",
    label: "Saffron Extract",
    unit: "mg",
    definition:
      "Saffron Extract is derived from the dried stigmas of the saffron flower (Crocus sativus). It is known for its mood-enhancing properties and is often used to support emotional well-being, reduce symptoms of depression, and promote relaxation. Saffron Extract also has antioxidant properties, helping to protect cells from oxidative stress. Additionally, it has been studied for its potential to support eye health, appetite control, and weight management. Saffron Extract is commonly included in supplements aimed at improving mood, reducing anxiety, and supporting overall mental and emotional health.",
  },
  {
    value: "santenergy",
    label: "SantEnergy™ Nu",
    unit: "mg",
    definition:
      "SantEnergy™ Nu is a branded extract derived from the leaves of the Yerba Santa plant (Eriodictyon californicum). It is known for its natural stimulant properties, providing a sustained boost in energy and focus without the jitters commonly associated with other stimulants. In addition to its energizing effects, SantEnergy™ Nu also has antioxidant properties, helping to protect cells from oxidative stress. It is often included in energy, weight management, and cognitive support supplements to enhance mental clarity, boost energy levels, and support overall well-being.",
  },
  {
    value: "sausagetree",
    label: "Sausage Tree Extract",
    unit: "mg",
    definition:
      "Sausage Tree Extract, derived from the fruit of the Kigelia africana tree, is known for its medicinal and cosmetic benefits. Traditionally used in African medicine, it is rich in bioactive compounds that have anti-inflammatory, antimicrobial, and antioxidant properties. Sausage Tree Extract is often used in skincare products to improve skin firmness, reduce blemishes, and treat conditions like eczema and psoriasis. It is also included in supplements and topical formulations aimed at promoting skin health, enhancing elasticity, and supporting overall skin rejuvenation.",
  },
  {
    value: "schisandra",
    label: "Schisandra",
    unit: "mg",
    definition:
      "Schisandra, derived from the berries of the Schisandra chinensis plant, is an adaptogenic herb known for its ability to help the body manage stress and enhance overall vitality. It is traditionally used in Chinese medicine to support liver health, improve mental clarity, and boost endurance. Schisandra is also recognized for its antioxidant properties, which help protect cells from oxidative stress. It is commonly included in supplements aimed at improving energy levels, supporting cognitive function, promoting liver detoxification, and enhancing physical performance.",
  },
  {
    value: "selenium",
    label: "Selenium",
    unit: "mcg",
    definition:
      "Selenium is an essential trace mineral that plays a critical role in various bodily functions, particularly as a component of antioxidant enzymes like glutathione peroxidase, which help protect cells from oxidative damage. Selenium is also important for thyroid health, as it supports the production of thyroid hormones, and it contributes to immune system function by helping to fight off infections. Adequate selenium intake is linked to a reduced risk of certain chronic diseases, including heart disease and some types of cancer. Selenium is commonly found in foods like Brazil nuts, seafood, and whole grains, and is often included in multivitamins and antioxidant supplements to support overall health, immune function, and thyroid regulation.",
  },
  {
    value: "senactiv",
    label: "SenActiv®",
    unit: "mg",
    definition:
      "SenActiv® is a patented blend of natural ingredients, including Panax notoginseng and Rosa roxburghii, designed to enhance athletic performance and promote muscle recovery. It works by increasing the production of energy at the cellular level, improving muscle endurance, and reducing inflammation. SenActiv® also supports the removal of old, damaged muscle cells, helping to promote the regeneration of healthy muscle tissue. This makes it a popular ingredient in pre-workout and recovery supplements, aimed at improving workout performance, accelerating recovery, and reducing muscle fatigue.",
  },
  {
    value: "sensoril",
    label: "Sensoril®",
    unit: "mg",
    definition:
      "Sensoril® is a patented, standardized extract of Ashwagandha (Withania somnifera), known for its adaptogenic properties. It is designed to help the body manage stress, reduce anxiety, and promote overall mental and physical well-being. Sensoril® is also known for supporting energy levels, improving mood, enhancing cognitive function, and promoting restful sleep. Additionally, it helps to balance cortisol levels, which can reduce stress-related symptoms and improve overall health. Sensoril® is commonly included in supplements aimed at stress management, mood enhancement, cognitive support, and overall wellness.",
  },
  {
    value: "setria",
    label: "Setria®",
    unit: "mg",
    definition:
      "Setria® is a patented form of glutathione, a powerful antioxidant naturally produced in the body. Glutathione plays a critical role in detoxification, immune support, and protecting cells from oxidative stress. Setria® is known for its high bioavailability, meaning it is effectively absorbed and utilized by the body. It is commonly included in supplements to boost the immune system, promote healthy aging, and support liver detoxification. Additionally, Setria® helps maintain overall cellular health, making it a popular choice for those looking to enhance their antioxidant defenses and improve their overall well-being.",
  },
  {
    value: "sodium",
    label: "Sodium",
    unit: "mg",
    definition:
      "Sodium is an essential electrolyte that plays a vital role in maintaining fluid balance, nerve function, and muscle contractions in the body. It helps regulate blood pressure and is crucial for proper hydration, as it helps the body retain water and maintain the balance of fluids inside and outside of cells. Sodium is commonly found in table salt (sodium chloride) and is naturally present in many foods. While it is necessary for health, too much sodium can lead to high blood pressure and increase the risk of heart disease. It is important to balance sodium intake with other electrolytes like potassium to support overall cardiovascular and muscular health.",
  },
  {
    value: "sodiumbicarbonate",
    label: "Sodium Bicarbonate",
    unit: "mg",
    definition:
      "Sodium Bicarbonate, commonly known as baking soda, is a compound that acts as a buffer to neutralize acids in the body. It is widely used in supplements for its ability to help reduce acidity in the stomach, relieve heartburn, and improve exercise performance. By buffering lactic acid buildup during intense physical activity, Sodium Bicarbonate can delay muscle fatigue and enhance endurance, making it popular among athletes, especially in high-intensity sports. Additionally, it is used in some health supplements to support pH balance and overall digestive health.",
  },
  {
    value: "sodiumchloride",
    label: "Sodium Chloride",
    unit: "mg",
    definition:
      "Sodium Chloride, commonly known as table salt, is a mineral composed of sodium and chloride, both essential electrolytes that play crucial roles in maintaining fluid balance, nerve function, and muscle contractions. Sodium Chloride helps regulate blood pressure and supports the body's hydration by maintaining the proper balance of fluids inside and outside of cells. It is naturally present in many foods and is often added to enhance flavor. While it is necessary for health, excessive intake of Sodium Chloride can lead to high blood pressure and increase the risk of cardiovascular disease. It is important to consume it in moderation as part of a balanced diet.",
  },
  {
    value: "sodiumnitrate",
    label: "Sodium Nitrate",
    unit: "mg",
    definition:
      "Sodium Nitrate is a compound often used as a preservative in processed meats and as a source of nitrates in dietary supplements. In the body, sodium nitrate is converted into nitric oxide, which helps improve blood flow, enhance oxygen delivery to muscles, and support cardiovascular health. This property makes sodium nitrate popular in pre-workout supplements to boost endurance, increase muscle pumps, and improve exercise performance. However, excessive consumption of sodium nitrate, especially from processed foods, has been linked to health concerns, so it is important to use it in moderation and as part of a well-balanced diet.",
  },
  {
    value: "sodiumphosphate",
    label: "Sodium Phosphate",
    unit: "mg",
    definition:
      "Sodium phosphate is a compound that consists of sodium and phosphate ions, commonly used in pre-workout supplements to enhance exercise performance. It acts as a buffering agent, helping to maintain pH levels in muscles during high-intensity exercise, which can reduce fatigue and improve endurance. Additionally, sodium phosphate supports the production of adenosine triphosphate (ATP), the body's primary energy source, which is essential for muscle contractions. This can result in increased stamina and overall workout performance, particularly in endurance-based activities.",
  },
  {
    value: "superspinach",
    label: "Super Spinach®",
    unit: "mg",
    definition:
      "Super Spinach® is a branded ingredient derived from spinach, specifically formulated to enhance nitric oxide production in the body. Nitric oxide is essential for improving blood flow, increasing oxygen delivery to muscles, and supporting cardiovascular health. Super Spinach® is often included in pre-workout and performance supplements to boost endurance, enhance muscle pumps, and improve overall exercise performance. Its natural source of nitrates makes it a popular choice for athletes and fitness enthusiasts looking to optimize their workouts and promote heart health.",
  },
  {
    value: "sweetorange",
    label: "Sweet Orange Peel Extract",
    unit: "mg",
    definition:
      "Sweet Orange Peel Extract is derived from the peel of the sweet orange (Citrus sinensis). It is rich in flavonoids, particularly hesperidin, and other bioactive compounds that offer antioxidant and anti-inflammatory benefits. Sweet Orange Peel Extract is often used in supplements to support immune health, improve circulation, and promote skin health. Its antioxidant properties help protect cells from oxidative stress, while its anti-inflammatory effects can support overall wellness. Additionally, Sweet Orange Peel Extract is sometimes included in weight management supplements for its potential to aid digestion and boost metabolism.",
  },
  {
    value: "synephrinehcl",
    label: "Synephrine Hydrochloride",
    unit: "mg",
    definition:
      "Synephrine Hydrochloride is a synthetic form of synephrine, a naturally occurring alkaloid found in bitter orange (Citrus aurantium). It is commonly used in weight loss and energy supplements for its stimulant properties, which can increase metabolism, boost energy levels, and promote fat burning. Synephrine works by stimulating the adrenergic receptors in the body, leading to increased thermogenesis (heat production) and enhanced calorie expenditure. It is often included in pre-workout and fat-burning supplements to support weight loss, improve athletic performance, and increase mental focus. However, due to its stimulant effects, it should be used with caution, especially in individuals sensitive to stimulants or with certain health conditions.",
  },
  {
    value: "taurine",
    label: "Taurine",
    unit: "mg",
    definition:
      "Taurine is a naturally occurring amino acid that plays a crucial role in various bodily functions, including supporting cardiovascular health, maintaining proper hydration and electrolyte balance, and promoting the development and function of the central nervous system. It also has antioxidant properties, helping to protect cells from oxidative stress. Taurine is commonly found in energy drinks, pre-workout supplements, and products aimed at improving athletic performance, as it can enhance endurance, reduce muscle fatigue, and support recovery. Additionally, taurine supports heart health by improving blood flow and reducing the risk of cardiovascular diseases.",
  },
  {
    value: "teacrine",
    label: "TeaCrine®",
    unit: "mg",
    definition:
      "TeaCrine® is a patented form of theacrine, a natural compound similar in structure to caffeine, but with a slightly different mechanism of action. TeaCrine® is known for providing a sustained boost in energy, mental clarity, and focus without the jittery side effects or crash often associated with caffeine. It works by stimulating dopamine receptors, which enhances mood, motivation, and cognitive function, while also inhibiting adenosine, which can increase alertness. TeaCrine® is commonly included in pre-workout supplements, nootropics, and energy products to support improved performance, prolonged energy, and enhanced mental and physical endurance.",
  },
  {
    value: "theanine",
    label: "Theanine",
    unit: "mg",
    definition:
      "Theanine, specifically L-theanine, is an amino acid primarily found in tea leaves, particularly green tea. It is known for its calming effects and its ability to promote relaxation without causing drowsiness. Theanine works by increasing the production of neurotransmitters like serotonin, dopamine, and GABA, which help regulate mood, reduce stress, and improve focus. It is often used in combination with caffeine to enhance cognitive performance while mitigating the jittery effects of caffeine. Theanine is commonly included in supplements aimed at reducing anxiety, improving sleep quality, and promoting mental clarity and focus.",
  },
  {
    value: "theobroma",
    label: "Theobroma Cacao Extract",
    unit: "mg",
    definition:
      "Theobroma Cacao Extract is derived from the seeds of the cacao plant, known as the source of cocoa and chocolate. This extract is rich in bioactive compounds like flavonoids, particularly epicatechin, and theobromine. Theobromine is a mild stimulant similar to caffeine, providing a gentle energy boost, enhancing mood, and supporting cognitive function. The flavonoids in cacao extract are potent antioxidants that help protect cells from oxidative stress, support heart health, and improve blood flow. Theobroma Cacao Extract is often included in supplements to promote cardiovascular health, enhance mental performance, and provide a natural energy boost, while also contributing to overall well-being.",
  },
  {
    value: "theobromine",
    label: "Theobromine",
    unit: "mg",
    definition:
      "Theobromine is a naturally occurring compound found in cacao beans, tea leaves, and some other plants. It is a mild stimulant, similar to caffeine, but with a gentler and longer-lasting effect. Theobromine primarily works by increasing blood flow, relaxing smooth muscles, and improving mood and focus. Unlike caffeine, it does not typically cause jitters or a rapid increase in heart rate, making it a popular ingredient in supplements aimed at providing a smooth energy boost, enhancing mental clarity, and supporting cardiovascular health. Theobromine is often included in pre-workout formulas, nootropics, and energy supplements for its ability to improve cognitive performance and physical endurance while promoting a sense of well-being.",
  },
  {
    value: "theobrominehcl",
    label: "Theobromine HCl",
    unit: "mg",
    definition:
      "Theobromine HCl (Theobromine Hydrochloride) is a hydrochloride salt form of theobromine, designed to enhance the compound's stability and absorption in the body. Theobromine HCl shares the same mild stimulant properties as theobromine, providing a gentle and sustained energy boost without the intense effects often associated with caffeine. It also supports cardiovascular health by promoting blood flow and relaxing smooth muscles. Theobromine HCl is commonly included in supplements aimed at improving mental clarity, enhancing mood, and supporting physical endurance, especially in pre-workout and cognitive enhancement formulas.",
  },
  {
    value: "thinkamine",
    label: "Thinkamine™",
    unit: "mg",
    definition:
      "Thinkamine™ is a branded nootropic ingredient designed to enhance cognitive function, improve focus, and boost mental clarity. It is formulated to support brain health by promoting neurotransmitter activity, increasing blood flow to the brain, and providing neuroprotective benefits. Thinkamine™ is commonly included in cognitive enhancement supplements, often used by individuals looking to improve memory, concentration, and overall mental performance, whether for academic, professional, or athletic purposes. Its specific formulation and active components are tailored to provide sustained cognitive benefits without the use of harsh stimulants.",
  },
  {
    value: "thiamin",
    label: "Thiamin",
    unit: "mg",
    definition:
      "Thiamin, also known as Vitamin B1, is an essential water-soluble vitamin that plays a crucial role in energy metabolism. It helps convert carbohydrates into energy and is vital for the proper function of the nervous system, muscles, and heart. Thiamin is also important for the synthesis of certain neurotransmitters and the conduction of nerve signals. It is commonly found in foods such as whole grains, legumes, nuts, and meat, and is often included in multivitamins and B-complex supplements to support overall energy production, nerve function, and cardiovascular health. Thiamin deficiency can lead to conditions such as beriberi and Wernicke-Korsakoff syndrome, particularly in individuals with high alcohol consumption or poor diets.",
  },
  {
    value: "thiaminehcl",
    label: "Thiamin HCl",
    unit: "mg",
    definition:
      "Thiamin HCl (Thiamin Hydrochloride) is a hydrochloride salt form of Thiamin (Vitamin B1). This form is used to enhance the stability and absorption of the vitamin in the body. Thiamin HCl plays a crucial role in energy metabolism by helping to convert carbohydrates into energy, and it supports the proper functioning of the nervous system, muscles, and heart. It is commonly included in supplements, particularly B-complex vitamins, to ensure adequate intake of Thiamin, supporting overall energy production, nerve function, and cardiovascular health. Thiamin HCl is especially beneficial for individuals at risk of deficiency, such as those with poor diets or high alcohol consumption.",
  },
  {
    value: "thiaminemononitrate",
    label: "Thiamine Mononitrate",
    unit: "mg",
    definition:
      "Thiamine Mononitrate is a stable form of Thiamine (Vitamin B1) used in dietary supplements and fortified foods. It helps convert carbohydrates into energy and supports the proper functioning of the nervous system, heart, and muscles. This form of Thiamine is commonly used to prevent and treat deficiencies, which can lead to conditions like beriberi and Wernicke-Korsakoff syndrome. It is valued for its stability and effectiveness in delivering Thiamine in supplements and enriched food products.",
  },
  {
    value: "triller",
    label: "Trilline™",
    unit: "mg",
    definition:
      "Trilline™ is a trademarked ingredient used in dietary supplements, primarily known for its potential cognitive and mood-enhancing benefits. It is often included in nootropic and energy supplements for its effects on mental performance and overall well-being. The specific formulation and benefits of Trilline™ can vary based on the product, so it's essential to refer to the product label or manufacturer for detailed information.",
  },
  {
    value: "triggertwo",
    label: "Trigger2®",
    unit: "mg",
    definition:
      "Trigger2® is a trademarked ingredient used in dietary supplements, typically for its performance-enhancing and energy-boosting properties. It is often included in pre-workout and energy supplements to support physical performance, endurance, and focus. The exact formulation and benefits of Trigger2® can vary by product, so it's best to consult the product label or manufacturer for detailed information.",
  },
  {
    value: "tyrosine",
    label: "Tyrosine",
    unit: "mg",
    definition:
      "Tyrosine is an amino acid that supports the production of neurotransmitters, such as dopamine and norepinephrine. It is commonly used in supplements to enhance cognitive function, improve mood, and manage stress. Tyrosine helps with mental alertness and focus, particularly during periods of fatigue or stressful situations.",
  },
  {
    value: "ultraeight",
    label: "Ultra8®'",
    unit: "mg",
    definition:
      "Ultra8® is a trademarked ingredient often used in dietary supplements for its potential benefits in enhancing physical performance and supporting overall well-being. It is typically included in formulations aimed at improving energy, endurance, and recovery. The specific effects and formulation of Ultra8® can vary, so it's best to check the product label or manufacturer for detailed information.",
  },
  {
    value: "uridine",
    label: "Uridine",
    unit: "mg",
    definition:
      "Uridine is a nucleoside that plays a role in cellular metabolism and brain function. It is involved in the synthesis of RNA and supports cognitive health, memory, and mood. Uridine supplements are often used to enhance mental performance and support overall brain health.",
  },
  {
    value: "uridinefive",
    label: "Uridine 5'",
    unit: "mg",
    definition:
      "Uridine 5'-monophosphate (UMP) is a nucleotide that supports brain health by contributing to RNA synthesis and neurotransmitter production. It may help with cognitive function, memory, and mood regulation. UMP is often used in supplements to promote mental clarity and support brain function.",
  },
  {
    value: "valine",
    label: "Valine",
    unit: "mg",
    definition:
      "Valine is an essential amino acid that supports muscle growth, repair, and energy production. It helps maintain nitrogen balance and is crucial for muscle metabolism. Valine is often included in supplements to enhance exercise performance and recovery.",
  },
  {
    value: "vasodriveap",
    label: "VasoDrive-AP®",
    unit: "mg",
    definition:
      "VasoDrive-AP® is a patented ingredient derived from a fermented milk protein that helps support healthy blood pressure levels and improve cardiovascular function. It contains bioactive peptides that promote the production of angiotensin-converting enzyme (ACE) inhibitors, which help relax blood vessels and improve blood flow. By aiding in the regulation of blood pressure and enhancing vascular health, VasoDrive-AP® is commonly used in dietary supplements to support overall cardiovascular wellness and athletic performance.",
  },
  {
    value: "velvetbean",
    label: "Velvet Bean Extract",
    unit: "mg",
    definition:
      "Velvet Bean Extract is derived from the seeds of the Mucuna pruriens plant. It is known for its high content of L-DOPA (levodopa), a precursor to dopamine, which is a neurotransmitter involved in mood, motivation, and cognitive function. Velvet Bean Extract is often used in supplements to support mental health, improve mood, and enhance cognitive performance. It may also aid in reducing symptoms of Parkinson's disease and improving overall brain health.",
  },
  {
    value: "vinitrox",
    label: "Vinitrox™",
    unit: "mg",
    definition:
      "Vinitrox™ is a proprietary blend of grape and apple polyphenols designed to support cardiovascular health and enhance athletic performance. This ingredient combines natural compounds that help improve nitric oxide production, leading to better blood flow, increased endurance, and reduced muscle fatigue. Vinitrox™ is commonly included in supplements to promote healthy circulation, boost energy levels, and support overall cardiovascular wellness.",
  },
  {
    value: "vitacholine",
    label: "VitaCholine™",
    unit: "mg",
    definition:
      "VitaCholine™ is a branded form of choline, a vital nutrient that supports brain health and cognitive function. It is a source of choline bitartrate, which contributes to the production of acetylcholine, a neurotransmitter essential for memory, learning, and overall brain health. VitaCholine™ is often used in supplements to enhance mental clarity, improve focus, and support overall cognitive function. It can also aid in maintaining liver health and supporting metabolic processes.",
  },
  {
    value: "vitaminb3",
    label: "Vitamin B3",
    unit: "mg",
    definition:
      "Vitamin B3, also known as niacin, is a water-soluble vitamin that supports various bodily functions. It plays a crucial role in energy production by aiding the metabolism of carbohydrates, fats, and proteins. Vitamin B3 also helps maintain healthy skin, supports digestive health, and contributes to cardiovascular health by improving cholesterol levels. Additionally, it supports the nervous system and can help reduce inflammation. Niacin is commonly found in foods like meat, fish, and whole grains and is often included in supplements to support overall health.",
  },
  {
    value: "vitaminb6",
    label: "Vitamin B6",
    unit: "mg",
    definition:
      "Vitamin B6, also known as pyridoxine, is a water-soluble vitamin essential for numerous bodily functions. It plays a key role in amino acid metabolism, neurotransmitter synthesis, and the production of hemoglobin. Vitamin B6 supports brain health, helps regulate mood, and aids in the functioning of the immune system. It also contributes to the metabolism of proteins, fats, and carbohydrates. Common sources of vitamin B6 include poultry, fish, potatoes, and bananas. It is often included in supplements to support overall health and well-being.",
  },
  {
    value: "vitaminb9",
    label: "Vitamin B9",
    unit: "mcg",
    definition:
      "Vitamin B9, commonly known as folate or folic acid, is a water-soluble vitamin vital for DNA synthesis and repair. It plays a crucial role in cell division and growth, making it especially important during periods of rapid development, such as pregnancy and infancy. Folate supports red blood cell formation and helps prevent neural tube defects in developing embryos. It also aids in mental health by supporting cognitive function and mood regulation. Sources of vitamin B9 include leafy green vegetables, legumes, and fortified cereals. It is commonly included in supplements to support overall health and prenatal care.",
  },
  {
    value: "vitaminb12",
    label: "Vitamin B12",
    unit: "mcg",
    definition:
      "Vitamin B12, also known as cobalamin, is a water-soluble vitamin essential for red blood cell formation, neurological function, and DNA synthesis. It helps maintain the health of nerve cells and supports brain function. Vitamin B12 is primarily found in animal products like meat, dairy, and eggs. It is crucial for energy metabolism and can help prevent anemia and neurological disorders. Supplementation is often necessary for those with dietary restrictions or absorption issues to maintain optimal health.",
  },
  {
    value: "vitaminc",
    label: "Vitamin C",
    unit: "mg",
    definition:
      "Vitamin C, or ascorbic acid, is a water-soluble vitamin vital for the growth and repair of tissues in the body. It acts as a powerful antioxidant, protecting cells from damage by free radicals. Vitamin C is essential for the production of collagen, which supports skin, blood vessels, bones, and cartilage. It also enhances the absorption of iron from plant-based foods and boosts the immune system. Common sources include citrus fruits, strawberries, and bell peppers. Deficiency can lead to scurvy, characterized by gum disease, fatigue, and skin issues.",
  },
  {
    value: "vitamind",
    label: "Vitamin D",
    unit: "mcg",
    definition:
      "Vitamin D is a fat-soluble vitamin crucial for maintaining healthy bones and teeth. It helps the body absorb calcium and phosphorus from the diet, which supports bone density and strength. Vitamin D also plays a role in immune function and has been linked to improved mood and overall health. It can be obtained through sunlight exposure, fatty fish, fortified dairy products, and supplements. Deficiency can lead to bone disorders such as rickets in children and osteomalacia in adults.",
  },
  {
    value: "yerba",
    label: "Yerba Mate Extract",
    unit: "mg",
    definition:
      "Yerba Mate Extract is derived from the leaves of the Ilex paraguariensis plant, native to South America. It is rich in antioxidants, caffeine, and other stimulating compounds. Yerba Mate Extract is often used for its energy-boosting and cognitive-enhancing effects, similar to coffee or tea. It can help improve mental alertness, increase physical endurance, and support weight loss by boosting metabolism. Additionally, it is known for its potential benefits in enhancing mood and providing a gentle, sustained energy release.",
  },
  {
    value: "yohimbine",
    label: "Yohimbine",
    unit: "mg",
    definition:
      "Yohimbine is an alkaloid extracted from the bark of the Yohimbe tree (Pausinystalia yohimbe), native to Africa. It is commonly used for its stimulant and fat-burning properties. Yohimbine works by blocking alpha-2 adrenergic receptors, which can increase the release of norepinephrine and boost fat metabolism. It is often included in weight loss and pre-workout supplements to enhance energy, focus, and physical performance. Yohimbine can also improve sexual function by increasing blood flow. Due to its potent effects, it should be used with caution, as it may cause side effects such as increased heart rate and elevated blood pressure.",
  },
  {
    value: "yohimbinehcl",
    label: "Yohimbine HCl",
    unit: "mg",
    definition:
      "Yohimbine HCl is the hydrochloride salt form of Yohimbine, derived from the bark of the Yohimbe tree (Pausinystalia yohimbe). It is used for its stimulant effects, primarily in fat-burning and performance-enhancing supplements. Yohimbine HCl works by blocking alpha-2 adrenergic receptors, leading to increased norepinephrine release and enhanced fat metabolism. It is also used to improve sexual function by increasing blood flow. As a potent compound, it should be used cautiously due to potential side effects like elevated heart rate and blood pressure.",
  },
  {
    value: "zinc",
    label: "Zinc",
    unit: "mg",
    definition:
      "Zinc is a mineral essential for numerous physiological functions, including immune system support, DNA synthesis, and cell division. It plays a key role in protein synthesis, wound healing, and maintaining healthy skin, eyes, and taste and smell senses. Zinc also supports proper growth and development during pregnancy, childhood, and adolescence. It is often included in dietary supplements to address deficiencies and boost overall health.",
  },
  {
    value: "zynamite",
    label: "Zynamite®",
    unit: "mg",
    definition:
      "Zynamite® is a patented extract derived from the fruit of the Zynamite® tree, known for its potential cognitive and physical performance benefits. It is standardized to provide high levels of mangiferin, a polyphenol with antioxidant properties. Zynamite® is often used in supplements to enhance mental focus, reduce fatigue, and improve exercise performance by increasing energy levels and supporting overall vitality.",
  },
];

export function titleMakerPW([...excludes]) {
  const findLabel = (value) => {
    const ingredient = preIngredients.find((ing) => ing.value === value);
    return ingredient ? ingredient.label : value;
  };

  const revisedExcludes = excludes
    .filter(
      (exclude) =>
        typeof exclude === "string" &&
        exclude !== "undefined" &&
        exclude !== "null"
    )
    .map((exclude) => {
      return findLabel(exclude);
    });

  if (revisedExcludes.length === 0) {
    return "Pre-Workout";
  } else if (revisedExcludes.length === 1) {
    return revisedExcludes[0] + " Free Pre-Workout";
  } else {
    return (
      revisedExcludes.slice(0, -1).join(", ") +
      " and " +
      revisedExcludes[revisedExcludes.length - 1] +
      " Free Pre-Workout"
    );
  }
}

export function subMakerElectrolytes([...ingredients]) {
  const Ingredients = [
    { value: "calcium", label: "Calcium" },
    { value: "chloride", label: "Chloride" },
    { value: "magnesium", label: "Magnesium" },
    { value: "potassium", label: "Potassium" },
    { value: "sodium", label: "Sodium" },
  ];

  const findLabel = (value) => {
    const ingredient = Ingredients.find((ing) => ing.value === value);
    return ingredient ? ingredient.label : value;
  };

  const validIngredients = ingredients.filter(
    (ingredient) =>
      typeof ingredient === "string" &&
      ingredient !== "undefined" &&
      ingredient !== "null" &&
      ingredient.trim() !== ""
  );

  const revisedIngredients = validIngredients.map((ingredient) =>
    findLabel(ingredient)
  );

  if (revisedIngredients.length === 0) {
    return "No valid ingredients provided.";
  } else if (revisedIngredients.length === 1) {
    return "Ranked by " + revisedIngredients[0] + " value.";
  } else {
    return (
      "Ranked by " +
      revisedIngredients.slice(0, -1).join(", ") +
      " and " +
      revisedIngredients[revisedIngredients.length - 1] +
      " value."
    );
  }
}

export function subMakerProtein(rank) {
  if (rank === "lowcal") {
    return "Ranked by Lowest Calories and Protein value.";
  } else if (rank === "highcal") {
    return "Ranked by Highest Calories and Protein value.";
  }

  return "Ranked by Protein value.";
}

export function subMakerPW([...ingredients]) {
  const findLabel = (value) => {
    const ingredient = preIngredients.find((ing) => ing.value === value);
    return ingredient ? ingredient.label : value;
  };

  const validIngredients = ingredients.filter(
    (ingredient) =>
      typeof ingredient === "string" &&
      ingredient !== "undefined" &&
      ingredient !== "null" &&
      ingredient.trim() !== ""
  );

  const revisedIngredients = validIngredients.map((ingredient) =>
    findLabel(ingredient)
  );

  if (revisedIngredients.length === 0) {
    return "No valid ingredients provided.";
  } else if (revisedIngredients.length === 1) {
    return "Ranked by " + revisedIngredients[0] + " value.";
  } else {
    return (
      "Ranked by " +
      revisedIngredients.slice(0, -1).join(", ") +
      " and " +
      revisedIngredients[revisedIngredients.length - 1] +
      " value."
    );
  }
}

export function tieBreaker(items) {
  let previousValueRank = null;
  let currentRank = 1;
  let previousOldValueRank = null;
  let oldCurrentRank = 1;

  // First, calculate the current ranks while handling ties
  const rankedItems = items.map((item, index) => {
    if (previousValueRank !== null && item.value_rank === previousValueRank) {
      currentRank--;
    }
    const rankToShow = currentRank;
    previousValueRank = item.value_rank;
    currentRank++;
    return { ...item, currentRank: rankToShow };
  });

  // Sort items by old_value_rank
  const sortedByOldValueRank = [...rankedItems].sort(
    (a, b) => a.old_value_rank - b.old_value_rank
  );

  // console.log(sortedByOldValueRank);

  // Calculate old ranks while handling ties
  const rankedOldItems = sortedByOldValueRank.map((item, index) => {
    if (
      previousOldValueRank !== null &&
      item.old_value_rank === previousOldValueRank
    ) {
      oldCurrentRank--;
    }
    const oldRankToShow = oldCurrentRank;
    previousOldValueRank = item.old_value_rank;
    oldCurrentRank++;
    return { ...item, oldRank: oldRankToShow };
  });

  // Merge old ranks back into the original items based on value_rank
  const mergedItems = rankedItems.map((item) => {
    const rankedOldItem = rankedOldItems.find(
      (i) => i.brand === item.brand && i.subtitle === item.subtitle
    );
    return {
      ...item,
      oldRank: rankedOldItem ? rankedOldItem.oldRank : null,
    };
  });

  // console.log(mergedItems);
  return mergedItems.filter((i) => i.currentRank <= 3);
}

export function searchPageGen(
  category,
  filter,
  rank,
  patent,
  [...rankIngredients],
  [...excludeIngredients]
) {
  let link = "/" + category + "?";
  const params = new URLSearchParams();

  if (category === "massgainer") {
    return "/massgainer";
  }

  if (category === "preworkout") {
    rankIngredients.forEach((ingredient, index) => {
      const value = preIngredients.find((i) => i.value === ingredient)?.value;
      if (value) {
        params.append(`ingredient${index + 1}`, value);
      }
    });
    excludeIngredients.forEach((exclude, index) => {
      const value = preIngredients.find((i) => i.value === exclude)?.value;
      if (value) {
        params.append(`exclude${index + 1}`, value);
      }
    });
  }

  if (category === "proteinpowder") {
    if (filter && filter !== "all" && filter !== "undefined") {
      params.append(`type`, filter);
    }
    if (rank && rank !== "undefined") {
      params.append(`rank`, rank);
    }
  }

  if (category === "electrolyte") {
    const Ingredients = [
      { value: "calcium", label: "Calcium" },
      { value: "chloride", label: "Chloride" },
      { value: "magnesium", label: "Magnesium" },
      { value: "potassium", label: "Potassium" },
      { value: "sodium", label: "Sodium" },
    ];

    rankIngredients.forEach((ingredient, index) => {
      const value = Ingredients.find((i) => i.value === ingredient)?.value;
      if (value) {
        params.append(`ingredient${index + 1}`, value);
      }
    });

    if (filter && filter !== "all") {
      params.append("filter", filter);
    }
  }

  if (category === "creatine") {
    if (filter && filter !== "all") {
      params.append(`type`, filter);
    }
    if (patent && patent !== "undefined") {
      params.append(`patent`, "true");
    }
  }

  if (category === "citrulline" || category === "betaalanine") {
    if (filter && filter !== "all") {
      params.append(`type`, filter);
    }
  }

  link += params.toString();

  // console.log(link);
  return link;
}

export const Regions = [
  {
    value: "US",
    label: "United States",
  },
  {
    value: "GB",
    label: "United Kingdom",
  },
];
