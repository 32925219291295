import { keyframes } from "styled-components";
import styled from "styled-components";

export const GradientAnimation = () => keyframes`
0% {
  background-position: 0% 50%;
}
75% {
  background-position: 0% 50%;
}
100% {
  background-position: 100% 50%;
}
`;

export const HomeItemDivAnimation = (loaded, rank, tool) => keyframes`
0% {
  transform: ${
    !loaded ? "" : tool ? "translateX(-200%)" : rank && "translateX(200%)"
  };
}
100% {
  transform: ${
    !loaded ? "" : rank || tool ? "translateX(0%)" : "translateX(0%)"
  };
}
`;

export const MobileHomeDivAnimation = (loaded, info, rank) => keyframes`
  0% {
    transform: ${
      !loaded ? "" : rank ? "translateX(-200%)" : info && "translateX(200%)"
    };
  }
  100% {
    transform: ${
      !loaded ? "" : info || rank ? "translateX(0%)" : "translateX(0%)"
    };
  }
`;

export const TabBarAnimation = (left, loaded, mobile) => keyframes`
  0% {
    transform: ${
      !loaded
        ? mobile
          ? "translate(70px)"
          : "translate(-70px)"
        : left === "0"
        ? "translateX(70px)"
        : "translateX(-70px)"
    }
  }
  100% {
    transform: ${left === "0" ? "translateX(-70px)" : "translateX(70px)"};
  }
`;

export const BounceEffect = () => keyframes`
  0% {
    scale: 1;
  }
  50% {
    scale: 1.01;
  }
  100% {
    scale: 1;
  }
`;

const pulse = keyframes`
  0% {
    transform: scale(0.33);
  }
  80%, 100% {
    opacity: 0;
  }
`;
const circle = keyframes`
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
`;
export const LiveIndicator = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10px;
  height: 10px;
  margin-right: 5px;

  &:before {
    content: "";
    position: absolute;
    display: block;
    width: 300%;
    height: 300%;
    box-sizing: border-box;
    // margin-left: -75%;
    // margin-top: -75%;
    border-radius: 45px;
    background-color: #f04949;
    animation: ${pulse} 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: #f04949;
    border-radius: 50px;
    animation: ${circle} 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
  }
`;