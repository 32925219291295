import { createGlobalStyle } from "styled-components";

import Hezaedrus from "./Hezaedrus-Bold.woff2";
import SanFranciscoBold from "./SFUIDisplay-Bold.woff2";
import SanFranciscoReg from "./SFUIDisplay-Regular.woff2";

export default createGlobalStyle`
    @font-face {
        font-family: 'Hezaedrus';
        src:url(${Hezaedrus}) format('woff2');
    }
    @font-face {
        font-family: 'San Francisco Bold';
        src:url(${SanFranciscoBold}) format('woff2');
    }
    @font-face {
        font-family: 'San Francisco Regular';
        src:url(${SanFranciscoReg}) format('woff2');
    }
`;
