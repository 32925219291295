import React, { useEffect } from "react";
import styled from "styled-components";

// Styled component for the input
const InputDiv = styled.div`
  --text: black;
  --text-placeholder: #d9d9db;
  --icon: black;
  --icon-focus: black;
  --icon-invalid: #f04949;
  --icon-valid: #16bf78;
  --background: #fff;
  --border: black;
  --border-hover: #a6accd;
  --border-focus: #d4af37;
  --shadow-focus: rgba(39, 94, 254, 0.32);
  position: relative;
  width: 375px;
  margin: 10px;

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s;
  }
  

  input {
    width: 300px;
    -webkit-appearance: none;
    outline: none;
    display: block;
    font-size: 16px;
    font-family: inherit;
    margin: 0;
    padding: 8px 16px 8px 41px;
    line-height: 27px;
    border-radius: 6px;
    color: var(--text);
    border: 1px solid var(--bc, var(--border));
    background: var(--background);
    transition: border-color 0.3s, box-shadow 0.3s;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
    &::placeholder {
      color: var(--text-placeholder);
    }
  }

  svg {
    width: 16px;
    height: 16px;
    top: 14px;
    left: 20px;
    display: block;
    position: absolute;
    stroke: var(--i, var(--icon));
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: .53;
    transition: stroke 0.3s;

    path {
      stroke-dasharray: 80;
      stroke-dashoffset: var(--path, 170);
      transition: stroke-dashoffset 0.5s ease var(--path-delay, 0.3s);
    }

    polyline {
      stroke-dasharray: 12;
      stroke-dashoffset: var(--tick, 12);
      transition: stroke-dashoffset 0.45s ease var(--tick-delay, 0s);
    }
  }

  &:hover {
    --bc: var(--border-hover);
  }

  &:focus-within {
    --bc: var(--border-focus);
    --i: var(--icon-focus);
    input {
      box-shadow: 0 1px 6px -1px var(--shadow-focus);
    }
  }

  &:not(.valid) {
    input {
      &:not(:placeholder-shown) {
        &:not(:focus) {
          & + svg {
            --i: var(--icon-invalid);
            fill: var(--icon-invalid);
          }
        }
      }
    }
  }

  &.valid {
    --i: var(--icon-valid);

    svg {
      fill: var(--icon-valid);
    }
  }
  }
`;

const PasswordInput = ({ value, onChange, isValid, placeholder, autoComplete }) => {
  return (
    <InputDiv className={`password-input ${isValid ? "valid" : "invalid"}`}>
      <input
        type="password"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        autoComplete={autoComplete}
      />
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18 10.5C19.6569 10.5 21 11.8431 21 13.5V19.5C21 21.1569 19.6569 22.5 18 22.5H6C4.34315 22.5 3 21.1569 3 19.5V13.5C3 11.8431 4.34315 10.5 6 10.5V7.5C6 4.18629 8.68629 1.5 12 1.5C15.3137 1.5 18 4.18629 18 7.5V10.5ZM12 3.5C14.2091 3.5 16 5.29086 16 7.5V10.5H8V7.5C8 5.29086 9.79086 3.5 12 3.5ZM18 12.5H6C5.44772 12.5 5 12.9477 5 13.5V19.5C5 20.0523 5.44772 20.5 6 20.5H18C18.5523 20.5 19 20.0523 19 19.5V13.5C19 12.9477 18.5523 12.5 18 12.5Z"
        />
      </svg>
    </InputDiv>
  );
};

export default PasswordInput;
