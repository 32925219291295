import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { trackEvent } from "../custom/analytics";
import { massIngredients } from "../custom/functions";
import { RightArrowSVG, LeftArrowSVG } from "../custom/svg";
import { CircularProgress } from "@mui/material";

// CSS FOLD
const StyledLink = styled(Link)`
  text-decoration: none; /* Remove underline */
  color: inherit; /* Ensure text color is inherited */
`;
const UnderlinedSpan = styled.span`
  text-decoration: underline; /* Add underline to the span */
  color: inherit; /* Ensure text color is inherited */
`;
const ProductDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: start;
  gap: 50px;
  position: static;
  padding: 10px 0px 30px 0px;
  max-width: 1600px;

  @media (max-width: 760px) {
    flex-direction: column;
    // padding: 0px 0px 30px 0px;
    padding: ${(props) =>
      props.backActive ? "0px 0px 30px 0px" : "10px 0px 30px 0px"};
    align-items: center;
    gap: 25px;
  }
`;
const BackRoundedSquare = styled.div.attrs((props) => ({
  color: props.color,
}))`
  display: block;
  text-decoration: none;
  color: black;
  width: 40%;
  background-color: ${(props) => props.color};
  border-radius: 15px;
  position: static;
  //top: 15px;
  transition-duration: 0.25s;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;

  @media (max-width: 760px) {
    flex-direction: column;
    width: 65%;
  }
`;
const FrontRoundedSquare = styled.div`
  //width: 96%;
  background-color: white;
  border-radius: 10px;
  position: static;
  margin: 5px;
  padding: 5px;
`;
const ItemDiv = styled.div`
  width: 100%;
  aspect-ratio: 1 / 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ItemLogo = styled.img`
  height: 85%;
  position: static;
`;
const Info = styled.div`
  width: 45%;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-top: 10px;

  @media (max-width: 760px) {
    width: 90%;
    margin-top: 0px;
  }
`;
const ProductName = styled.p`
  font-family: San Francisco Bold;
  font-size: 1.4rem;
  margin: 0 auto;
`;
const TypeSubTitle = styled.p`
  color: gray;
  font-size: 14px;
  margin: 5px 0px 5px 0px;
  font-family: San Francisco Regular;
`;
const Disclaimer = styled.p`
  max-width: 350px;
  color: gray;
  //letter-spacing: 0.7px;
  font-size: 10px;
  font-family: San Francisco Regular;
`;
const PriceDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
`;
const Price = styled.p`
  color: black;
  //letter-spacing: 0.7px;
  font-size: 18px;
  font-weight: bold;
  margin: 0px 0px 0px 0px;
  font-family: San Francisco Bold;
`;
const ShopNow = styled.button.attrs((props) => ({
  color: props.color,
}))`
  all: unset;
  display: block;
  text-decoration: none;
  background-color: gray;
  width: 100px;
  height: 40px;
  background-color: ${(props) => props.color};
  border-radius: 10px;
  position: static;
  cursor: pointer;
  transition-duration: 0.25s;
  margin: 0 auto;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;

  @media (min-width: 760px) {
    &:hover {
      box-shadow: 0px 0px 5px black;
    }
  }
`;
const ShopText = styled.p.attrs((props) => ({
  color: props.color,
}))`
  font-size: 1rem;
  font-family: San Francisco Bold;
  color: ${(props) => props.color};
  margin: auto;
  line-height: 40px;
  position: static;
`;
const Table = styled.table`
  border-collapse: seperate;
  position: static;
  width: 80%;
  padding: 0px 0px 0px 0px;
  margin-left: auto;
  margin-right: auto;
  font-family: San Francisco Regular;

  & span {
    float: left;
    margin: 0.5rem;
  }

  & th,
  td {
    border-style: dashed;
    border: 1px solid #ccc;
  }

  @media (max-width: 760px) {
    width: 90%;
  }
`;
const FlavorDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  font-family: San Francisco Bold;

  width: 70%;

  @media (max-width: 760px) {
    width: 90%;
  }
`;
const SizeDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 70%;

  @media (max-width: 760px) {
    width: 90%;
  }
`;
const FlavorButton = styled.button`
  all: unset;
  display: block;
  text-decoration: none;
  cursor: pointer;
  border-radius: 10px;
  padding: 2px;
  border: none;
  background: #efefef;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  transition-duration: 0.25s;
  box-shadow: ${(props) => props.active && "0px 0px 5px black"};

  &:hover {
    box-shadow: 0px 0px 5px black;
  }
`;
const FlavorText = styled.p`
  font-size: 0.9rem;
  margin: 5px;
  position: static;
  line-height: 20px;
  color: black;
  font-family: San Francisco Bold;
`;
const TabBar = styled.div`
  display: block;
  height: 3px;
  width: 70%;
  border-radius: 50px;
  background-color: #efefef;
  margin-top: 10px;
  margin-bottom: 10px;

  @media (max-width: 760px) {
    width: 90%;
  }
`;
const ArrowDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const RankButton = styled.button`
  all: unset;
  // text-decoration: underline;
  // color: gray;
  // background: gray;
  display: flex;
  align-items: center;

  &:active {
    text-decoration: underline;
  }

  &:hover {
    cursor: pointer;
  }
`;
const BackDiv = styled.div`
  display: flex;
  width: 95%;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  line-height: 14px;
  font-family: San Francisco Regular;
  padding-bottom: 10px;
  // background: gray;

  @media (max-width: 760px) {
    width: 90%;
    height: 50px;
    line-height: 12px;
    padding-bottom: 0px;
  }
`;
const BackButton = styled.button`
  all: unset;
  display: flex;
  align-items: center;
  gap: 5px;

  &:active {
    text-decoration: underline;
  }

  &:hover {
    cursor: pointer;
  }
`;
const BackText = styled.p`
  font-size: 14px;
  color: black;
  // margin: 0px;

  @media (max-width: 760px) {
    font-size: 12px;
  }
`;
const TableIngredient = styled.td`
  width: 65%;
`;

const TableValue = styled.td`
  width: 35%;
`;
const TableDefinition = styled.p`
  color: gray;
  font-size: 14px;
  margin: 10px 5px 10px 5px;
  font-family: San Francisco Regular;
`;

const NutritionTable = (props) => {
  const [selectedIngredient, setSelectedIngredient] = useState(null);

  const handleIngredientClick = (ingredient) => {
    if (ingredient === selectedIngredient) {
      return setSelectedIngredient(null);
    }
    setSelectedIngredient(ingredient);
  };
  return (
    <Table>
      <thead>
        <tr>
          <th>
            <span>Nutrition: </span>
          </th>
          <th>
            <span>Amount: </span>
          </th>
          {/* <th>
              <span>Category Rank: </span>
            </th> */}
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <span>Servings</span>
          </td>
          <td>
            <span>{props.prod.servings}</span>
          </td>
        </tr>
        <tr>
          <td>
            <span>Serving Size</span>
          </td>
          <td>
            <span>{props.prod.servingsize}g</span>
          </td>
        </tr>
        {massIngredients.map((ingredient) => {
          const { value, label, unit, definition } = ingredient;
          if (props.prod[value] !== null) {
            return (
              <React.Fragment key={value}>
                <tr key={value}>
                  <TableIngredient>
                    {definition ? (
                      <UnderlinedSpan
                        onClick={() => handleIngredientClick(ingredient)}
                      >
                        {label}
                      </UnderlinedSpan>
                    ) : (
                      <span>{label}</span>
                    )}
                  </TableIngredient>
                  <TableValue>
                    <span>
                      {props.twoScoop
                        ? parseFloat(props.prod[value]) * 2
                        : props.prod[value]}
                      {unit}
                    </span>
                  </TableValue>
                </tr>
                {selectedIngredient && selectedIngredient.value === value && (
                  <tr>
                    <td colSpan="2" style={{ border: "none" }}>
                      <TableDefinition>{definition}</TableDefinition>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            );
          }
          return null;
        })}
      </tbody>
    </Table>
  );
};

const MassProduct = (props) => {
  let sign = "$";

  const { brand, subtitle, flavor, servings, region } = useParams();
  const [productGroup, setProductGroup] = useState({
    category: null,
    brand: null,
    subtitle: null,
    type: null,
    region: null
  });
  const [productLoading, setProductLoading] = useState(true);
  const [productData, setProductData] = useState([]);

  if (region === "GB") {
    sign = "£";
  }

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    if (brand && subtitle && region) {
      setProductGroup({
        category: "massgainer",
        brand: brand,
        subtitle: subtitle,
        type: null,
        region: region
      });
    }
  }, [brand, subtitle, region]);

  const constructQueryParams = (params) => {
    if (typeof params !== "object" || Array.isArray(params)) {
      throw new Error("Params should be an object with key-value pairs.");
    }

    let query = "/api/product-group?";
    for (const [key, value] of Object.entries(params)) {
      // console.log(`Processing key: ${key}, value: ${value}`); // Debugging line
      if (value !== null && value !== "") {
        query += `${encodeURIComponent(key)}=${encodeURIComponent(value)}&`;
      }
    }

    return query.endsWith("&") ? query.slice(0, -1) : query;
  };

  useEffect(() => {
    if (productGroup.category && productGroup.brand && productGroup.subtitle) {
      setProductLoading(true);
      fetch(constructQueryParams(productGroup))
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          setProductData(data);
          setProductLoading(false);
        })
        .catch((error) => {
          console.error("Error caught:", error);
          setProductLoading(false);
        });
    }
  }, [productGroup]);

  if (productLoading) {
    return (
      <center style={{ paddingTop: "150px" }}>
        <CircularProgress color="inherit" />
      </center>
    );
  }

  const prod = productData.find(
    (obj) =>
      obj.brand === brand &&
      obj.subtitle === subtitle &&
      obj.flavor === flavor &&
      obj.servings === servings
  );
  const flavors = productData.filter(
    (obj) =>
      obj.brand === brand &&
      obj.subtitle === subtitle &&
      obj.servings === servings &&
      obj.stock === true
  );
  const sizes = productData.filter(
    (obj) =>
      obj.brand === brand &&
      obj.subtitle === subtitle &&
      obj.flavor === flavor &&
      obj.stock === true
  );

  let backColor = "";
  let textColor = "";
  let previousValueRank = null;
  let adjustedRank = 1;
  let rank = 0;

  const originalIndex = props.massFilter.findIndex(
    (i) => i.brand === prod.brand && i.subtitle === prod.subtitle
  );

  for (let i = 0; i <= originalIndex; i++) {
    if (
      previousValueRank !== null &&
      props.massFilter[i].value_rank === previousValueRank
    ) {
      adjustedRank--;
    }
    if (i === originalIndex) {
      rank = adjustedRank;
    }
    previousValueRank = props.massFilter[i].value_rank;
    adjustedRank++;
  }

  if (rank === 1) {
    backColor = "#d4af37";
    textColor = "white";
  } else if (rank === 2) {
    backColor = "#C0C0C0";
    textColor = "white";
  } else if (rank === 3) {
    backColor = "#CD7F32";
    textColor = "white";
  } else {
    backColor = "#efefef";
    textColor = "black";
  }

  // green: #00a650
  // yreen: #cadb2a
  // red: #ed1b24

  const getStyle = (bool) => {
    return bool
      ? { background: "#2e2e2e", color: "white" } // Adding color: "white" for better visibility
      : { color: "#efefef" };
  };

  const getTextStyle = (bool) => {
    return bool
      ? { color: "white" } // Adding color: "white" for better visibility
      : { color: "black" };
  };

  const getPrev = () => {
    if (originalIndex <= 0) {
      return null;
    }

    const prevProduct = props.massFilter[originalIndex - 1];
    const link =
      "/massgainer/" +
      prevProduct.brand +
      "/" +
      prevProduct.subtitle +
      "/" +
      prevProduct.flavor +
      "/" +
      prevProduct.servings  +
      "/" +
      prevProduct.region;

    return link;
  };

  const getNext = () => {
    if (originalIndex >= props.massFilter.length - 1) {
      return null;
    }

    const nextProduct = props.massFilter[originalIndex + 1];
    const link =
      "/massgainer/" +
      nextProduct.brand +
      "/" +
      nextProduct.subtitle +
      "/" +
      nextProduct.flavor +
      "/" +
      nextProduct.servings +
      "/" +
      nextProduct.region;

    return link;
  };

  // console.log(props.massFilter)

  return (
    <center>
      {rank > 0 && (
        <BackDiv>
          <StyledLink to={props.massFilterLink} method="get">
            <BackButton type="submit">
              <LeftArrowSVG />
              <BackText>Full Ranking Page</BackText>
            </BackButton>
          </StyledLink>
          {/* <BackText style={{color:"gray"}}>{rank} Place</BackText> */}
        </BackDiv>
      )}
      <ProductDiv backActive={rank > 0}>
        <BackRoundedSquare color={backColor}>
          <FrontRoundedSquare>
            <ItemDiv>
              <ItemLogo src={prod.imglink} />
            </ItemDiv>
          </FrontRoundedSquare>
        </BackRoundedSquare>
        <Info>
          {rank > 0 ? (
            <ArrowDiv>
              <StyledLink
                onClick={() => setProductLoading(true)}
                to={getPrev()}
                method="get"
              >
                <RankButton type="submit">
                  <LeftArrowSVG
                    color={originalIndex === 0 ? "white" : "black"}
                  />
                </RankButton>
              </StyledLink>
              <div>
                <ProductName>
                  {prod.brand}
                  <br />
                  {prod.subtitle !== null ? '"' + prod.subtitle + '"' : ""}
                </ProductName>
                <TypeSubTitle>{prod.flavor} Mass Gainer</TypeSubTitle>
              </div>
              <StyledLink
                onClick={() => setProductLoading(true)}
                to={getNext()}
                method="get"
              >
                <RankButton type="submit">
                  <RightArrowSVG
                    color={
                      originalIndex + 1 === props.massFilter.length
                        ? "white"
                        : "black"
                    }
                  />
                </RankButton>
              </StyledLink>
            </ArrowDiv>
          ) : (
            <div>
              <ProductName>
                {prod.brand}
                <br />
                {prod.subtitle !== null ? '"' + prod.subtitle + '"' : ""}
              </ProductName>
              <TypeSubTitle>{prod.flavor} Mass Gainer</TypeSubTitle>
            </div>
          )}
          <Price>
            {sign + prod.price}
            {!prod.shoplink.includes("amzn") &&
              !prod.shoplink.includes("lvnta") &&
              "*"}
          </Price>
          <SizeDiv>
            {sizes.map(
              (item) =>
                item && (
                  <StyledLink
                    to={
                      "/massgainer/" +
                      item.brand +
                      "/" +
                      item.subtitle +
                      "/" +
                      item.flavor +
                      "/" +
                      item.servings +
                      "/" +
                      item.region
                    }
                    method="get"
                    key={item.servings}
                  >
                    <FlavorButton
                      type="submit"
                      style={getStyle(item.servings === prod.servings)}
                    >
                      <FlavorText
                        style={getTextStyle(item.servings === prod.servings)}
                      >
                        {item.servings} Servings
                      </FlavorText>
                    </FlavorButton>
                  </StyledLink>
                )
            )}
          </SizeDiv>
          {/* <SubTitle>{subtitle}</SubTitle> */}
          <FlavorDiv>
            {flavors.map(
              (item) =>
                item && (
                  <StyledLink
                    to={
                      "/massgainer/" +
                      item.brand +
                      "/" +
                      item.subtitle +
                      "/" +
                      item.flavor +
                      "/" +
                      item.servings +
                      "/" +
                      item.region
                    }
                    method="get"
                    key={item.flavor}
                  >
                    <FlavorButton
                      type="submit"
                      style={getStyle(item.flavor === prod.flavor)}
                    >
                      <FlavorText
                        style={getTextStyle(item.flavor === prod.flavor)}
                      >
                        {item.flavor}
                      </FlavorText>
                    </FlavorButton>
                  </StyledLink>
                )
            )}
          </FlavorDiv>
          <TabBar />
          <a
            style={{ all: "unset" }}
            href={prod.shoplink}
            method="get"
            target="_blank"
            rel="noreferrer"
          >
            <ShopNow
              color={backColor}
              onClick={() => {
                trackEvent("ShopNow", "Shopped: Mass Gainer");
              }}
            >
              <ShopText color={textColor}>Shop Now</ShopText>
            </ShopNow>
          </a>
          <Disclaimer>
            {!prod.shoplink.includes("amzn") &&
              !prod.shoplink.includes("lvnta") &&
              "*If price isn't accurate at checkout, use code SUPPHEAD."}
            {!prod.shoplink.includes("amzn") &&
              !prod.shoplink.includes("lvnta") && <br />}
            These rankings are based on Nutritional statistics alone. <br />{" "}
            They have no consideration for flavor of product.
          </Disclaimer>
          <NutritionTable prod={prod} />
          {/* <Disclaimer>
            Nutritional statistics displayed are directly from product packaging.
          </Disclaimer> */}
        </Info>
      </ProductDiv>
    </center>
  );
};

export default MassProduct;
